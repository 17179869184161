import {
   Box,
   IconButton,
   Stack,
   FormControl,
   FormLabel,
   VStack,
   HStack,
   Input,
   Button,
   NumberInput,
   NumberInputField,
} from "@chakra-ui/react";
import { useState, useMemo } from "react";
import { IoMdArrowRoundBack, IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { IApp } from "../../ts/interfaces/announces_interface";

const ApplicationEdit: React.FC<{
   app: IApp;
   onDone: (fields: IApp) => void;
   goBack: () => void;
}> = ({ onDone, app, goBack }) => {
   const [select, setSelect] = useState<IApp>(structuredClone(app));

   const [value, setValue] = useState<Record<string, string>>(app.fields || {});

   const [port, setPort] = useState<number>(app.port || 20);

   const validate = useMemo(
      () =>
         (
            select?.type?.fields?.map((field) => {
               if (field.multiple) {
                  return (
                     value[field.key]
                        .split("|")
                        .map((value, index) => {
                           let optional = field.optional
                              ? field.optional && index === 0
                              : field.optional;
                           if (optional && !value) {
                              return true;
                           }
                           if (!optional && !value) {
                              return false;
                           }
                           return new RegExp(field.pattern).test(value || "");
                        })
                        .every(Boolean) || false
                  );
               }
               if (field.optional && !value[field.key]) {
                  return true;
               }
               if (!field.optional && !value[field.key]) {
                  return false;
               }
               return new RegExp(field.pattern).test(value[field.key]);
            }) || []
         ).every(Boolean),
      [value]
   );

   return (
      <Box>
         <IconButton
            onClick={goBack}
            icon={<IoMdArrowRoundBack />}
            aria-label="back"
         />
         <Stack spacing="3" mt={3}>
            {select.type.need_port && (
               <FormControl isRequired>
                  <FormLabel>Port</FormLabel>
                  <NumberInput
                     precision={0}
                     step={1}
                     onChange={(e) => e && setPort(parseInt(e))}
                     value={port}
                  >
                     <NumberInputField placeholder="Port" />
                  </NumberInput>
               </FormControl>
            )}
            {select &&
               select.type.fields?.map((field) => (
                  <FormControl isRequired={!field.optional}>
                     <FormLabel>{field.label}</FormLabel>
                     {field.multiple ? (
                        <VStack spacing={2}>
                           {value[field.key]?.split("|").map((v, idy) => (
                              <HStack w="100%">
                                 <Input
                                    placeholder={field.placeholder}
                                    value={v}
                                    onChange={(e) => {
                                       let newValue = structuredClone(value);
                                       let values =
                                          value[field.key].split("|") || [];

                                       values[idy] = e.target.value;
                                       newValue[field.key] = values.join("|");
                                       setValue(newValue);
                                    }}
                                 />
                                 {idy === 0 ? (
                                    <IconButton
                                       icon={<IoMdAdd />}
                                       aria-label="add"
                                       onClick={() => {
                                          let newValue = structuredClone(value);
                                          let values =
                                             value[field.key].split("|") || [];
                                          values.push("");
                                          newValue[field.key] =
                                             values.join("|");
                                          setValue(newValue);
                                       }}
                                       disabled={!value || !validate}
                                    />
                                 ) : (
                                    <IconButton
                                       icon={<MdDelete />}
                                       aria-label="delete"
                                       colorScheme="danger"
                                       onClick={() => {
                                          let sel = { ...select };
                                          if (sel.fields) {
                                             let newValue =
                                                structuredClone(value);
                                             let values =
                                                value[field.key].split("|") ||
                                                [];
                                             values.splice(idy, 1);
                                             newValue[field.key] =
                                                values.join("|");
                                             setValue(newValue);
                                          }
                                          setSelect(sel);
                                       }}
                                    />
                                 )}
                              </HStack>
                           ))}
                        </VStack>
                     ) : (
                        <Input
                           mb={2}
                           placeholder={field.placeholder}
                           value={value[field.key]}
                           onChange={(e) => {
                              let newValue = structuredClone(value);
                              newValue[field.key] = e.target.value;
                              setValue(newValue);
                           }}
                        />
                     )}
                  </FormControl>
               ))}
            <Button
               onClick={() => {
                  onDone({ ...select, fields: value, port: port });
                  goBack();
               }}
               colorScheme="brand"
               disabled={!validate}
            >
               Save
            </Button>
         </Stack>
      </Box>
   );
};

export default ApplicationEdit;

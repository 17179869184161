import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";
import * as React from "react";

export const Card = (props: BoxProps) => (
   <Box
      bg={useColorModeValue("white", "gray.700")}
      p={8}
      shadow="xl"
      rounded={{ sm: "md" }}
      {...props}
   />
);

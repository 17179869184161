import React, { FC, useState } from "react";
import {
   Alert,
   Badge,
   Button,
   Checkbox,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Select,
   Textarea,
   useDisclosure,
   useToast,
   VStack,
} from "@chakra-ui/react";
import { useMutation } from "react-query";
import axios, { AxiosError } from "axios";
import { APIError } from "../../ts/interfaces/error";
import { IoMdAdd } from "react-icons/io";

interface CreateSwitchDeviceModalProps {
   onCreate: () => void;
}

export const CreateSwitchModelModal: FC<CreateSwitchDeviceModalProps> = ({
   onCreate,
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const toast = useToast();

   const initData = {
      name: "",
      shutdown: "shutdown",
      no_shutdown: "no shutdown",
      access: "switchport mode access",
      no_access: "",
      access_port_fast: "spanning-tree portfast edge",
      no_access_port_fast: "no spanning-tree portfast",
      access_vlan: "switchport access vlan {vlan}",
      no_access_vlan: "no switchport access vlan",
      trunk: "switchport trunk encapsulation dot1q\nswitchport mode trunk",
      no_trunk: "no switchport trunk encapsulation dot1q",
      trunk_port_fast: "spanning-tree portfast edge trunk",
      no_trunk_port_fast: "no spanning-tree portfast",
      trunk_native: "switchport trunk native vlan {native_vlan}",
      no_trunk_native: "no switchport trunk native vlan",
      trunk_allow: "switchport trunk allow vlan {allow_vlan}",
      no_trunk_allow: "no switchport trunk allow vlan",
      keep_alive: "keepalive",
      no_keep_alive: "no keepalive",
      channel_group: "channel-group {group} mode active",
      no_channel_group: "no channel-group",
      need_double_quote: false,
      show_version_text_fsm: showVersionTextFSM,
      show_interface_text_fsm: showInterfaceTextFSM,
      show_run_interface_text_fsm: showRunInterfaceTextFSM,
      save: "do copy run start\nstartup-config",
      device_type: "",
      port_pattern:
         "(?i)GigabitEthernet(\\d+\\/\\d+\\/\\d+)|Port-channel(\\d+)|TenGigabitEthernet(\\d+\\/\\d+\\/\\d+)",
      shorthand_port_pattern:
         "(?i)Gi\\d+\\/\\d+\\/\\d+|Po\\d+|Te\\d+\\/\\d+\\/\\d+",
      port_number_pattern: "(?i)\\d+\\/\\d+\\/(\\d+)|channel(\\d+)|Po(\\d+)",
      port_channel_pattern: "(?i)Port-channel(\\d+)",
   };

   const [formData, setFormData] = useState(initData);
   const { mutateAsync } = useMutation<any, AxiosError<APIError>, any>(
      ["admin", "create-switch-model"],
      (data) => {
         return axios.put("/admin/switch-model", data).then((res) => res.data);
      }
   );

   return (
      <>
         <IconButton
            colorScheme="brand"
            icon={<IoMdAdd />}
            aria-label={"Add"}
            onClick={() => {
               onOpen();
               setFormData(initData);
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Create Switch Model</ModalHeader>
               <ModalCloseButton />
               <ModalBody maxHeight="70vh" overflow="auto">
                  <VStack spacing={4}>
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           placeholder="Name"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 name: e.target.value,
                              })
                           }
                           value={formData.name}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Port Pattern</FormLabel>
                        <Input
                           placeholder="Port Pattern"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 port_pattern: e.target.value,
                              })
                           }
                           value={formData.port_pattern}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Shorthand Port Pattern</FormLabel>
                        <Input
                           placeholder="Shorthand Port Pattern"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 shorthand_port_pattern: e.target.value,
                              })
                           }
                           value={formData.shorthand_port_pattern}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>PortNumber Pattern</FormLabel>
                        <Input
                           placeholder="PortNumber Pattern"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 port_number_pattern: e.target.value,
                              })
                           }
                           value={formData.port_number_pattern}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>PortChannel Pattern</FormLabel>
                        <Input
                           placeholder="PortChannel Pattern"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 port_channel_pattern: e.target.value,
                              })
                           }
                           value={formData.port_channel_pattern}
                        />
                     </FormControl>
                     <Select
                        placeholder="Device Type"
                        value={formData.device_type}
                        onChange={(e) =>
                           setFormData({
                              ...formData,
                              device_type: e.target.value,
                           })
                        }
                     >
                        <option value="cisco_asa">Cisco ASA</option>
                        <option value="cisco_ios">Cisco IOS</option>
                        <option value="cisco_iosxr">Cisco IOS XR</option>
                        <option value="cisco_nxos">Cisco NX-OS</option>
                        <option value="juniper">Juniper JunOS</option>
                        <option value="mikrotik_routeros">
                           Mikrotik Routeros
                        </option>
                     </Select>
                     <FormControl>
                        <FormLabel>Save</FormLabel>
                        <Textarea
                           placeholder="Save command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 save: e.target.value,
                              })
                           }
                           value={formData.save}
                           rows={formData.save.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Shutdown</FormLabel>
                        <Textarea
                           placeholder="Shutdown command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 shutdown: e.target.value,
                              })
                           }
                           value={formData.shutdown}
                           rows={formData.shutdown.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Shutdown</FormLabel>
                        <Textarea
                           placeholder="No Shutdown command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_shutdown: e.target.value,
                              })
                           }
                           value={formData.no_shutdown}
                           rows={formData.no_shutdown.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Access</FormLabel>
                        <Textarea
                           placeholder="Access command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 access: e.target.value,
                              })
                           }
                           value={formData.access}
                           rows={formData.access.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Access</FormLabel>
                        <Textarea
                           placeholder="No Access command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_access: e.target.value,
                              })
                           }
                           value={formData.no_access}
                           rows={formData.no_access.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Access PortFast</FormLabel>
                        <Textarea
                           placeholder="Access PortFast command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 access_port_fast: e.target.value,
                              })
                           }
                           value={formData.access_port_fast}
                           rows={formData.access_port_fast.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Access PortFast</FormLabel>
                        <Textarea
                           placeholder="No Access PortFast command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_access_port_fast: e.target.value,
                              })
                           }
                           value={formData.no_access_port_fast}
                           rows={
                              formData.no_access_port_fast.split("\n").length
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Access Vlan</FormLabel>
                        <Alert mb={4}>
                           <div>
                              <div>
                                 <Badge cursor="pointer">{"{vlan}"}</Badge> for
                                 access vlan
                              </div>
                           </div>
                        </Alert>
                        <Textarea
                           placeholder="Access Vlan command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 access_vlan: e.target.value,
                              })
                           }
                           value={formData.access_vlan}
                           rows={formData.access_vlan.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Access Vlan</FormLabel>
                        <Textarea
                           placeholder="No Access Vlan command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_access_vlan: e.target.value,
                              })
                           }
                           value={formData.no_access_vlan}
                           rows={formData.no_access_vlan.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Trunk</FormLabel>
                        <Textarea
                           placeholder="Trunk command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 trunk: e.target.value,
                              })
                           }
                           value={formData.trunk}
                           rows={formData.trunk.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Trunk</FormLabel>
                        <Textarea
                           placeholder="No Trunk command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_trunk: e.target.value,
                              })
                           }
                           value={formData.no_trunk}
                           rows={formData.no_trunk.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Trunk PortFast</FormLabel>
                        <Textarea
                           placeholder="Trunk PortFast command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 trunk_port_fast: e.target.value,
                              })
                           }
                           value={formData.trunk_port_fast}
                           rows={formData.trunk_port_fast.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Trunk PortFast</FormLabel>
                        <Textarea
                           placeholder="No Trunk PortFast command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_trunk_port_fast: e.target.value,
                              })
                           }
                           value={formData.no_trunk_port_fast}
                           rows={formData.no_trunk_port_fast.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Trunk Native</FormLabel>
                        <Alert mb={4}>
                           <div>
                              <div>
                                 <Badge cursor="pointer">
                                    {"{native_vlan}"}
                                 </Badge>{" "}
                                 for trunk native vlan
                              </div>
                           </div>
                        </Alert>
                        <Textarea
                           placeholder="Trunk Native command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 trunk_native: e.target.value,
                              })
                           }
                           value={formData.trunk_native}
                           rows={formData.trunk_native.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Trunk Native</FormLabel>
                        <Textarea
                           placeholder="No Trunk Native command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_trunk_native: e.target.value,
                              })
                           }
                           value={formData.no_trunk_native}
                           rows={formData.no_trunk_native.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Trunk Allow</FormLabel>
                        <Alert mb={4}>
                           <div>
                              <div>
                                 <Badge cursor="pointer">
                                    {"{allow_vlan}"}
                                 </Badge>{" "}
                                 for trunk allow vlan
                              </div>
                           </div>
                        </Alert>
                        <Textarea
                           placeholder="Trunk Allow command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 trunk_allow: e.target.value,
                              })
                           }
                           value={formData.trunk_allow}
                           rows={formData.trunk_allow.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Trunk Allow</FormLabel>
                        <Textarea
                           placeholder="No Trunk Allow command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_trunk_allow: e.target.value,
                              })
                           }
                           value={formData.no_trunk_allow}
                           rows={formData.no_trunk_allow.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>KeepAlive</FormLabel>
                        <Textarea
                           placeholder="KeepAlive command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 keep_alive: e.target.value,
                              })
                           }
                           value={formData.keep_alive}
                           rows={formData.keep_alive.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No KeepAlive</FormLabel>
                        <Textarea
                           placeholder="No KeepAlive command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_keep_alive: e.target.value,
                              })
                           }
                           value={formData.no_keep_alive}
                           rows={formData.no_keep_alive.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>ChannelGroup</FormLabel>
                        <Alert mb={4}>
                           <div>
                              <div>
                                 <Badge cursor="pointer">{"{group}"}</Badge> for
                                 port channel group
                              </div>
                           </div>
                        </Alert>
                        <Textarea
                           placeholder="ChannelGroup command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 channel_group: e.target.value,
                              })
                           }
                           value={formData.channel_group}
                           rows={formData.channel_group.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No ChannelGroup</FormLabel>
                        <Textarea
                           placeholder="No ChannelGroup command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_channel_group: e.target.value,
                              })
                           }
                           value={formData.no_channel_group}
                           rows={formData.no_channel_group.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Checkbox
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 need_double_quote: e.target.checked,
                              })
                           }
                           isChecked={formData.need_double_quote}
                        >
                           Need DoubleQuote
                        </Checkbox>
                     </FormControl>
                     <FormControl>
                        <FormLabel>ShowVersion TextFSM</FormLabel>
                        <Textarea
                           placeholder="ShowVersion TextFSM parser"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 show_version_text_fsm: e.target.value,
                              })
                           }
                           value={formData.show_version_text_fsm}
                           rows={
                              formData.show_version_text_fsm.split("\n").length
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>ShowInterface TextFSM</FormLabel>
                        <Textarea
                           placeholder="ShowInterface TextFSM parser"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 show_interface_text_fsm: e.target.value,
                              })
                           }
                           value={formData.show_interface_text_fsm}
                           rows={
                              formData.show_interface_text_fsm.split("\n")
                                 .length
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>ShowRunInterface TextFSM</FormLabel>
                        <Textarea
                           placeholder="ShowRunInterface TextFSM parser"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 show_run_interface_text_fsm: e.target.value,
                              })
                           }
                           value={formData.show_run_interface_text_fsm}
                           rows={
                              formData.show_run_interface_text_fsm.split("\n")
                                 .length
                           }
                        />
                     </FormControl>
                  </VStack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        mutateAsync(formData, {
                           onSuccess: (data) => {
                              toast({
                                 title: `Commit success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                              onClose();
                              onCreate();
                           },
                           onError: (res) => {
                              toast({
                                 title: res.response?.data.message,
                                 status: "error",
                                 position: "top",
                                 isClosable: true,
                              });
                           },
                           onSettled: () => {
                              setLoad(false);
                           },
                        });
                     }}
                  >
                     Create
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

const showVersionTextFSM = `Value SOFTWARE_IMAGE (\\S+)
Value VERSION (.+?)
Value RELEASE (\\S+)
Value ROMMON (\\S+)
Value HOSTNAME (\\S+)
Value UPTIME (.+)
Value UPTIME_YEARS (\\d+)
Value UPTIME_WEEKS (\\d+)
Value UPTIME_DAYS (\\d+)
Value UPTIME_HOURS (\\d+)
Value UPTIME_MINUTES (\\d+)
Value RELOAD_REASON (.+?)
Value RUNNING_IMAGE (\\S+)
Value List HARDWARE (\\S+|\\S+\\d\\S+)
Value List SERIAL (\\S+)
Value CONFIG_REGISTER (\\S+)
Value List MAC_ADDRESS ([0-9a-fA-F]{2}(:[0-9a-fA-F]{2}){5})
Value RESTARTED (.+)

Start
  ^.*Software,*\\s+\\(\${SOFTWARE_IMAGE}\\),\\sVersion\\s\${VERSION},*\\s+RELEASE.*\\(\${RELEASE}\\)
  ^.*Software,*\\s+\\(\${SOFTWARE_IMAGE}\\),\\sVersion\\s\${VERSION},
  ^ROM:\\s+\${ROMMON}
  ^\\s*\${HOSTNAME}\\s+uptime\\s+is\\s+\${UPTIME} -> Continue
  ^.*\\s+uptime\\s+is.*\\s+\${UPTIME_YEARS}\\syear -> Continue
  ^.*\\s+uptime\\s+is.*\\s+\${UPTIME_WEEKS}\\sweek -> Continue
  ^.*\\s+uptime\\s+is.*\\s+\${UPTIME_DAYS}\\sday -> Continue
  ^.*\\s+uptime\\s+is.*\\s+\${UPTIME_HOURS}\\shour -> Continue
  ^.*\\s+uptime\\s+is.*\\s+\${UPTIME_MINUTES}\\sminute
  ^[sS]ystem\\s+image\\s+file\\s+is\\s+"(.*?):\${RUNNING_IMAGE}"
  ^(?:[lL]ast\\s+reload\\s+reason:|System\\s+returned\\s+to\\s+ROM\\s+by)\\s+\${RELOAD_REASON}\\s*$$
  ^[Pp]rocessor\\s+board\\s+ID\\s+\${SERIAL}
  ^[Cc]isco\\s+\${HARDWARE}\\s+\\(.+\\).+
  ^[Cc]onfiguration\\s+register\\s+is\\s+\${CONFIG_REGISTER}
  ^Base\\s+[Ee]thernet\\s+MAC\\s+[Aa]ddress\\s+:\\s+\${MAC_ADDRESS}
  ^System\\s+restarted\\s+at\\s+\${RESTARTED}$$
  ^Switch\\s+Port -> Stack
  # Capture time-stamp if vty line has command time-stamping turned on
  ^Switch\\s\\d+ -> Stack
  ^Load\\s+for\\s+
  ^Time\\s+source\\s+is


Stack
  ^[Ss]ystem\\s+[Ss]erial\\s+[Nn]umber\\s+:\\s+\${SERIAL}
  ^[Mm]odel\\s+[Nn]umber\\s+:\\s+\${HARDWARE}\\s*
  ^[Cc]onfiguration\\s+register\\s+is\\s+\${CONFIG_REGISTER}
  ^Base [Ee]thernet MAC [Aa]ddress\\s+:\\s+\${MAC_ADDRESS}`;

const showInterfaceTextFSM = `Value Required INTERFACE (\\S+)
Value LINK_STATUS (.+?)
Value PROTOCOL_STATUS (.+?)
Value HARDWARE_TYPE ([\\w ]+)
Value MAC_ADDRESS ([a-fA-F0-9]{4}\\.[a-fA-F0-9]{4}\\.[a-fA-F0-9]{4})
Value BIA ([a-fA-F0-9]{4}\\.[a-fA-F0-9]{4}\\.[a-fA-F0-9]{4})
Value DESCRIPTION (.+?)
Value IP_ADDRESS (\\d+\\.\\d+\\.\\d+\\.\\d+)
Value PREFIX_LENGTH (\\d+)
Value MTU (\\d+)
Value DUPLEX (([Ff]ull|[Aa]uto|[Hh]alf|[Aa]-).*?)
Value SPEED (.*?)
Value MEDIA_TYPE (\\S+.*)
Value BANDWIDTH (\\d+\\s+\\w+)
Value DELAY (\\d+\\s+\\S+)
Value ENCAPSULATION (.+?)
Value LAST_INPUT (.+?)
Value LAST_OUTPUT (.+?)
Value LAST_OUTPUT_HANG (.+?)
Value QUEUE_STRATEGY (.+)
Value INPUT_RATE (\\d+)
Value OUTPUT_RATE (\\d+)
Value INPUT_PPS (\\d+)
Value OUTPUT_PPS (\\d+)
Value INPUT_PACKETS (\\d+)
Value OUTPUT_PACKETS (\\d+)
Value RUNTS (\\d+)
Value GIANTS (\\d+)
Value INPUT_ERRORS (\\d+)
Value CRC (\\d+)
Value FRAME (\\d+)
Value OVERRUN (\\d+)
Value ABORT (\\d+)
Value OUTPUT_ERRORS (\\d+)
Value VLAN_ID (\\d+)
Value VLAN_ID_INNER (\\d+)
Value VLAN_ID_OUTER (\\d+)

Start
  ^\\S+\\s+is\\s+.+?,\\s+line\\s+protocol.*$$ -> Continue.Record
  ^\${INTERFACE}\\s+is\\s+\${LINK_STATUS},\\s+line\\s+protocol\\s+is\\s+\${PROTOCOL_STATUS}\\s*$$
  ^\\s+Hardware\\s+is\\s+\${HARDWARE_TYPE} -> Continue
  ^.+address\\s+is\\s+\${MAC_ADDRESS}\\s+\\(bia\\s+\${BIA}\\)\\s*$$
  ^\\s+Description:\\s+\${DESCRIPTION}\\s*$$
  ^\\s+Internet\\s+address\\s+is\\s+\${IP_ADDRESS}\\/\${PREFIX_LENGTH}\\s*$$
  ^\\s+MTU\\s+\${MTU}.*BW\\s+\${BANDWIDTH}.*DLY\\s+\${DELAY},\\s*$$
  ^\\s+Encapsulation\\s+\${ENCAPSULATION}, Vlan ID\\s+\${VLAN_ID}.+$$
  ^\\s+Encapsulation\\s+\${ENCAPSULATION}, outer ID\\s+\${VLAN_ID_OUTER}, inner ID\\s+\${VLAN_ID_INNER}.+$$
  ^\\s+Encapsulation\\s+\${ENCAPSULATION},.+$$
  ^\\s+Last\\s+input\\s+\${LAST_INPUT},\\s+output\\s+\${LAST_OUTPUT},\\s+output\\s+hang\\s+\${LAST_OUTPUT_HANG}\\s*$$
  ^\\s+Queueing\\s+strategy:\\s+\${QUEUE_STRATEGY}\\s*$$
  ^\\s+\${DUPLEX},\\s+\${SPEED},.+media\\stype\\sis\\s\${MEDIA_TYPE}$$
  ^\\s+\${DUPLEX},\\s+\${SPEED},.+TX/FX$$
  ^\\s+\${DUPLEX},\\s+\${SPEED}$$
  ^.*input\\s+rate\\s+\${INPUT_RATE}\\s+\\w+/sec,\\s+\${INPUT_PPS}\\s+packets.+$$
  ^.*output\\s+rate\\s+\${OUTPUT_RATE}\\s+\\w+/sec,\\s+\${OUTPUT_PPS}\\s+packets.+$$
  ^\\s+\${INPUT_PACKETS}\\s+packets\\s+input,\\s+\\d+\\s+bytes,\\s+\\d+\\s+no\\s+buffer\\s*$$
  ^\\s+\${RUNTS}\\s+runts,\\s+\${GIANTS}\\s+giants,\\s+\\d+\\s+throttles\\s*$$
  ^\\s+\${INPUT_ERRORS}\\s+input\\s+errors,\\s+\${CRC}\\s+CRC,\\s+\${FRAME}\\s+frame,\\s+\${OVERRUN}\\s+overrun,\\s+\\d+\\s+ignored\\s*$$
  ^\\s+\${INPUT_ERRORS}\\s+input\\s+errors,\\s+\${CRC}\\s+CRC,\\s+\${FRAME}\\s+frame,\\s+\${OVERRUN}\\s+overrun,\\s+\\d+\\s+ignored,\\s+\${ABORT}\\s+abort\\s*$$
  ^\\s+\${OUTPUT_PACKETS}\\s+packets\\s+output,\\s+\\d+\\s+bytes,\\s+\\d+\\s+underruns\\s*$$
  ^\\s+\${OUTPUT_ERRORS}\\s+output\\s+errors,\\s+\\d+\\s+collisions,\\s+\\d+\\s+interface\\s+resets\\s*$$
  # Capture time-stamp if vty line has command time-stamping turned on
  ^Load\\s+for\\s+
  ^Time\\s+source\\s+is`;

const showRunInterfaceTextFSM = `Value INTERFACE (\\S+)
Value DESCRIPTION (.+)
Value ACCESS_VLAN (\\d+)
Value TRUNK_NATIVE_VLAN (\\d.+)
Value TRUNK_ALLOW_VLAN (\\d.+)
Value TRUNK_ALLOW_VLAN_ADD (\\d.+)
Value MEMBER_PORT_CHANNEL (\\d+)
Value MODE (.+)

Start
  ^interface\\s+\${INTERFACE}
  ^\\s+description\\s+\${DESCRIPTION}
  ^\\s+switchport\\s+access\\s+vlan\\s+\${ACCESS_VLAN}
  ^\\s+switchport\\s+trunk\\s+allowed\\s+vlan\\s+\${TRUNK_ALLOW_VLAN}
  ^\\s+switchport\\s+trunk\\s+allowed\\s+vlan\\s+add\\s+\${TRUNK_ALLOW_VLAN_ADD}
  ^\\s+switchport\\s+trunk\\s+native\\s+vlan\\s+\${TRUNK_NATIVE_VLAN}
  ^\\s+switchport\\s+mode\\s+\${MODE}
  ^\\s+channel-group\\s+\${MEMBER_PORT_CHANNEL}\\s+mode\\s+active
  ^end -> Record`;

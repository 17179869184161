import React, { useMemo, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillEye,
} from "react-icons/ai";
import { IPort, SwitchLog } from "../../ts/interfaces/switch_device";
import axios from "axios";
import { useQuery } from "react-query";
import { MdCable } from "react-icons/md";
import DataTable from "react-data-table-component";
import moment from "dayjs";

export const ViewPortModal: React.FC<{ data: IPort }> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [load, setLoad] = useState(false);

  const {
    isLoading,
    data: portData,
    refetch,
  } = useQuery(
    ["switch-management", "member-port", data?.id],
    () => {
      return axios
        .get<IPort>(`/switch-management/ports/` + data?.id)
        .then(({ data }) => {
          return data;
        });
    },
    {
      enabled: !!data && isOpen,
    },
  );

  const columnsLog = useMemo(
    () => [
      {
        name: "Time",
        cell: (row: SwitchLog) =>
          `${moment(row.created_at).format("DD-MM-YYYY HH:mm:ss")}`,
        width: "200px",
        sortable: false,
      },
      {
        name: "Content",
        cell: (row: SwitchLog) => `${row.content}`,
        sortable: false,
      },
    ],
    [],
  );

  function YorN(data: boolean) {
    return (
      <Flex>
        <Box></Box>
        <Spacer />
        {data
          ? <AiFillCheckCircle size={16} color="green" />
          : <AiFillCloseCircle size={16} color="red" />}
      </Flex>
    );
  }

  return (
    <>
      <IconButton
        icon={<AiFillEye />}
        aria-label={"Add"}
        onClick={() => {
          onOpen();
          refetch();
        }}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Port :: {data?.name}</ModalHeader>
          <ModalBody>
            <TableContainer>
              <Table variant="simple" size="sm">
                <Tbody>
                  <Tr>
                    <Td>Description</Td>
                    <Td isNumeric>{data.description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Note</Td>
                    <Td
                      isNumeric
                      maxWidth="15vw"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {data.note}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Protocol Status</Td>
                    <Td isNumeric>
                      <Badge
                        colorScheme={data.port_status.includes("up")
                          ? "green"
                          : "red"}
                        cursor="pointer"
                      >
                        {data.port_status}
                      </Badge>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Link Status</Td>
                    <Td isNumeric>
                      <Badge
                        colorScheme={data.link_state.includes("up")
                          ? "green"
                          : "red"}
                        cursor="pointer"
                      >
                        {data.link_state}
                      </Badge>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Created At</Td>
                    <Td isNumeric>
                      {moment(data.created_at).format(
                        "DD-MM-YYYY HH:mm:ss",
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Mode</Td>
                    <Td isNumeric>{data.mode}</Td>
                  </Tr>
                  <Tr>
                    <Td>Interface Type</Td>
                    <Td isNumeric>{data.interface_type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Vlan</Td>
                    <Td isNumeric>
                      {data.mode === "access"
                        ? (
                          <Tooltip label="Access Vlan">
                            <Badge
                              colorScheme="green"
                              m={1}
                              cursor="pointer"
                            >
                              {data.access_vlan}
                            </Badge>
                          </Tooltip>
                        )
                        : (
                          <Box margin="auto">
                            {data.trunk_need_native_vlan && (
                              <Tooltip label="Native Vlan">
                                <Badge
                                  colorScheme="blue"
                                  m={1}
                                  cursor="pointer"
                                >
                                  {data.trunk_native_vlan}
                                </Badge>
                              </Tooltip>
                            )}
                            {data.trunk_allow_vlan
                              ?.split(",")
                              .filter((vlan) => vlan !== "")
                              .map((vlan) => (
                                <Tooltip
                                  label="Allow Vlan"
                                  key={vlan}
                                >
                                  <Badge
                                    colorScheme="green"
                                    m={1}
                                    cursor="pointer"
                                  >
                                    {vlan}
                                  </Badge>
                                </Tooltip>
                              ))}
                          </Box>
                        )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Interface Type</Td>
                    <Td isNumeric>{data.interface_type}</Td>
                  </Tr>
                  <Tr>
                    <Td>No shutdown</Td>
                    <Td isNumeric>{YorN(!data.shutdown)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Restrict</Td>
                    <Td isNumeric>{YorN(data.restrict)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Deletable</Td>
                    <Td isNumeric>{YorN(data.deletable)}</Td>
                  </Tr>
                  <Tr>
                    <Td>PortFast</Td>
                    <Td isNumeric>{YorN(data.port_fast)}</Td>
                  </Tr>
                  <Tr>
                    <Td>No Keepalive</Td>
                    <Td isNumeric>{YorN(data.no_keep_alive)}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Flex mt={4}>
              <MdCable size={24} />
              <Text ml={2}>Logs ::</Text>
            </Flex>
            <Box
              p={4}
              mt={4}
              border="1px"
              borderColor="gray.200"
              maxHeight="50vh"
              overflow="auto"
            >
              <DataTable
                columns={columnsLog}
                noHeader={true}
                data={portData?.logs || []}
                progressPending={isLoading}
                highlightOnHover
              />
            </Box>
          </ModalBody>
          <ModalCloseButton />
          <ModalFooter>
            <Button onClick={onClose} isDisabled={load}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

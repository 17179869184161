import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   StackDivider,
   VStack,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import { INode } from "../../ts/interfaces/node_interface";
import { CreateNode } from "./CreateNode";
import { EditNode } from "./EditNode";
import { DeleteNode } from "./DeleteNode";

const NodeManagement: React.FC = () => {
   const [data, setData] = useState<INode[]>([]);
   const [loading, setLoad] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);
   const [search, setSearch] = useState("");

   const fetch = (page: number = currentPage, limit: number = perPage) => {
      setLoad(true);
      setCurrentPage(page);
      axios
         .get<{
            nodes: INode[];
            meta: {
               limit: number;
               total: number;
            };
         }>(
            `/admin/nodes?limit=${limit}&offset=${
               (page - 1) * perPage
            }&search=${search}`
         )
         .then(({ data }) => {
            setData(data.nodes);
            setLoad(false);
            setTotalRows(data.meta.total);
            setPerPage(data.meta.limit);
         });
   };

   useEffect(() => {
      fetch();
   }, []);

   const columns = [
      {
         name: "Name",
         selector: "name",
         sortable: true,
         width: "30%",
      },
      {
         name: "Priority",
         width: "20%",
         cell: (row: INode) => row.priority,
      },
      {
         name: "Active",
         width: "20%",
         cell: (row: INode) => (
            <span style={{ color: row.active ? "green" : "red" }}>
               {row.active ? "YES" : "No"}
            </span>
         ),
      },
      {
         name: "",
         cell: (row: INode) => (
            <HStack
               spacing={2}
               divider={<StackDivider borderColor="gray.200" />}
            >
               <EditNode node={row} onEdit={fetch} />
               <DeleteNode node={row} onDelete={fetch} />
            </HStack>
         ),
         right: true,
         sortable: false,
      },
   ];

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
      fetch(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      fetch(page, newPerPage);
   };

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <Flex w={{ base: "100%", lg: "auto" }}>
               <Flex mr={2} w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     onChange={(e) => setSearch(e.target.value)}
                     bg={"white"}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                     onClick={() => fetch()}
                  />
               </Flex>
               {/* <CreateUser onCreate={fetch} /> */}
               <CreateNode onCreate={fetch} />
            </Flex>
         </Flex>
         <Box>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data}
               progressPending={loading}
               pagination
               paginationServer
               paginationTotalRows={totalRows}
               onChangeRowsPerPage={handlePerRowsChange}
               onChangePage={handlePageChange}
               paginationPerPage={perPage}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
            />
         </Box>
      </VStack>
   );
};

export { NodeManagement };

import React, { useMemo } from "react";
import { IPort } from "../../ts/interfaces/switch_device";
import { useQuery } from "react-query";
import axios from "axios";
import {
   Badge,
   Box,
   Flex,
   HStack,
   StackDivider,
   Text,
   Tooltip,
} from "@chakra-ui/react";
import { SettingPortModal } from "./SettingPort";
import { MdCable } from "react-icons/md";
import DataTable from "react-data-table-component";
import { ViewPortModal } from "./ViewPort";

export const PortExpander: React.FC<{ data?: IPort }> = ({ data }) => {
   const {
      isLoading,
      data: portData,
      refetch,
   } = useQuery(
      ["switch-management", "member-port", data?.id],
      () => {
         return axios
            .get<IPort>(`/switch-management/ports/` + data?.id)
            .then(({ data }) => {
               return data;
            });
      },
      {
         enabled: !!data,
      }
   );

   const columns = useMemo(
      () => [
         {
            name: "Name",
            cell: (row: IPort) => `${row.name}`,
            width: "180px",
            sortable: false,
         },
         {
            name: "Description",
            cell: (row: IPort) => `${row.description}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "Note",
            cell: (row: IPort) => `${row.note}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "Status",
            cell: (row: IPort) => (
               <Tooltip
                  label={
                     <div>
                        <div>Protocol Status: {row.port_status}</div>
                        <div>Link Status: {row.link_state}</div>
                     </div>
                  }
               >
                  <Badge
                     colorScheme={
                        row.port_status.includes("up") ? "green" : "red"
                     }
                     cursor="pointer"
                  >
                     {row.port_status.includes("up") ? "UP" : "DOWN"}
                  </Badge>
               </Tooltip>
            ),
            width: "200px",
            sortable: false,
         },
         {
            name: "",
            cell: (row: IPort) => (
               <HStack
                  spacing={2}
                  divider={<StackDivider borderColor="gray.200" />}
               >
                  <SettingPortModal onSave={refetch} port={row} />
                  <ViewPortModal data={row} />
               </HStack>
            ),
            right: true,
         },
      ],
      []
   );

   return (
      <>
         <Box
            p={6}
            border="1px"
            borderColor="gray.100"
            borderLeftColor="red.500"
            borderLeftWidth={5}
         >
            <Flex>
               <MdCable size={24} />
               <Text ml={2}>Member Interfaces ::</Text>
            </Flex>

            <Box p={4} mt={4} border="1px" borderColor="gray.200">
               <DataTable
                  columns={columns}
                  noHeader={true}
                  data={portData?.member_ports || []}
                  progressPending={isLoading}
                  paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
                  highlightOnHover
               />
            </Box>
         </Box>
      </>
   );
};

import { IRule } from "../../ts/interfaces/firewall-group";
import React, { useEffect, useState } from "react";
import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogCloseButton,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   Button,
   FormControl,
   HStack,
   IconButton,
   Input,
   Select,
   StackDivider,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { AiFillCaretDown, AiFillCaretUp, AiFillDelete } from "react-icons/ai";
import { IoIosAddCircle } from "react-icons/io";
import DataTable from "react-data-table-component";

interface RuleManagement {
   rules: IRule[];
   add: (rule: IRule) => void;
   del: (idx: number) => void;
   up: (idx: number) => void;
   down: (idx: number) => void;
}

const VerifyIP = (ip: string) => {
   const ipInvalid =
      /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)($|\/(0|16|24|32))?$/gi;
   return ipInvalid.test(ip);
};

const VerifyPortRange = (port: string) => {
   return /^(\d+|(\d+)\s*-\s*(\d+)|Any)$/gi.test(port);
};

export const RuleManagement: React.FC<RuleManagement> = ({
   rules,
   del,
   add,
   up,
   down,
}) => {
   const [protocol, setProtocol] = useState("");
   const [source, setSource] = useState("");
   const [destination, setDestination] = useState("");
   const [portRange, setPortRange] = useState("");
   const [action, setAction] = useState("");

   const columns = [
      {
         name: "",
         cell: (row: IRule, index: number) => (
            <HStack spacing={1}>
               <IconButton
                  colorScheme="gray"
                  icon={<AiFillCaretUp />}
                  aria-label={"Up"}
                  size="xs"
                  onClick={() => up(index)}
               />
               <IconButton
                  colorScheme="gray"
                  icon={<AiFillCaretDown />}
                  aria-label={"Down"}
                  size="xs"
                  onClick={() => down(index)}
               />
            </HStack>
         ),
         width: "60px",
      },
      {
         name: "Protocol",
         width: "100px",
         cell: (row: IRule, index: number) => (
            <div>{row.protocol.toUpperCase()}</div>
         ),
      },
      {
         name: "Source",
         selector: "source",
         width: "140px",
      },
      {
         name: "Destination",
         selector: "destination",
         width: "140px",
      },
      {
         name: "Port Range",
         width: "100px",
         cell: (row: IRule, index: number) => (
            <div>{row.port_range.toUpperCase()}</div>
         ),
      },
      {
         name: "Action",
         cell: (row: IRule, index: number) => (
            <div>{row.action.toUpperCase()}</div>
         ),
      },
      {
         name: "",
         cell: (row: IRule, index: number) => (
            <HStack
               spacing={2}
               divider={<StackDivider borderColor="gray.200" />}
            >
               <DeleteRule
                  del={() => {
                     del(index);
                  }}
               />
            </HStack>
         ),
         right: true,
         sortable: false,
         width: "50px",
      },
   ];

   useEffect(() => {
      if (protocol === "icmp") {
         setPortRange("-");
      } else {
         if (portRange === "-") {
            setPortRange("");
         }
      }
   }, [protocol]);

   return (
      <FormControl>
         <HStack spacing={2}>
            <Select
               placeholder="Protocol"
               onChange={(e) => {
                  setProtocol(e.target.value);
               }}
               value={protocol}
            >
               <option key="all" value="all">
                  All
               </option>
               <option key="tcp" value="tcp">
                  TCP
               </option>
               <option key="udp" value="udp">
                  UDP
               </option>
               <option key="icmp" value="icmp">
                  ICMP
               </option>
            </Select>
            <Input
               placeholder="Soruce"
               onChange={(e) => {
                  setSource(e.target.value);
               }}
               value={source}
            />
            <Input
               placeholder="Destination"
               onChange={(e) => {
                  setDestination(e.target.value);
               }}
               value={destination}
            />
            <Input
               placeholder="Port Range"
               onChange={(e) => {
                  setPortRange(e.target.value);
               }}
               disabled={protocol === "icmp"}
               value={portRange}
            />

            <Select
               placeholder="Action"
               onChange={(e) => {
                  setAction(e.target.value);
               }}
               value={action}
            >
               <option key="allow" value="allow">
                  Allow
               </option>
               <option key="deny" value="deny">
                  Deny
               </option>
            </Select>

            <IconButton
               icon={<IoIosAddCircle />}
               aria-label={"icon"}
               colorScheme="brand"
               onClick={() => {
                  add({
                     protocol: protocol,
                     port_range: portRange || "all",
                     source: source || "0.0.0.0/0",
                     destination: destination || "0.0.0.0/0",
                     action: action,
                  } as IRule);
                  setAction("");
                  setSource("");
                  setDestination("");
                  setProtocol("");
                  setPortRange("");
               }}
               disabled={
                  !protocol ||
                  !action ||
                  (!!source && !VerifyIP(source)) ||
                  (!!destination && !VerifyIP(destination)) ||
                  (protocol !== "icmp" &&
                     !!portRange &&
                     !VerifyPortRange(portRange))
               }
            />
         </HStack>
         <DataTable
            columns={columns}
            noHeader={true}
            data={rules}
            highlightOnHover
         />
      </FormControl>
   );
};

const DeleteRule: React.FC<{ del: () => void }> = ({ del }) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const cancelRef = React.useRef<HTMLButtonElement | null>(null);
   const toast = useToast();

   return (
      <>
         <IconButton
            colorScheme="danger"
            icon={<AiFillDelete />}
            aria-label={"Del"}
            onClick={() => {
               onOpen();
            }}
         />
         <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
         >
            <AlertDialogOverlay />

            <AlertDialogContent>
               <AlertDialogHeader>Delete rule?</AlertDialogHeader>
               <AlertDialogCloseButton />
               <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
               </AlertDialogBody>
               <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                     Cancel
                  </Button>
                  <Button
                     colorScheme="danger"
                     ml={3}
                     onClick={() => {
                        del();
                     }}
                  >
                     Delete
                  </Button>
               </AlertDialogFooter>
            </AlertDialogContent>
         </AlertDialog>
      </>
   );
};

import React, { FC, useState } from "react";
import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogCloseButton,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   Button,
   IconButton,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import { IType } from "../../ts/interfaces/announces_interface";

interface DeleteUserProps {
   type: IType;
   onDelete: () => void;
}

export const DeleteFirewallType: FC<DeleteUserProps> = ({ type, onDelete }) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const cancelRef = React.useRef<HTMLButtonElement | null>(null);
   const [load, setLoad] = useState(false);
   const toast = useToast();

   return (
      <>
         <IconButton
            icon={<AiFillDelete />}
            aria-label={"Add"}
            onClick={() => {
               setLoad(false);
               onOpen();
            }}
            colorScheme="danger"
         />
         <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
         >
            <AlertDialogOverlay />

            <AlertDialogContent>
               <AlertDialogHeader>Delete user?</AlertDialogHeader>
               <AlertDialogCloseButton />
               <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
               </AlertDialogBody>
               <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                     Cancel
                  </Button>
                  <Button
                     colorScheme="danger"
                     ml={3}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        axios
                           .delete(`/admin/announces/types/${type.id}`)
                           .then(() => {
                              setLoad(false);
                              toast({
                                 title: `Commit success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                              onDelete();
                           })
                           .catch((error) => {
                              let res = error.response;
                              if (res) {
                                 toast({
                                    title: res.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              }
                           })
                           .finally(() => onClose());
                     }}
                  >
                     Delete
                  </Button>
               </AlertDialogFooter>
            </AlertDialogContent>
         </AlertDialog>
      </>
   );
};

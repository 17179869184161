import React, { FC, useState } from "react";
import {
   Alert,
   Badge,
   Button,
   Checkbox,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Select,
   Textarea,
   useDisclosure,
   useToast,
   VStack,
} from "@chakra-ui/react";
import { useMutation } from "react-query";
import axios, { AxiosError } from "axios";
import { APIError } from "../../ts/interfaces/error";
import { ISwitchModel } from "../../ts/interfaces/switch_device";
import { AiFillSetting } from "react-icons/ai";

interface CreateSwitchDeviceModalProps {
   onSave: () => void;
   switchModel: ISwitchModel;
}

export const EditSwitchModelModal: FC<CreateSwitchDeviceModalProps> = ({
   onSave,
   switchModel,
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const toast = useToast();

   const initData = {
      name: switchModel.name,
      shutdown: switchModel.shutdown,
      no_shutdown: switchModel.no_shutdown,
      access: switchModel.access,
      no_access: switchModel.no_access,
      access_port_fast: switchModel.access_port_fast,
      no_access_port_fast: switchModel.no_access_port_fast,
      access_vlan: switchModel.access_vlan,
      no_access_vlan: switchModel.no_access_vlan,
      trunk: switchModel.trunk,
      no_trunk: switchModel.no_trunk,
      trunk_port_fast: switchModel.trunk_port_fast,
      no_trunk_port_fast: switchModel.no_trunk_port_fast,
      trunk_native: switchModel.trunk_native,
      no_trunk_native: switchModel.no_trunk_native,
      trunk_allow: switchModel.trunk_allow,
      no_trunk_allow: switchModel.no_trunk_allow,
      keep_alive: switchModel.keep_alive,
      no_keep_alive: switchModel.no_keep_alive,
      channel_group: switchModel.channel_group,
      no_channel_group: switchModel.no_channel_group,
      need_double_quote: switchModel.need_double_quote,
      show_version_text_fsm: switchModel.show_version_text_fsm,
      show_interface_text_fsm: switchModel.show_interface_text_fsm,
      show_run_interface_text_fsm: switchModel.show_run_interface_text_fsm,
      save: switchModel.save,
      device_type: switchModel.device_type,
      port_pattern: switchModel.port_pattern,
      port_number_pattern: switchModel.port_number_pattern,
      port_channel_pattern: switchModel.port_channel_pattern,
      shorthand_port_pattern: switchModel.shorthand_port_pattern,
   };

   const [formData, setFormData] = useState(initData);

   const [ipConnect, setIpConnect] = useState("");

   const { mutateAsync } = useMutation<any, AxiosError<APIError>, any>(
      ["admin", "create-switch-model"],
      (data) => {
         return axios
            .patch("/admin/switch-model/" + switchModel.id, data)
            .then((res) => res.data);
      }
   );

   return (
      <>
         <IconButton
            icon={<AiFillSetting />}
            colorScheme="warning"
            aria-label={"Add"}
            onClick={() => {
               onOpen();
               setFormData(initData);
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>
                  Edit Switch Model :: {switchModel.name}
               </ModalHeader>
               <ModalCloseButton />
               <ModalBody maxHeight="70vh" overflow="auto">
                  <VStack spacing={4}>
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           placeholder="Name"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 name: e.target.value,
                              })
                           }
                           value={formData.name}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Port Pattern</FormLabel>
                        <Input
                           placeholder="Port Pattern"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 port_pattern: e.target.value,
                              })
                           }
                           value={formData.port_pattern}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Shorthand Port Pattern</FormLabel>
                        <Input
                           placeholder="Shorthand Port Pattern"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 shorthand_port_pattern: e.target.value,
                              })
                           }
                           value={formData.shorthand_port_pattern}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>PortNumber Pattern</FormLabel>
                        <Input
                           placeholder="PortNumber Pattern"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 port_number_pattern: e.target.value,
                              })
                           }
                           value={formData.port_number_pattern}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>PortChannel Pattern</FormLabel>
                        <Input
                           placeholder="PortChannel Pattern"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 port_channel_pattern: e.target.value,
                              })
                           }
                           value={formData.port_channel_pattern}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Device Type</FormLabel>
                        <Select
                           placeholder="Device Type"
                           value={formData.device_type}
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 device_type: e.target.value,
                              })
                           }
                        >
                           <option value="cisco_asa">Cisco ASA</option>
                           <option value="cisco_ios">Cisco IOS</option>
                           <option value="cisco_iosxr">Cisco IOS XR</option>
                           <option value="cisco_nxos">Cisco NX-OS</option>
                           <option value="juniper">Juniper JunOS</option>
                           <option value="mikrotik_routeros">
                              Mikrotik Routeros
                           </option>
                        </Select>
                     </FormControl>
                     <FormControl>
                        <FormLabel>Save</FormLabel>
                        <Textarea
                           placeholder="Save command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 save: e.target.value,
                              })
                           }
                           value={formData.save}
                           rows={formData.save.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Shutdown</FormLabel>
                        <Textarea
                           placeholder="Shutdown command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 shutdown: e.target.value,
                              })
                           }
                           value={formData.shutdown}
                           rows={formData.shutdown.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Shutdown</FormLabel>
                        <Textarea
                           placeholder="No Shutdown command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_shutdown: e.target.value,
                              })
                           }
                           value={formData.no_shutdown}
                           rows={formData.no_shutdown.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Access</FormLabel>
                        <Textarea
                           placeholder="Access command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 access: e.target.value,
                              })
                           }
                           value={formData.access}
                           rows={formData.access.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Access</FormLabel>
                        <Textarea
                           placeholder="No Access command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_access: e.target.value,
                              })
                           }
                           value={formData.no_access}
                           rows={formData.no_access.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Access PortFast</FormLabel>
                        <Textarea
                           placeholder="Access PortFast command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 access_port_fast: e.target.value,
                              })
                           }
                           value={formData.access_port_fast}
                           rows={formData.access_port_fast.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Access PortFast</FormLabel>
                        <Textarea
                           placeholder="No Access PortFast command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_access_port_fast: e.target.value,
                              })
                           }
                           value={formData.no_access_port_fast}
                           rows={
                              formData.no_access_port_fast.split("\n").length
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Access Vlan</FormLabel>
                        <Alert mb={4}>
                           <div>
                              <div>
                                 <Badge cursor="pointer">{"{vlan}"}</Badge> for
                                 access vlan
                              </div>
                           </div>
                        </Alert>
                        <Textarea
                           placeholder="Access Vlan command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 access_vlan: e.target.value,
                              })
                           }
                           value={formData.access_vlan}
                           rows={formData.access_vlan.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Access Vlan</FormLabel>
                        <Textarea
                           placeholder="No Access Vlan command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_access_vlan: e.target.value,
                              })
                           }
                           value={formData.no_access_vlan}
                           rows={formData.no_access_vlan.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Trunk</FormLabel>
                        <Textarea
                           placeholder="Trunk command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 trunk: e.target.value,
                              })
                           }
                           value={formData.trunk}
                           rows={formData.trunk.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Trunk</FormLabel>
                        <Textarea
                           placeholder="No Trunk command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_trunk: e.target.value,
                              })
                           }
                           value={formData.no_trunk}
                           rows={formData.no_trunk.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Trunk PortFast</FormLabel>
                        <Textarea
                           placeholder="Trunk PortFast command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 trunk_port_fast: e.target.value,
                              })
                           }
                           value={formData.trunk_port_fast}
                           rows={formData.trunk_port_fast.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Trunk PortFast</FormLabel>
                        <Textarea
                           placeholder="No Trunk PortFast command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_trunk_port_fast: e.target.value,
                              })
                           }
                           value={formData.no_trunk_port_fast}
                           rows={formData.no_trunk_port_fast.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Trunk Native</FormLabel>
                        <Alert mb={4}>
                           <div>
                              <div>
                                 <Badge cursor="pointer">
                                    {"{native_vlan}"}
                                 </Badge>{" "}
                                 for trunk native vlan
                              </div>
                           </div>
                        </Alert>
                        <Textarea
                           placeholder="Trunk Native command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 trunk_native: e.target.value,
                              })
                           }
                           value={formData.trunk_native}
                           rows={formData.trunk_native.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Trunk Native</FormLabel>
                        <Textarea
                           placeholder="No Trunk Native command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_trunk_native: e.target.value,
                              })
                           }
                           value={formData.no_trunk_native}
                           rows={formData.no_trunk_native.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Trunk Allow</FormLabel>
                        <Alert mb={4}>
                           <div>
                              <div>
                                 <Badge cursor="pointer">
                                    {"{allow_vlan}"}
                                 </Badge>{" "}
                                 for trunk allow vlan
                              </div>
                           </div>
                        </Alert>
                        <Textarea
                           placeholder="Trunk Allow command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 trunk_allow: e.target.value,
                              })
                           }
                           value={formData.trunk_allow}
                           rows={formData.trunk_allow.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No Trunk Allow</FormLabel>
                        <Textarea
                           placeholder="No Trunk Allow command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_trunk_allow: e.target.value,
                              })
                           }
                           value={formData.no_trunk_allow}
                           rows={formData.no_trunk_allow.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>KeepAlive</FormLabel>
                        <Textarea
                           placeholder="KeepAlive command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 keep_alive: e.target.value,
                              })
                           }
                           value={formData.keep_alive}
                           rows={formData.keep_alive.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No KeepAlive</FormLabel>
                        <Textarea
                           placeholder="No KeepAlive command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_keep_alive: e.target.value,
                              })
                           }
                           value={formData.no_keep_alive}
                           rows={formData.no_keep_alive.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>ChannelGroup</FormLabel>
                        <Alert mb={4}>
                           <div>
                              <div>
                                 <Badge cursor="pointer">{"{group}"}</Badge> for
                                 port channel group
                              </div>
                           </div>
                        </Alert>
                        <Textarea
                           placeholder="ChannelGroup command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 channel_group: e.target.value,
                              })
                           }
                           value={formData.channel_group}
                           rows={formData.channel_group.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>No ChannelGroup</FormLabel>
                        <Textarea
                           placeholder="No ChannelGroup command"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 no_channel_group: e.target.value,
                              })
                           }
                           value={formData.no_channel_group}
                           rows={formData.no_channel_group.split("\n").length}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Checkbox
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 need_double_quote: e.target.checked,
                              })
                           }
                           isChecked={formData.need_double_quote}
                        >
                           Need DoubleQuote
                        </Checkbox>
                     </FormControl>
                     <FormControl>
                        <FormLabel>ShowVersion TextFSM</FormLabel>
                        <Textarea
                           placeholder="ShowVersion TextFSM parser"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 show_version_text_fsm: e.target.value,
                              })
                           }
                           value={formData.show_version_text_fsm}
                           rows={
                              formData.show_version_text_fsm.split("\n").length
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>ShowInterface TextFSM</FormLabel>
                        <Textarea
                           placeholder="ShowInterface TextFSM parser"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 show_interface_text_fsm: e.target.value,
                              })
                           }
                           value={formData.show_interface_text_fsm}
                           rows={
                              formData.show_interface_text_fsm.split("\n")
                                 .length
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>ShowRunInterface TextFSM</FormLabel>
                        <Textarea
                           placeholder="ShowRunInterface TextFSM parser"
                           onChange={(e) =>
                              setFormData({
                                 ...formData,
                                 show_run_interface_text_fsm: e.target.value,
                              })
                           }
                           value={formData.show_run_interface_text_fsm}
                           rows={
                              formData.show_run_interface_text_fsm.split("\n")
                                 .length
                           }
                        />
                     </FormControl>
                  </VStack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        mutateAsync(formData, {
                           onSuccess: (data) => {
                              toast({
                                 title: `Commit success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                              onClose();
                              onSave();
                           },
                           onError: (res) => {
                              toast({
                                 title: res.response?.data.message,
                                 status: "error",
                                 position: "top",
                                 isClosable: true,
                              });
                           },
                           onSettled: () => {
                              setLoad(false);
                           },
                        });
                     }}
                  >
                     Save
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

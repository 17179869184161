import React, { FC, useState } from "react";
import {
   Button,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Select,
   Stack,
   Textarea,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { AiFillFileAdd } from "react-icons/ai";
import axios from "axios";
import { PasswordField } from "../Login/PasswordField";
import { IUserGroup } from "../../ts/interfaces/user_group";

interface CreateUserProps {
   onCreate: () => void;
}

export const CreateUser: FC<CreateUserProps> = ({ onCreate }) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [ipGroups, setIPGroups] = useState("");
   const [vlans, setVlans] = useState("");
   const [role, setRole] = useState("customer");
   const [userGroupId, setUserGroupId] = useState("");

   const [load, setLoad] = useState(false);
   const toast = useToast();

   const getIPGroup = (): string[] => {
      let newIP: string[] = [];
      ipGroups
         .replaceAll("\r\n", "\n")
         .split("\n")
         .forEach((ip) => {
            if (
               /(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|)){4})-(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                  ip
               )
            ) {
               let ex = ip.split("-");
               let main = ex[0].split(".");
               let start = Number(main[3]);
               let end = Number(ex[1]);
               for (let i = start; i <= end; i++) {
                  newIP.push(`${main[0]}.${main[1]}.${main[2]}.${i}/32`);
               }
            } else {
               newIP.push(ip);
            }
         });
      return newIP;
   };

   const getVlans = (): string[] => {
      let newVlans: string[] = [];
      vlans
         .replaceAll("\r\n", ",")
         .replaceAll("\n", ",")
         .split(",")
         .map((vlan) => vlan.trim())
         .forEach((vlan) => {
            const number = +vlan;
            if (isNaN(number)) {
               return;
            }
            if (newVlans.includes(vlan)) {
               return;
            }
            if (number > 4093 || number < 1) {
               return;
            }
            newVlans.push(vlan);
         });
      return newVlans;
   };

   const [userGroupList, setUserGroupList] = useState<IUserGroup[]>([]);

   const fetchUserGroup = () => {
      setLoad(true);
      axios
         .get<{
            user_groups: IUserGroup[];
            meta: {
               limit: number;
               total: number;
            };
         }>(`/admin/user-groups`)
         .then(({ data }) => {
            setUserGroupList(data.user_groups);
            setLoad(false);
         });
   };

   return (
      <>
         <IconButton
            aria-label={"icon"}
            icon={<AiFillFileAdd />}
            onClick={() => {
               setEmail("");
               setPassword("");
               setIPGroups("");
               setUserGroupId("");
               setRole("customer");
               fetchUserGroup();
               setLoad(false);
               onOpen();
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Create user</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <Stack spacing="3">
                     <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input
                           placeholder="Email"
                           isDisabled={load}
                           onChange={(e) => setEmail(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Password</FormLabel>
                        <PasswordField
                           placeholder={"Password"}
                           onChange={(e) => setPassword(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>IP Groups</FormLabel>
                        <Textarea
                           placeholder="IP group example 103.91.205.0/24"
                           onChange={(e) => setIPGroups(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Vlans</FormLabel>
                        <Textarea
                           placeholder="Vlan for management 1 - 4094"
                           value={vlans}
                           onChange={(e) => setVlans(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Role</FormLabel>
                        <Select
                           placeholder="Select option"
                           value={role}
                           onChange={(e) => setRole(e.target.value)}
                        >
                           <option value="customer">Customer</option>
                           <option value="editor">Editor</option>
                           <option value="admin">Admin</option>
                           <option value="suspend">Suspend</option>
                        </Select>
                     </FormControl>
                     <FormControl>
                        <FormLabel>User Group</FormLabel>
                        <Select
                           placeholder="Select option"
                           value={userGroupId}
                           onChange={(e) => setUserGroupId(e.target.value)}
                        >
                           {userGroupList.map((userGroup) => (
                              <option value={userGroup.id}>
                                 {userGroup.name}
                              </option>
                           ))}
                        </Select>
                     </FormControl>
                  </Stack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={
                        load || !email || !password || !role || !userGroupId
                     }
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        axios
                           .put("/admin/users", {
                              email: email,
                              password: password,
                              ip_groups: getIPGroup().join("\n"),
                              vlans: getVlans().join(","),
                              role: role,
                              user_group_id: userGroupId,
                           })
                           .then(() => {
                              setLoad(false);
                              toast({
                                 title: `Commit success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                              onCreate();
                           })
                           .catch((error) => {
                              let res = error.response;
                              if (res) {
                                 toast({
                                    title: res.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              }
                           })
                           .finally(() => onClose());
                     }}
                  >
                     Create
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

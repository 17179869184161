import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogCloseButton,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   Button,
   Checkbox,
   Divider,
   Flex,
   FormControl,
   Text,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { GoStop } from "react-icons/go";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { INode } from "../../ts/interfaces/node_interface";

export const KickAllInstances = () => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const cancelRef = React.useRef<HTMLButtonElement | null>(null);
   const [load, setLoad] = useState(false);
   const toast = useToast();

   const [nodeList, setNodeList] = useState<INode[]>([]);
   const [announce_node_ids, setAnnounceNodeIds] = useState<string[]>([]);

   const fetchNode = () => {
      setLoad(true);
      axios
         .get<{
            nodes: INode[];
            meta: {
               limit: number;
               total: number;
            };
         }>(`/admin/nodes?kick-all=t`)
         .then(({ data }) => {
            setNodeList(data.nodes);
            setLoad(false);
         });
   };

   useEffect(() => {
      fetchNode();
   }, []);

   return (
      <>
         <Button
            leftIcon={<GoStop />}
            onClick={() => {
               fetchNode();
               setAnnounceNodeIds([]);
               onOpen();
               setLoad(false);
            }}
            colorScheme="red"
         >
            Kick All
         </Button>
         <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
         >
            <AlertDialogOverlay />

            <AlertDialogContent>
               <AlertDialogHeader>Kick all all announces?</AlertDialogHeader>
               <AlertDialogCloseButton />
               <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                  <Flex align="center">
                     <Divider />
                     <Text padding="2">Nodes</Text>
                     <Divider />
                  </Flex>
                  {nodeList.map((node) => (
                     <FormControl key={node.id}>
                        <Checkbox
                           name="nodes"
                           value={node.id}
                           onChange={(e) => {
                              const { value, checked } = e.target;

                              if (checked) {
                                 setAnnounceNodeIds([
                                    ...announce_node_ids,
                                    value,
                                 ]);
                              } else {
                                 setAnnounceNodeIds(
                                    announce_node_ids.filter(
                                       (item) => item !== value
                                    )
                                 );
                              }
                           }}
                           isChecked={announce_node_ids.includes(node.id)}
                        >
                           {node.name}
                        </Checkbox>
                     </FormControl>
                  ))}
               </AlertDialogBody>
               <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                     Cancel
                  </Button>
                  <Button
                     colorScheme="red"
                     ml={3}
                     isLoading={load}
                     disabled={announce_node_ids.length < 1}
                     onClick={() => {
                        setLoad(true);
                        axios
                           .post(`/admin/announces/kick-all`, {
                              node_ids: announce_node_ids,
                           })
                           .then(() => {
                              setLoad(false);
                              toast({
                                 title: `Commit success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                           })
                           .catch((error) => {
                              let res = error.response;
                              if (res) {
                                 toast({
                                    title: res.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              }
                           })
                           .finally(() => onClose());
                     }}
                  >
                     KickAll
                  </Button>
               </AlertDialogFooter>
            </AlertDialogContent>
         </AlertDialog>
      </>
   );
};

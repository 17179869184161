import {
   useDisclosure,
   Box,
   Flex,
   Spacer,
   HStack,
   IconButton,
   Collapse,
   VStack,
   Text,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IApp, ITypeField } from "../../ts/interfaces/announces_interface";

const ApplicationCard: React.FC<{
   setEdit: (edit: IApp) => void;
   setEditIndex: (index: number) => void;
   setTabIndex: (index: number) => void;
   apps: IApp[];
   app: IApp;
   idx: number;
   setApps: (apps: IApp[]) => void;
}> = ({ app, setEdit, setEditIndex, setTabIndex, apps, idx, setApps }) => {
   const { isOpen, onToggle } = useDisclosure();

   const fieldsObj = Object.assign(
      {} as ITypeField,
      ...(app.type.fields || [])?.map((state) => {
         return { [state.key]: state };
      })
   ) as Record<string, ITypeField>;

   return (
      <Box
         borderWidth="1px"
         borderRadius="lg"
         p={6}
         cursor="pointer"
         onClick={onToggle}
      >
         <Flex>
            <Box>
               <Text fontWeight="semibold">{app.type.name}</Text>
            </Box>
            <Spacer />
            <HStack>
               <IconButton
                  aria-label="Edit firewall type field"
                  onClick={() => {
                     setEdit(app);
                     setEditIndex(idx);
                     setTabIndex(2);
                  }}
                  size="xs"
                  variant="outline"
                  icon={<FaEdit />}
                  disabled={
                     !(
                        (app.fields && Object.keys(app.fields).length > 0) ||
                        app.type.need_port
                     )
                  }
               />
               <IconButton
                  aria-label="Delete firewall type field"
                  onClick={() => {
                     let a = [...apps];
                     a.splice(idx, 1);
                     setApps(a);
                  }}
                  size="xs"
                  colorScheme="danger"
                  icon={<MdDelete />}
               />
            </HStack>
         </Flex>
         <Collapse in={isOpen} animateOpacity>
            {app.fields && Object.keys(app.fields).length > 0 && (
               <VStack mt={4} color="gray.700">
                  {Object.keys(app.fields).map((key) => {
                     const value = app.fields ? app.fields[key] : "";
                     return (
                        <Flex w="100%">
                           {fieldsObj[key].label}
                           <Spacer />
                           <Text maxW="50%" align="right">
                              {value.replaceAll("|", ", ") || ""}
                           </Text>
                        </Flex>
                     );
                  })}
               </VStack>
            )}
            {app?.fields !== undefined && <>{}</>}
         </Collapse>
      </Box>
   );
};

export default ApplicationCard;

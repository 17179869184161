import { ISwitchDevice } from "../../ts/interfaces/switch_device";
import React, { FC, useMemo, useState } from "react";
import {
   Button,
   Checkbox,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Select,
   Textarea,
   useDisclosure,
   useToast,
   VStack,
} from "@chakra-ui/react";
import { useAuth } from "../../context/auth";
import { MultiValue, Select as MSelect } from "chakra-react-select";
import { useMutation } from "react-query";
import axios, { AxiosError } from "axios";
import { APIError } from "../../ts/interfaces/error";
import { TbArrowMerge } from "react-icons/tb";

interface CreatePortChannelModalProps {
   onCreate: () => void;
   swDevice?: ISwitchDevice;
}

export const CreatePortChannelModal: FC<CreatePortChannelModalProps> = ({
   onCreate,
   swDevice,
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const toast = useToast();
   const { user } = useAuth();

   const [description, setDescription] = useState("");
   const [note, setNote] = useState("");
   const [mode, setMode] = useState<"trunk" | "access">();
   const [accessVlan, setAccessVlan] = useState("");
   const [nativeVlan, setNativeVlan] = useState("");
   const [needNativeVlan, setNeedNativeVlan] = useState(false);
   const [allowVlan, setAllowVlan] =
      useState<MultiValue<{ label: string; value: string }>>();
   const [noKeepalive, setNoKeepalive] = useState(false);
   const [shutdown, setShutdown] = useState(false);

   const vlanList = useMemo(
      () => user?.vlans.split(",").filter((vlan) => vlan !== ""),
      [user]
   );

   const { mutateAsync } = useMutation<any, AxiosError<APIError>, any>(
      ["switch-create-port-channel"],
      (data) => {
         return axios
            .put("/switch-management/" + swDevice?.id, data)
            .then((res) => res.data);
      }
   );

   const isValid = useMemo(() => {
      if (!mode) return false;
      if (mode === "access" && accessVlan) {
         return true;
      }
      if (mode === "trunk" && allowVlan && allowVlan.length > 0) {
         return !(needNativeVlan && !nativeVlan);
      }
      return false;
   }, [accessVlan, allowVlan, description, mode, nativeVlan, needNativeVlan]);

   return (
      <>
         <IconButton
            colorScheme="brand"
            icon={<TbArrowMerge />}
            aria-label={"Add"}
            onClick={() => {
               onOpen();
               setDescription("");
               setMode("access");
               setAccessVlan("");
               setNativeVlan("");
               setAllowVlan([]);
               setNoKeepalive(false);
               setShutdown(true);
               setNeedNativeVlan(false);
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Create Port Channel</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <VStack spacing={4}>
                     <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Input
                           placeholder="Description"
                           value={description}
                           onChange={(e) => setDescription(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Note</FormLabel>
                        <Textarea
                           placeholder="Note"
                           value={note}
                           onChange={(e) => setNote(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <Checkbox
                           isChecked={shutdown}
                           onChange={(e) => setShutdown(e.target.checked)}
                        >
                           Shutdown
                        </Checkbox>
                     </FormControl>
                     <FormControl>
                        <FormLabel>Port Mode</FormLabel>
                        <Select
                           placeholder="Select Port Mode"
                           value={mode}
                           onChange={(e) => {
                              const input = e.target.value;
                              if (input === "access" || input === "trunk") {
                                 setMode(input);
                              }
                           }}
                        >
                           <option value="access">Access</option>
                           <option value="trunk">Trunk</option>
                        </Select>
                     </FormControl>
                     {mode === "access" && (
                        <FormControl>
                           <Select
                              placeholder="Select Access Vlan"
                              backgroundColor="white"
                              value={accessVlan}
                              onChange={(e) => setAccessVlan(e.target.value)}
                           >
                              {vlanList?.map((vlan, idx) => (
                                 <option value={vlan} key={idx}>
                                    {vlan}
                                 </option>
                              ))}
                           </Select>
                        </FormControl>
                     )}
                     {mode === "trunk" && (
                        <>
                           <FormControl>
                              <Checkbox
                                 isChecked={needNativeVlan}
                                 onChange={(e) =>
                                    setNeedNativeVlan(e.target.checked)
                                 }
                              >
                                 Native Vlan
                              </Checkbox>
                           </FormControl>
                           {needNativeVlan && (
                              <FormControl>
                                 <Select
                                    placeholder="Select Native Vlan"
                                    backgroundColor="white"
                                    value={nativeVlan}
                                    onChange={(e) =>
                                       setNativeVlan(e.target.value)
                                    }
                                 >
                                    {vlanList?.map((vlan, idx) => (
                                       <option value={vlan} key={idx}>
                                          {vlan}
                                       </option>
                                    ))}
                                 </Select>
                              </FormControl>
                           )}
                           <FormControl>
                              <FormLabel>Allow Vlan</FormLabel>
                              <MSelect
                                 isMulti
                                 placeholder="Select Allow Vlan"
                                 options={vlanList?.map((vlan) => {
                                    return {
                                       label: vlan,
                                       value: vlan,
                                    };
                                 })}
                                 value={allowVlan}
                                 onChange={(e) => setAllowVlan(e)}
                              />
                           </FormControl>
                        </>
                     )}
                     <FormControl>
                        <Checkbox
                           isChecked={noKeepalive}
                           onChange={(e) => setNoKeepalive(e.target.checked)}
                        >
                           No KeepAlive
                        </Checkbox>
                     </FormControl>
                  </VStack>
               </ModalBody>
               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load || !isValid}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        mutateAsync(
                           {
                              description: description,
                              mode: mode,
                              access_vlan: accessVlan,
                              trunk_need_native_vlan: needNativeVlan,
                              trunk_native_vlan: nativeVlan,
                              trunk_allow_vlan: allowVlan?.map(
                                 (vlan) => vlan.value
                              ),
                              no_keep_alive: noKeepalive,
                              shutdown: shutdown,
                              note: note,
                           },
                           {
                              onSuccess: (data) => {
                                 toast({
                                    title: `Commit success`,
                                    position: "top",
                                    status: "success",
                                    isClosable: true,
                                 });
                                 onClose();
                                 onCreate();
                              },
                              onError: (res) => {
                                 toast({
                                    title: res.response?.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              },
                              onSettled: () => {
                                 setLoad(false);
                              },
                           }
                        );
                     }}
                  >
                     Create
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

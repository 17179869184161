import React, { useEffect, useState } from "react";
import axios from "axios";
import {
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   Text,
   VStack,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { IDDOS } from "../ts/interfaces/ddos_interface";
import moment from "dayjs";
import qs from "qs";
import { useQuery } from "react-query";
import { useQueryState } from "../hooks/useQueryState";
import { IoIosRefresh } from "react-icons/io";

export const DDOS = () => {
   return (
      <VStack align="stretch" spacing={5}>
         <TableList />
      </VStack>
   );
};

const formatBytesSuffix = (bytes: number, pps = false) => {
   if (bytes === 0) return pps ? "pps" : "0 Bytes";

   const k = 1024;
   let sizes = [
      "Bytes",
      "Kbps",
      "Mbps",
      "Gbps",
      "Tbps",
      "Pbps",
      "Ebps",
      "Zbps",
      "Ybps",
   ];
   if (pps) {
      sizes = [
         "pps",
         "Kpps",
         "Mpps",
         "Gpps",
         "Tpps",
         "Ppps",
         "Epps",
         "Zpps",
         "Ypps",
      ];
   }

   const i = Math.floor(Math.log(bytes) / Math.log(k));

   return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const TableList = () => {
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);
   const [search, setSearch] = useQueryState("search");

   const { isLoading, data, refetch } = useQuery(
      ["firewall-ddos", perPage, currentPage, search],
      () => {
         return axios
            .get<{
               ddos: IDDOS[];
               meta: {
                  limit: number;
                  total: number;
               };
            }>(
               `/ddos?${qs.stringify({
                  limit: `${perPage}`,
                  offset: `${(currentPage - 1) * perPage}`,
                  search: search,
               })}`
            )
            .then(({ data }) => {
               return data;
            });
      }
   );

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      setPerPage(newPerPage);
      setCurrentPage(page);
   };

   const columns = [
      {
         name: "IP address",
         selector: "ip_addr",
         sortable: true,
         width: "200px",
      },
      {
         name: "Type",
         selector: "type",
         sortable: true,
      },
      {
         name: "Power",
         selector: "power",
         sortable: true,
      },
      {
         name: "Time",
         selector: "bandwidth",
         cell: (row: IDDOS) => (
            <Text fontSize="xs" whiteSpace={"nowrap"}>
               {moment(row.created_at).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
         ),
         width: "200px",
         sortable: true,
      },
   ];

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <HStack w={{ base: "100%", lg: "auto" }}>
               <Flex w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     onChange={(e) => setSearch(e.target.value)}
                     bg={"white"}
                     value={search}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                     onClick={() => refetch()}
                  />
               </Flex>
               <IconButton
                  aria-label={"search"}
                  icon={<IoIosRefresh />}
                  onClick={() => refetch()}
                  colorScheme="orange"
               />
            </HStack>
         </Flex>
         <Box boxShadow={"md"} rounded={"lg"}>
            <Box overflowX={"auto"}>
               <DataTable
                  columns={columns}
                  noHeader={true}
                  data={data?.ddos || []}
                  progressPending={isLoading}
                  pagination
                  paginationServer
                  paginationTotalRows={data?.meta?.total || 0}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
                  highlightOnHover
               />
            </Box>
         </Box>
      </VStack>
   );
};

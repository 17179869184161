import { Box } from "@chakra-ui/react";
import React from "react";
import { NavbarMenu } from "./NavbarMenu";

export const Navbar = () => {
   return (
      <Box boxShadow={"lg"} bg={"white"} zIndex={10} h={"100%"} minW="240px">
         <Box>
            <NavbarMenu />
         </Box>
      </Box>
   );
};

import React from "react";
import { Button, HStack, VStack } from "@chakra-ui/react";
import { FirewallTypeTableList } from "../component/Admin/FirewallTypeTableList";
import { UserTableList } from "../component/Admin/UserTableList";
import { UserGroupManagement } from "../component/Admin/UserGroupManagement";
import { NodeManagement } from "../component/Admin/NodeManagement";
import SwitchDevice from "../component/Admin/SwitchDevice";
import PortGroup from "../component/Admin/PortGroup";
import SwitchModel from "../component/Admin/SwitchModel";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";

const TabButton: React.FC<{ to: string; exact?: boolean }> = ({
  to,
  children,
  exact,
}) => {
  return (
    <Button
      as={NavLink}
      exact={exact}
      to={to}
      activeClassName=""
      activeStyle={{
        color: "#2b6cb0",
        borderBottomColor: "#2b6cb0",
        zIndex: 20,
      }}
      variant="unstyled"
      borderRadius={0}
      borderBottom="2px"
      fontWeight="normal"
      p={4}
      py={3}
      borderColor="gray.200"
      minWidth="auto"
      maxWidth="200px"
      maxHeight="200px"
      h="100%"
    >
      {children}
    </Button>
  );
};

export const Admin = () => {
  let { path } = useRouteMatch();
  return (
    <VStack align="stretch" spacing={5}>
      <HStack spacing={0} overflow="auto" p={1}>
        <TabButton exact={true} to={path}>
          Users
        </TabButton>
        <TabButton to={`${path}/user-groups`}>User Groups</TabButton>
        <TabButton to={`${path}/firewall-types`}>Firewall Types</TabButton>
        <TabButton to={`${path}/nodes`}>Nodes</TabButton>
        <TabButton to={`${path}/switch-devices`}>Switch Devices</TabButton>
        <TabButton to={`${path}/switch-models`}>Switch Models</TabButton>
        <TabButton to={`${path}/port-groups`}>Port Groups</TabButton>
      </HStack>

      <Switch>
        <Route exact path={`${path}`}>
          <UserTableList />
        </Route>
        <Route path={`${path}/user-groups`}>
          <UserGroupManagement />
        </Route>
        <Route path={`${path}/user-groups`}>
          <UserGroupManagement />
        </Route>
        <Route path={`${path}/firewall-types`}>
          <FirewallTypeTableList />
        </Route>
        <Route path={`${path}/nodes`}>
          <NodeManagement />
        </Route>
        <Route path={`${path}/switch-devices`}>
          <SwitchDevice />
        </Route>
        <Route path={`${path}/switch-models`}>
          <SwitchModel />
        </Route>
        <Route path={`${path}/port-groups`}>
          <PortGroup />
        </Route>
      </Switch>
    </VStack>
  );
};

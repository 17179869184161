import React, { useMemo, useState } from "react";
import {
   Badge,
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   Spacer,
   StackDivider,
   Text,
   Tooltip,
   VStack,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import {
   IPort,
   ISwitchDevice,
   SwitchLog,
} from "../../ts/interfaces/switch_device";
import DataTable from "react-data-table-component";
import { MdCable } from "react-icons/md";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import omitBy from "lodash.omitby";
import axios from "axios";
import { ViewPortModal } from "../SwitchManagement/ViewPort";
import { SettingPortModal } from "../SwitchManagement/SettingPort";
import { EditSwitchDeviceModal } from "./EditSwitchDevice";
import { DeleteSwitchDevice } from "./DeleteSwitchDevice";
import { CreateSwitchDeviceModal } from "./CreateSwitchDevice";
import moment from "dayjs";
import { RefreshSwitchDevice } from "./Refresh";
import { DeletePort } from "../SwitchManagement/DeletePort";
import { ReloadConfig } from "./ReloadConfig";
import qs from "qs";

const Index = () => {
   const [search, setSearch] = useState("");
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);

   const { isLoading, data, refetch } = useQuery(
      ["admin", "switch-management", perPage, currentPage, search],
      () => {
         return axios
            .get<{
               switch_devices: ISwitchDevice[];
               meta: {
                  limit: number;
                  total: number;
               };
            }>(
               `/admin/switch-device?${qs.stringify({
                  limit: `${perPage}`,
                  offset: `${(currentPage - 1) * perPage}`,
                  search: search,
               })}`
            )
            .then(({ data }) => {
               return data;
            });
      }
   );

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      setPerPage(newPerPage);
   };

   const columns = useMemo(
      () => [
         {
            name: "Name",
            cell: (row: ISwitchDevice) => `${row.name}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "",
            cell: (row: ISwitchDevice) => (
               <HStack
                  spacing={2}
                  divider={<StackDivider borderColor="gray.200" />}
               >
                  <RefreshSwitchDevice switchDevice={row} onRefresh={refetch} />
                  <EditSwitchDeviceModal onSave={refetch} swDevice={row} />
                  <DeleteSwitchDevice onDelete={refetch} switchDevice={row} />
               </HStack>
            ),
            right: true,
         },
      ],
      []
   );

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <Flex w={{ base: "100%", lg: "auto" }}>
               <Flex mr={2} w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     bg={"white"}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                  />
               </Flex>
               <CreateSwitchDeviceModal onCreate={refetch} />
            </Flex>
         </Flex>
         <Box>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data?.switch_devices || []}
               progressPending={isLoading}
               pagination
               paginationServer
               paginationTotalRows={0}
               onChangeRowsPerPage={handlePerRowsChange}
               onChangePage={handlePageChange}
               paginationPerPage={perPage}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
               expandableRows
               expandOnRowClicked
               expandableRowsComponent={<SwitchExpander />}
            />
         </Box>
      </VStack>
   );
};

const SwitchExpander: React.FC<{ data?: ISwitchDevice }> = ({ data }) => {
   const [select, setSelect] = useState<string[]>([]);

   const columns = useMemo(
      () => [
         {
            name: "Name",
            cell: (row: IPort) => `${row.name}`,
            width: "180px",
            sortable: false,
         },
         {
            name: "Description",
            cell: (row: IPort) => `${row.description}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "Status",
            cell: (row: IPort) => (
               <Tooltip
                  label={
                     <div>
                        <div>Protocol Status: {row.port_status}</div>
                        <div>Link Status: {row.link_state}</div>
                     </div>
                  }
               >
                  <Badge
                     colorScheme={
                        row.port_status.includes("up") ? "green" : "red"
                     }
                     cursor="pointer"
                  >
                     {row.port_status.includes("up") ? "UP" : "DOWN"}
                  </Badge>
               </Tooltip>
            ),
            width: "100px",
            sortable: false,
         },
         {
            name: "Mode",
            cell: (row: IPort) => <Badge cursor="pointer">{row.mode}</Badge>,
            width: "150px",
            sortable: false,
         },
         {
            name: "Vlan",
            cell: (row: IPort) => {
               if (row.mode === "access") {
                  return (
                     <Tooltip label="Access Vlan">
                        <Badge colorScheme="green" m={1} cursor="pointer">
                           {row.access_vlan}
                        </Badge>
                     </Tooltip>
                  );
               }
               return (
                  <Box>
                     {row.trunk_need_native_vlan && (
                        <Tooltip label="Native Vlan">
                           <Badge colorScheme="blue" m={1} cursor="pointer">
                              {row.trunk_native_vlan}
                           </Badge>
                        </Tooltip>
                     )}
                     {row.trunk_allow_vlan
                        ?.split(",")
                        .filter((vlan) => vlan !== "")
                        .map((vlan) => (
                           <Tooltip label="Allow Vlan" key={vlan}>
                              <Badge colorScheme="green" m={1} cursor="pointer">
                                 {vlan}
                              </Badge>
                           </Tooltip>
                        ))}
                  </Box>
               );
            },
            width: "200px",
            sortable: false,
         },
         {
            name: "",
            cell: (row: IPort) => (
               <HStack
                  spacing={2}
                  divider={<StackDivider borderColor="gray.200" />}
               >
                  <SettingPortModal onSave={refetch} port={row} admin />
                  <ViewPortModal data={row} />
                  <DeletePort port={row} onDelete={refetch} />
               </HStack>
            ),
            right: true,
         },
      ],
      []
   );

   const columnsLog = useMemo(
      () => [
         {
            name: "Time",
            cell: (row: SwitchLog) =>
               `${moment(row.created_at).format("DD-MM-YYYY HH:mm:ss")}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "Content",
            cell: (row: SwitchLog) => `${row.content}`,
            sortable: false,
         },
      ],
      []
   );

   const {
      isLoading,
      data: swData,
      refetch,
   } = useQuery(
      ["admin", "switch-detail", data?.id],
      () => {
         return axios
            .get<ISwitchDevice>(`/admin/switch-device/${data?.id}`)
            .then(({ data }) => {
               return data;
            });
      },
      {
         enabled: !!data,
      }
   );

   return (
      <>
         <Box
            p={6}
            border="1px"
            borderColor="gray.100"
            borderLeftColor="red.500"
            borderLeftWidth={5}
         >
            <Flex>
               <MdCable size={24} />
               <Text ml={2}>Interfaces ::</Text>
            </Flex>
            <Box p={4} mt={4} border="1px" borderColor="gray.200">
               {select.length > 0 && (
                  <Flex px={4}>
                     <Box>{select.length} item selected</Box>
                     <Spacer />
                     <Box>
                        {swData && (
                           <ReloadConfig
                              switchDevice={swData}
                              portIds={select}
                           />
                        )}
                     </Box>
                  </Flex>
               )}
               <DataTable
                  columns={columns}
                  noHeader={true}
                  data={swData?.ports || []}
                  progressPending={isLoading}
                  highlightOnHover
                  selectableRows
                  selectableRowDisabled={(row) => row.restrict}
                  onSelectedRowsChange={({ selectedRows }) => {
                     setSelect(selectedRows.map((port) => port.id));
                  }}
               />
            </Box>
            <Flex mt={4}>
               <MdCable size={24} />
               <Text ml={2}>Logs ::</Text>
            </Flex>
            <Box p={4} mt={4} border="1px" borderColor="gray.200">
               <DataTable
                  columns={columnsLog}
                  noHeader={true}
                  data={swData?.logs || []}
                  progressPending={isLoading}
                  highlightOnHover
               />
            </Box>
         </Box>
      </>
   );
};

export default Index;

import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   StackDivider,
   Tag,
   Text,
   VStack,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import { IUserGroup } from "../../ts/interfaces/user_group";
import { CreateUserGroup } from "./CreateUserGroup";
import { EditUserGroup } from "./EditUserGroup";
import { DeleteUserGroup } from "./DeleteUserGroup";

const UserGroupManagement: React.FC = () => {
   const [data, setData] = useState<IUserGroup[]>([]);
   const [loading, setLoad] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);
   const [search, setSearch] = useState("");

   const fetch = (page: number = currentPage, limit: number = perPage) => {
      setLoad(true);
      setCurrentPage(page);
      axios
         .get<{
            user_groups: IUserGroup[];
            meta: {
               limit: number;
               total: number;
            };
         }>(
            `/admin/user-groups?limit=${limit}&offset=${
               (page - 1) * perPage
            }&search=${search}`
         )
         .then(({ data }) => {
            setData(data.user_groups);
            setLoad(false);
            setTotalRows(data.meta.total);
            setPerPage(data.meta.limit);
         });
   };

   useEffect(() => {
      fetch();
   }, []);

   const columns = [
      {
         name: "Name",
         selector: "name",
         sortable: true,
         width: "20%",
      },
      {
         name: "Nodes",
         cell: (row: IUserGroup) => (
            <HStack>
               {row.announce_nodes?.map((node) => (
                  <Tag key={node.id}>
                     <Text fontSize="xs" whiteSpace={"nowrap"}>
                        {node.name}
                     </Text>
                  </Tag>
               ))}
            </HStack>
         ),
         width: "50%",
      },
      {
         name: "",
         cell: (row: IUserGroup) => (
            <HStack
               spacing={2}
               divider={<StackDivider borderColor="gray.200" />}
            >
               <EditUserGroup user_group={row} onEdit={fetch} />
               <DeleteUserGroup user_group={row} onDelete={fetch} />
            </HStack>
         ),
         right: true,
         sortable: false,
      },
   ];

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
      fetch(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      fetch(page, newPerPage);
   };

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <Flex w={{ base: "100%", lg: "auto" }}>
               <Flex mr={2} w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     onChange={(e) => setSearch(e.target.value)}
                     bg={"white"}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                     onClick={() => fetch()}
                  />
               </Flex>
               <CreateUserGroup onCreate={fetch} />
            </Flex>
         </Flex>
         <Box>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data}
               progressPending={loading}
               pagination
               paginationServer
               paginationTotalRows={totalRows}
               onChangeRowsPerPage={handlePerRowsChange}
               onChangePage={handlePageChange}
               paginationPerPage={perPage}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
            />
         </Box>
      </VStack>
   );
};

export { UserGroupManagement };

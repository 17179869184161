import React from "react";
import {
   Container,
   FormControl,
   FormLabel,
   Input,
   Textarea,
   VStack,
} from "@chakra-ui/react";
import { ChangePassword } from "../component/Setting/ChangePassword";
import { useAuth } from "../context/auth";

export const Setting = () => {
   const { user } = useAuth();
   return (
      <div>
         <Container size={"xs"} mx={0} p={0}>
            <VStack>
               <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                     value={user?.email}
                     bg={"white"}
                     color={"black"}
                     isDisabled={true}
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>Role</FormLabel>
                  <Input
                     value={user?.role}
                     bg={"white"}
                     color={"black"}
                     isDisabled={true}
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>IP Groups</FormLabel>
                  <Textarea
                     value={user?.ip_groups}
                     bg={"white"}
                     color={"black"}
                     isDisabled={true}
                     rows={
                        user?.ip_groups.replaceAll("\r\n", "\n").split("\n")
                           .length
                     }
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>Vlans</FormLabel>
                  <Textarea
                     value={user?.vlans}
                     bg={"white"}
                     color={"black"}
                     isDisabled={true}
                     rows={
                        user?.vlans.split(",").filter((vlan) => vlan !== "")
                           .length
                     }
                  />
               </FormControl>
               <ChangePassword />
            </VStack>
         </Container>
      </div>
   );
};

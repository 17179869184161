import { IPort } from "../../ts/interfaces/switch_device";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogCloseButton,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   Button,
   Checkbox,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Select,
   Textarea,
   Tooltip,
   useDisclosure,
   useToast,
   VStack,
} from "@chakra-ui/react";
import { useAuth } from "../../context/auth";
import { MultiValue, Select as MSelect } from "chakra-react-select";
import { useMutation, useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { APIError } from "../../ts/interfaces/error";
import switchDevice from "../Admin/SwitchDevice";
import { AiFillSetting } from "react-icons/ai";

interface SettingPortModalProps {
   onSave: () => void;
   port: IPort;
   admin?: boolean;
}

export const SettingPortModal: FC<SettingPortModalProps> = ({
   onSave,
   port,
   admin,
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const toast = useToast();
   const { user, isAdmin } = useAuth();

   const [description, setDescription] = useState("");
   const [mode, setMode] = useState<"trunk" | "access" | "bonding">();
   const [accessVlan, setAccessVlan] = useState("");
   const [nativeVlan, setNativeVlan] = useState("");
   const [needNativeVlan, setNeedNativeVlan] = useState(false);
   const [allowVlan, setAllowVlan] =
      useState<MultiValue<{ label: string; value: string }>>();
   const [noKeepalive, setNoKeepalive] = useState(false);
   const [portChannelId, setPortChannelId] = useState("");
   const [shutdown, setShutdown] = useState(false);
   const [portFast, setPortFast] = useState(true);
   const [restrict, setRestrict] = useState(false);
   const [noExec, setNoExec] = useState(false);
   const [note, setNote] = useState("");

   const vlanList = useMemo(
      () => user?.vlans.split(",").filter((vlan) => vlan !== ""),
      [user]
   );

   const { mutateAsync } = useMutation<any, AxiosError<APIError>, any>(
      ["switch-setting"],
      (data) => {
         return axios
            .patch("/switch-management/ports/" + port?.id, data)
            .then((res) => res.data);
      }
   );

   const isBonding = mode === "bonding";

   const isValid = useMemo(() => {
      if (mode === "bonding") {
         return !!portChannelId;
      }
      if (!mode) return false;
      if (mode === "access") {
         return !!accessVlan;
      }
      if (mode === "trunk" && allowVlan && allowVlan.length > 0) {
         return !(needNativeVlan && !nativeVlan);
      }
      return false;
   }, [
      accessVlan,
      allowVlan,
      description,
      mode,
      nativeVlan,
      needNativeVlan,
      portChannelId,
   ]);

   const canBonding = port.interface_type === "physical";

   const {
      isLoading,
      data: portList,
      refetch,
   } = useQuery(
      ["switch-port-channel-list", port?.switch_device_id],
      () => {
         return axios
            .get<IPort[]>(
               `/switch-management/${port?.switch_device_id}/ports-channel`
            )
            .then(({ data }) => {
               return data;
            });
      },
      {
         enabled: !!switchDevice && isOpen,
      }
   );

   const apply = () => {
      setLoad(true);
      mutateAsync(
         {
            description: description,
            mode: mode,
            access_vlan: accessVlan,
            trunk_need_native_vlan: needNativeVlan,
            trunk_native_vlan: nativeVlan,
            trunk_allow_vlan: allowVlan?.map((vlan) => vlan.value),
            no_keep_alive: noKeepalive,
            port_channel_id: portChannelId === "" ? null : portChannelId,
            shutdown: shutdown,
            port_fast: portFast,
            restrict: restrict,
            no_exec: noExec,
            in: admin ? "a" : "",
            note: note,
         },
         {
            onSuccess: (data) => {
               toast({
                  title: `Commit success`,
                  position: "top",
                  status: "success",
                  isClosable: true,
               });
               onSave();
            },
            onError: (res) => {
               toast({
                  title: res.response?.data.message,
                  status: "error",
                  position: "top",
                  isClosable: true,
               });
            },
            onSettled: () => {
               setLoad(false);
            },
         }
      );
   };

   return (
      <>
         <IconButton
            colorScheme="warning"
            icon={<AiFillSetting />}
            aria-label={"Add"}
            disabled={port.restrict && !admin}
            onClick={() => {
               onOpen();

               setDescription(port.description || "");
               setMode(port.mode);
               setAccessVlan(port.access_vlan || "");
               setNativeVlan(port.trunk_native_vlan || "");
               setNeedNativeVlan(port.trunk_need_native_vlan || false);
               setAllowVlan(
                  port.trunk_allow_vlan
                     ?.split(",")
                     .filter((vlan) => vlan !== "")
                     .map((vlan) => {
                        return {
                           label: vlan,
                           value: vlan,
                        };
                     })
               );
               setNoKeepalive(port.no_keep_alive);
               setPortChannelId(port.parent_port_id);
               setRestrict(port.restrict);
               setShutdown(port.shutdown);
               setPortFast(port.port_fast);
               setNoExec(port.restrict);
               setNote(port.note);
               refetch();
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Setting Port :: {port.name}</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <VStack spacing={4}>
                     <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Input
                           placeholder="Description"
                           value={description}
                           onChange={(e) => setDescription(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Note</FormLabel>
                        <Textarea
                           placeholder="Note"
                           value={note}
                           onChange={(e) => setNote(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <Checkbox
                           isChecked={shutdown}
                           onChange={(e) => setShutdown(e.target.checked)}
                        >
                           Shutdown
                        </Checkbox>
                     </FormControl>
                     <FormControl>
                        <FormLabel>Port Mode</FormLabel>
                        <Tooltip label="Access Vlan">
                           <Select
                              placeholder="Select Port Mode"
                              value={mode}
                              onChange={(e) => {
                                 const input = e.target.value;
                                 if (
                                    input === "access" ||
                                    input === "trunk" ||
                                    input === "bonding"
                                 ) {
                                    setMode(input);
                                 } else {
                                    setMode(undefined);
                                 }
                              }}
                           >
                              <option value="access">Access</option>
                              <option value="trunk">Trunk</option>
                              {canBonding && (
                                 <option value="bonding">Bonding</option>
                              )}
                           </Select>
                        </Tooltip>
                     </FormControl>
                     {mode === "access" && (
                        <FormControl>
                           <FormLabel>Access Vlan</FormLabel>
                           <Select
                              placeholder="Select Access Vlan"
                              backgroundColor="white"
                              value={accessVlan}
                              onChange={(e) => setAccessVlan(e.target.value)}
                              isDisabled={isBonding}
                           >
                              {vlanList?.map((vlan, idx) => (
                                 <option value={vlan} key={idx}>
                                    {vlan}
                                 </option>
                              ))}
                           </Select>
                        </FormControl>
                     )}
                     {mode && mode !== "bonding" && (
                        <>
                           {mode === "trunk" && (
                              <>
                                 <FormControl>
                                    <Checkbox
                                       isChecked={needNativeVlan}
                                       onChange={(e) =>
                                          setNeedNativeVlan(e.target.checked)
                                       }
                                       isDisabled={isBonding}
                                    >
                                       Native Vlan
                                    </Checkbox>
                                 </FormControl>
                                 {needNativeVlan && (
                                    <FormControl>
                                       <Select
                                          placeholder="Select Native Vlan"
                                          backgroundColor="white"
                                          value={nativeVlan}
                                          onChange={(e) =>
                                             setNativeVlan(e.target.value)
                                          }
                                          isDisabled={isBonding}
                                       >
                                          {vlanList?.map((vlan, idx) => (
                                             <option value={vlan} key={idx}>
                                                {vlan}
                                             </option>
                                          ))}
                                       </Select>
                                    </FormControl>
                                 )}
                                 <FormControl>
                                    <FormLabel>Allow Vlan</FormLabel>
                                    <MSelect
                                       isMulti
                                       placeholder="Select Allow Vlan"
                                       options={vlanList?.map((vlan) => {
                                          return {
                                             label: vlan,
                                             value: vlan,
                                          };
                                       })}
                                       value={allowVlan}
                                       onChange={(e) => setAllowVlan(e)}
                                       isDisabled={isBonding}
                                    />
                                 </FormControl>
                              </>
                           )}
                           <FormControl>
                              <Checkbox
                                 isChecked={noKeepalive}
                                 onChange={(e) =>
                                    setNoKeepalive(e.target.checked)
                                 }
                                 isDisabled={isBonding}
                              >
                                 No KeepAlive
                              </Checkbox>
                           </FormControl>
                           {isAdmin && (
                              <FormControl>
                                 <Checkbox
                                    isChecked={portFast}
                                    onChange={(e) =>
                                       setPortFast(e.target.checked)
                                    }
                                    isDisabled={isBonding}
                                 >
                                    Portfast
                                 </Checkbox>
                              </FormControl>
                           )}
                        </>
                     )}
                     {mode === "bonding" && (
                        <FormControl>
                           <FormLabel>Port Channel</FormLabel>
                           <Select
                              placeholder="Select port channel"
                              backgroundColor="white"
                              value={portChannelId}
                              onChange={(e) => setPortChannelId(e.target.value)}
                           >
                              {portList?.map((port, idx) => (
                                 <option value={port.id} key={idx}>
                                    {port.name}
                                    {port.description &&
                                       ` - ${port.description}`}
                                 </option>
                              ))}
                           </Select>
                        </FormControl>
                     )}
                     {admin && (
                        <>
                           <FormControl>
                              <Checkbox
                                 isChecked={restrict}
                                 onChange={(e) => setRestrict(e.target.checked)}
                              >
                                 Restrict
                              </Checkbox>
                           </FormControl>
                           <FormControl>
                              <Checkbox
                                 isChecked={noExec}
                                 onChange={(e) => setNoExec(e.target.checked)}
                              >
                                 No Exec
                              </Checkbox>
                           </FormControl>
                        </>
                     )}
                  </VStack>
               </ModalBody>

               <ModalFooter>
                  {isAdmin &&
                     !port.restrict &&
                     port.interface_type === "physical" && (
                        <ResetComponent
                           port={port}
                           onSave={() => {
                              refetch();
                              onClose();
                           }}
                           load={load}
                           setLoad={(load) => setLoad(load)}
                        />
                     )}
                  <ApplyComponent
                     port={port}
                     onSave={apply}
                     load={load}
                     isValid={isValid}
                     shutdown={shutdown}
                     mode={mode}
                  />
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

const ApplyComponent: React.FC<{
   port: IPort;
   onSave: () => void;
   load: boolean;
   isValid: boolean;
   shutdown: boolean;
   mode: "trunk" | "access" | "bonding" | undefined;
}> = ({ port, load, onSave, isValid, shutdown, mode }) => {
   const {
      isOpen: isDialogOpen,
      onOpen: onDialogOpen,
      onClose: onDialogClose,
   } = useDisclosure();
   const cancelRef = React.useRef<HTMLButtonElement | null>(null);

   useEffect(() => {
      // close on done
      if (isDialogOpen && !load) {
         onDialogClose();
      }
   }, [load]);

   const subtitleMsg = shutdown
      ? "Proceed with caution: This action will initiate a port shutdown."
      : port.mode === mode
      ? "Important: This action is irreversible. Confirm to proceed."
      : "Warning: Executing this action will briefly disconnect the port. Please confirm.";

   return (
      <>
         <Button
            colorScheme="brand"
            mr={3}
            isDisabled={load || !isValid}
            isLoading={load}
            onClick={onDialogOpen}
         >
            Apply
         </Button>
         <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onDialogClose}
            isOpen={isDialogOpen}
            isCentered
         >
            <AlertDialogOverlay />

            <AlertDialogContent>
               <AlertDialogHeader>Apply port {port.name}?</AlertDialogHeader>
               <AlertDialogCloseButton isDisabled={load} />
               <AlertDialogBody>{subtitleMsg}</AlertDialogBody>
               <AlertDialogFooter>
                  <Button
                     ref={cancelRef}
                     onClick={onDialogClose}
                     isDisabled={load}
                  >
                     Cancel
                  </Button>
                  <Button
                     colorScheme="warning"
                     ml={3}
                     isLoading={load}
                     onClick={onSave}
                  >
                     Apply
                  </Button>
               </AlertDialogFooter>
            </AlertDialogContent>
         </AlertDialog>
      </>
   );
};

const ResetComponent: React.FC<{
   port: IPort;
   onSave: () => void;
   load: boolean;
   setLoad: (load: boolean) => void;
}> = ({ port, onSave, load, setLoad }) => {
   const {
      isOpen: isDialogOpen,
      onOpen: onDialogOpen,
      onClose: onDialogClose,
   } = useDisclosure();
   const cancelRef = React.useRef<HTMLButtonElement | null>(null);
   const toast = useToast();

   const { mutateAsync } = useMutation<any, AxiosError<APIError>, any>(
      ["switch-reset"],
      (data) => {
         return axios
            .post("/switch-management/ports/" + port?.id + "/reset", data)
            .then((res) => res.data);
      }
   );

   const reset = () => {
      setLoad(true);
      onDialogClose();
      mutateAsync(
         {},
         {
            onSuccess: (data) => {
               toast({
                  title: `Commit success`,
                  position: "top",
                  status: "success",
                  isClosable: true,
               });
               onSave();
            },
            onError: (res) => {
               toast({
                  title: res.response?.data.message,
                  status: "error",
                  position: "top",
                  isClosable: true,
               });
            },
            onSettled: () => {
               setLoad(false);
            },
         }
      );
   };

   return (
      <>
         <Button
            colorScheme="red"
            mr={3}
            isLoading={load}
            onClick={onDialogOpen}
         >
            Reset
         </Button>
         <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onDialogClose}
            isOpen={isDialogOpen}
            isCentered
         >
            <AlertDialogOverlay />

            <AlertDialogContent>
               <AlertDialogHeader>Reset port {port.name}?</AlertDialogHeader>
               <AlertDialogCloseButton isDisabled={load} />
               <AlertDialogBody>
                  Are you sure? This action will be shutdown port, You can't
                  undo this action afterwards.
               </AlertDialogBody>
               <AlertDialogFooter>
                  <Button
                     ref={cancelRef}
                     onClick={onDialogClose}
                     isDisabled={load}
                  >
                     Cancel
                  </Button>
                  <Button
                     colorScheme="red"
                     ml={3}
                     isLoading={load}
                     onClick={reset}
                  >
                     Reset
                  </Button>
               </AlertDialogFooter>
            </AlertDialogContent>
         </AlertDialog>
      </>
   );
};

import {
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   Spacer,
   Spinner,
   StackDivider,
   Table,
   TableContainer,
   Tag,
   Tbody,
   Td,
   Text,
   Tr,
} from "@chakra-ui/react";
import { EditInstance } from "./EditInstance";
import { DeleteInstance } from "./DeleteInstance";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { IAnnounce } from "../../ts/interfaces/announces_interface";
import { AddInstance } from "./AddInstance";
import { ReloadInstances } from "./ReloadInstances";
import { KickAllInstances } from "./KickAllInstances";
import {
   AiFillCheckCircle,
   AiFillCloseCircle,
   AiOutlineSearch,
} from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { BsFillSkipEndFill } from "react-icons/bs";
import { GiSandsOfTime } from "react-icons/gi";

import { useAuth } from "../../context/auth";
import axios from "axios";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import omitBy from "lodash.omitby";
import moment from "dayjs";
import { useQueryState } from "../../hooks/useQueryState";
import { IoIosRefresh } from "react-icons/io";
import qs from "qs";

export const TableList = () => {
   const { isAdmin } = useAuth();

   const [search, setSearch] = useQueryState("search");
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);

   const { isLoading, data, refetch } = useQuery(
      ["firewall-instance", perPage, currentPage, search],
      () => {
         return axios
            .get<{
               ins: IAnnounce[];
               meta: {
                  limit: number;
                  total: number;
               };
            }>(
               `/announces/instances?${qs.stringify({
                  limit: `${perPage}`,
                  offset: `${(currentPage - 1) * perPage}`,
                  search: search,
               })}`
            )
            .then(({ data }) => {
               return data;
            });
      }
   );

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      setPerPage(newPerPage);
      setCurrentPage(page);
   };

   const columns = useMemo(
      () => [
         {
            name: "IP address",
            sortable: true,
            width: "150px",
            cell: (row: IAnnounce) => (
               <Flex>
                  {row.lock || row.mark_delete ? (
                     <Spinner width={3} height={3} mr={2} />
                  ) : (
                     <div style={{ marginRight: 3 }}>
                        {row.status === 0 && (
                           <BiTimeFive size={16} color="orange" />
                        )}
                        {row.status === 1 && (
                           <AiFillCheckCircle size={16} color="green" />
                        )}
                        {row.status === 2 && (
                           <AiFillCloseCircle size={16} color="red" />
                        )}
                        {row.status === 3 && (
                           <GiSandsOfTime size={16} color="blue" />
                        )}

                        {row.status === 4 && <BsFillSkipEndFill size={16} />}
                     </div>
                  )}
                  {row.ip_addr}
               </Flex>
            ),
         },
         {
            name: isAdmin ? "Owner" : "",
            cell: (row: IAnnounce) =>
               isAdmin && (
                  <Text
                     textOverflow="ellipsis"
                     overflow="hidden"
                     whiteSpace="nowrap"
                  >
                     {row.user?.email || "Unknown"}
                  </Text>
               ),
            width: "250px",
            sortable: false,
         },
         {
            name: "",
            cell: (row: IAnnounce) => (
               <HStack
                  spacing={2}
                  divider={<StackDivider borderColor="gray.200" />}
               >
                  <EditInstance ins={row} onEdit={refetch} />
                  <DeleteInstance ins={row} onDelete={refetch} />
               </HStack>
            ),
            right: true,
            sortable: false,
         },
      ],
      []
   );

   return (
      <>
         <Box>
            <Flex flexWrap={"wrap"}>
               <HStack w={{ base: "100%", lg: "auto" }}>
                  <Flex w={"100%"}>
                     <Input
                        placeholder={"Search"}
                        roundedRight={"none"}
                        onChange={(e) => setSearch(e.target.value)}
                        bg={"white"}
                        value={search}
                     />
                     <IconButton
                        aria-label={"search"}
                        roundedLeft={"none"}
                        icon={<AiOutlineSearch />}
                        onClick={() => refetch()}
                     />
                  </Flex>
                  <AddInstance onAdd={refetch} />
                  <IconButton
                     aria-label={"search"}
                     icon={<IoIosRefresh />}
                     onClick={() => refetch()}
                     colorScheme="orange"
                  />
               </HStack>
               <Spacer />
               <Flex mt={{ base: "4", lg: "0" }}>
                  {isAdmin && (
                     <HStack spacing={2}>
                        <ReloadInstances />
                        <KickAllInstances />
                     </HStack>
                  )}
               </Flex>
            </Flex>
         </Box>
         <Box boxShadow={"md"} rounded={"lg"}>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data?.ins || []}
               progressPending={isLoading}
               pagination
               paginationServer
               paginationTotalRows={data?.meta?.total || 0}
               onChangeRowsPerPage={handlePerRowsChange}
               onChangePage={handlePageChange}
               paginationPerPage={perPage}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
               expandableRows
               expandOnRowClicked
               expandableRowsComponent={<Expander />}
            />
         </Box>
      </>
   );
};

const Expander: React.FC<{ data?: IAnnounce }> = ({ data }) => {
   const { isAdmin } = useAuth();
   return (
      <Box
         p={6}
         border="1px"
         borderColor="gray.100"
         borderLeftColor="red.500"
         borderLeftWidth={5}
      >
         <TableContainer>
            <Table variant="simple" size="sm">
               <Tbody>
                  <Tr>
                     <Td>IP</Td>
                     <Td isNumeric>{data?.ip_addr}</Td>
                  </Tr>
                  <Tr>
                     <Td>Created At</Td>
                     <Td isNumeric>
                        {moment(data?.created_at).format("DD-MM-YYYY HH:mm:ss")}
                     </Td>
                  </Tr>
                  <Tr>
                     <Td>Applications</Td>
                     <Td isNumeric>
                        {data?.apps?.map((app) => (
                           <Tag
                              key={app.id}
                              bg={app.type.color}
                              color={"white"}
                              m={1}
                           >
                              <Text fontSize="xs" whiteSpace={"nowrap"}>
                                 {app.type.name}
                              </Text>
                           </Tag>
                        ))}
                     </Td>
                  </Tr>
                  {isAdmin && (
                     <Tr>
                        <Td>Nodes</Td>
                        <Td isNumeric>
                           {data?.announce_instance_nodes?.map((node) => (
                              <Tag key={node.id} m={1}>
                                 <Text fontSize="xs" whiteSpace={"nowrap"}>
                                    {node.name}
                                 </Text>
                              </Tag>
                           ))}
                        </Td>
                     </Tr>
                  )}
               </Tbody>
            </Table>
         </TableContainer>
      </Box>
   );
};

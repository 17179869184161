import {
   Button,
   chakra,
   FormControl,
   HTMLChakraProps,
   Input,
   InputGroup,
   InputLeftElement,
   Stack,
   useToast,
} from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { PasswordField } from "./PasswordField";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { IUser } from "../../ts/interfaces/user_interface";
import { useHistory } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";

export const LoginForm = (props: HTMLChakraProps<"form">) => {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const { setLoad, loading, setUser } = useAuth();
   const history = useHistory();
   const toast = useToast();

   return (
      <chakra.form
         onSubmit={(e) => {
            e.preventDefault();
            setLoad(true);
            axios
               .post<{
                  access_token: string;
               }>("/auth/login", {
                  email: email,
                  password: password,
               })
               .then(({ data }) => {
                  localStorage.setItem("jwt", data.access_token);
                  axios
                     .get<IUser>("/user/profile")
                     .then(({ data }) => {
                        setUser(data);
                        history.push("/home");
                     })
                     .finally(() => {
                        setTimeout(() => setLoad(false), 1000);
                     });
                  toast({
                     title: `Login success 🚀`,
                     position: "top",
                     status: "success",
                     isClosable: true,
                  });
               })
               .catch((error) => {
                  setLoad(false);
                  let res = error.response;
                  if (res) {
                     toast({
                        title: res.data.message,
                        status: "error",
                        position: "top",
                        isClosable: true,
                     });
                  }
               });
         }}
         {...props}
      >
         <Stack spacing="6">
            <FormControl id="email">
               <InputGroup>
                  <InputLeftElement
                     pointerEvents="none"
                     children={<AiOutlineMail color="gray.300" />}
                  />
                  <Input
                     placeholder={"Enter your email"}
                     name="email"
                     type="email"
                     autoComplete="email"
                     required
                     onChange={(e) => setEmail(e.target.value)}
                  />
               </InputGroup>
            </FormControl>
            <FormControl id="password">
               <PasswordField
                  leftIcon={<RiLockPasswordFill color={"gray.300"} />}
                  placeholder={"Enter your password"}
                  onChange={(e) => setPassword(e.target.value)}
               />
            </FormControl>
            <Button
               type="submit"
               size="lg"
               fontSize="md"
               colorScheme="brand"
               isDisabled={loading || !email || !password}
               isLoading={loading}
            >
               Sign in
            </Button>
         </Stack>
      </chakra.form>
   );
};

import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Card } from "../component/Login/Card";
import { LoginForm } from "../component/Login/LoginForm";
import brand from "../assets/brand.png";

export const Login = () => {
   return (
      <Box minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
         <Box maxW="sm" mx="auto">
            <Flex p={4}>
               <Image
                  maxW={"300px"}
                  mx={"auto"}
                  src={brand}
                  alt="brand"
                  objectFit={"cover"}
               />
            </Flex>
            <Card>
               <Text
                  textAlign="center"
                  fontWeight={"medium"}
                  color={"gray.800"}
               >
                  <Text as="span">Welcome Back</Text>
               </Text>
               <Text
                  textAlign="center"
                  mb={8}
                  fontWeight={"light"}
                  color={"gray.400"}
               >
                  <Text as="span">
                     Enter your credential to access your account.
                  </Text>
               </Text>
               <LoginForm />
            </Card>
         </Box>
      </Box>
   );
};

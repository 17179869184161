import React, { FC, useEffect, useState } from "react";
import {
   Box,
   Button,
   Checkbox,
   Collapse,
   Flex,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   SimpleGrid,
   Stack,
   useDisclosure,
   useToast,
   VStack,
} from "@chakra-ui/react";
import { MultiValue, Select as MSelect } from "chakra-react-select";
import { useMutation, useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { IUser } from "../../ts/interfaces/user_interface";
import { IPort, ISwitchDevice } from "../../ts/interfaces/switch_device";
import { APIError } from "../../ts/interfaces/error";
import { TbArrowMerge } from "react-icons/tb";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
// @ts-ignore
import { memoPlus } from "@karuga/react-memo-plus";

interface CreatePortGroupModalProps {
   onCreate: () => void;
}

export const CreatePortGroupModal: FC<CreatePortGroupModalProps> = ({
   onCreate,
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const [name, setName] = useState("");
   const [portIds, setPortIds] = useState<string[][]>([]);

   const [userIds, setUserIds] =
      useState<
         MultiValue<{
            label: string;
            value: string;
         }>
      >();
   const toast = useToast();

   const {
      isLoading,
      data: userData,
      refetch,
   } = useQuery(
      ["admin", "port-group", "users"],
      () => {
         return axios
            .get<{
               users: IUser[];
            }>(`/admin/users?limit=-1`)
            .then(({ data }) => {
               return data;
            });
      },
      {
         refetchInterval: false,
      }
   );

   const {
      isLoading: swIsLoading,
      data: swData,
      refetch: swRefetch,
   } = useQuery(
      ["admin", "port-group", "switch"],
      () => {
         return axios
            .get<{
               switch_devices: ISwitchDevice[];
            }>(`/admin/port-group/switch`)
            .then(({ data }) => {
               return data;
            });
      },
      {
         refetchInterval: false,
      }
   );

   const { mutateAsync } = useMutation<any, AxiosError<APIError>, any>(
      ["admin", "create-port-group"],
      (data) => {
         return axios.put("/admin/port-group", data).then((res) => res.data);
      }
   );

   return (
      <>
         <IconButton
            colorScheme="brand"
            icon={<TbArrowMerge />}
            aria-label={"Add"}
            onClick={() => {
               onOpen();
               refetch();
               swRefetch();
               setName("");
               setUserIds([]);
            }}
         />
         <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            size="3xl"
         >
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Create Port Group</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <VStack spacing={4}>
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           placeholder="Name"
                           value={name}
                           onChange={(e) => {
                              setName(e.target.value);
                           }}
                        />
                     </FormControl>

                     <FormControl>
                        <FormLabel>User</FormLabel>
                        <MSelect
                           isMulti
                           hasStickyGroupHeaders
                           chakraStyles={{
                              dropdownIndicator: (provided) => ({
                                 ...provided,
                                 bg: "transparent",
                                 px: 2,
                                 cursor: "inherit",
                              }),
                              indicatorSeparator: (provided) => ({
                                 ...provided,
                                 display: "none",
                              }),
                           }}
                           closeMenuOnSelect={false}
                           placeholder="Select user, who can access"
                           options={userData?.users.map((user) => {
                              return {
                                 label: user.email,
                                 value: user.id,
                              };
                           })}
                           value={userIds}
                           onChange={(sel) => {
                              setUserIds(sel);
                           }}
                        />
                     </FormControl>

                     <FormControl>
                        <FormLabel>Port List</FormLabel>
                        <Box maxH="40vh" overflow="auto">
                           {swData?.switch_devices.map((sw, idx) => (
                              <MemoDeviceCheckbox
                                 key={sw.id}
                                 switchDevice={sw}
                                 setSelectIds={(ids: string[]) => {
                                    let newIds = [...portIds];
                                    newIds[idx] = ids;
                                    setPortIds(newIds);
                                 }}
                              />
                           ))}
                        </Box>
                     </FormControl>
                  </VStack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        let newPortIds: string[] = [];
                        portIds.forEach((ids) => {
                           if (ids) {
                              newPortIds.push(...ids);
                           }
                        });
                        mutateAsync(
                           {
                              name: name,
                              port_ids: newPortIds,
                              user_ids: userIds?.map((user) => user.value),
                           },
                           {
                              onSuccess: (data) => {
                                 toast({
                                    title: `Commit success`,
                                    position: "top",
                                    status: "success",
                                    isClosable: true,
                                 });
                                 onClose();
                                 onCreate();
                              },
                              onError: (res) => {
                                 toast({
                                    title: res.response?.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              },
                              onSettled: () => {
                                 setLoad(false);
                              },
                           }
                        );
                     }}
                  >
                     Create
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

const PortCheckBox: React.FC<{
   port: IPort;
   checkedItems: string[];
   setCheckedItems: (data: string[]) => void;
}> = ({ port, checkedItems, setCheckedItems }) => {
   console.log("ReRender");
   return (
      <Box key={port.id}>
         <Checkbox
            isChecked={checkedItems.includes(port.id)}
            onChange={() => {
               const index = checkedItems.indexOf(port.id);
               if (index === -1) {
                  setCheckedItems([...checkedItems, port.id]);
               } else {
                  const newSelectId = [...checkedItems];
                  newSelectId.splice(index, 1);
                  setCheckedItems(newSelectId);
               }
            }}
         >
            {port.name}
         </Checkbox>
      </Box>
   );
};

const MemoPortCheckBox = memoPlus(PortCheckBox);

const DeviceCheckbox: React.FC<{
   switchDevice: ISwitchDevice;
   setSelectIds: (ids: string[]) => void;
}> = ({ switchDevice, setSelectIds }) => {
   const { isOpen, onToggle } = useDisclosure();
   const [checkedItems, setCheckedItems] = React.useState<string[]>([]);

   const isIndeterminate =
      checkedItems.length > 0 &&
      checkedItems.length !== switchDevice.ports.length;
   const allChecked =
      checkedItems.length > 0 &&
      checkedItems.length === switchDevice.ports.length;

   useEffect(() => {
      setSelectIds([...checkedItems]);
   }, [checkedItems, setSelectIds]);

   return (
      <Box p={1}>
         <Box onClick={onToggle} cursor="pointer">
            <Flex>
               <Box p={1} pl={0}>
                  {isOpen ? <FaAngleDown /> : <FaAngleRight />}
               </Box>
               <Checkbox
                  p={1}
                  mr={1}
                  onChange={(e) => {
                     if (e.target.checked) {
                        setCheckedItems(
                           switchDevice.ports.map((port) => port.id)
                        );
                     } else {
                        setCheckedItems([]);
                     }
                  }}
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
               ></Checkbox>
               {switchDevice.name}
            </Flex>
         </Box>

         <Collapse in={isOpen} unmountOnExit style={{ overflow: "visible" }}>
            <Stack pl={6} mt={1} spacing={1}>
               <SimpleGrid columns={2} spacing={3}>
                  {switchDevice?.ports?.map((port) => (
                     <MemoPortCheckBox
                        port={port}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                     />
                  ))}
               </SimpleGrid>
            </Stack>
         </Collapse>
      </Box>
   );
};

const MemoDeviceCheckbox = memoPlus(DeviceCheckbox);

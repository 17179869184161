import React, { useEffect, useState } from "react";
import { IType } from "../../ts/interfaces/announces_interface";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
   Box,
   Circle,
   Flex,
   HStack,
   IconButton,
   Input,
   StackDivider,
   VStack,
} from "@chakra-ui/react";
import { EditFirewallType } from "./EditFirewallType";
import { CreateFirewallType } from "./CreateFirewallType";
import { AiOutlineSearch } from "react-icons/ai";
import { DeleteFirewallType } from "./DeleteFirewallType";

export const FirewallTypeTableList = () => {
   const [data, setData] = useState<IType[]>([]);
   const [loading, setLoad] = useState(false);
   const [search, setSearch] = useState("");

   const fetch = () => {
      setLoad(true);
      axios
         .get<{
            types: IType[];
         }>(`/admin/announces/types?search=${search}`)
         .then(({ data }) => {
            setData(data.types);
            setLoad(false);
         });
   };

   useEffect(() => {
      fetch();
   }, []);

   const columns = [
      {
         name: "Name",
         selector: "name",
         sortable: true,
         width: "400px",
      },
      {
         name: "",
         cell: (row: IType) => <Circle size="20px" bg={row.color} />,
         sortable: false,
      },
      {
         name: "",
         cell: (row: IType) => (
            <HStack
               spacing={2}
               divider={<StackDivider borderColor="gray.200" />}
            >
               <EditFirewallType type={row} onEdit={fetch} />
               <DeleteFirewallType type={row} onDelete={fetch} />
            </HStack>
         ),
         width: "200px",
         right: true,
         sortable: false,
      },
   ];

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <Flex w={{ base: "100%", lg: "auto" }}>
               <Flex mr={2} w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     onChange={(e) => setSearch(e.target.value)}
                     bg={"white"}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                     onClick={() => fetch()}
                  />
               </Flex>
               <CreateFirewallType onCreate={fetch} />
            </Flex>
         </Flex>
         <Box>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data}
               progressPending={loading}
               pagination={false}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
            />
         </Box>
      </VStack>
   );
};

import React, { createContext, FC, useContext, useState } from "react";
import { IUser } from "../../ts/interfaces/user_interface";

interface IContextProps {
   user: null | IUser;
   loading: boolean;
   setLoad: React.Dispatch<React.SetStateAction<boolean>>;
   setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
   isAdmin: boolean;
}

const authContext = createContext({} as IContextProps);

const useProvideAuth = () => {
   const [user, setUser] = useState<null | IUser>(null);
   const [loading, setLoad] = useState(false);

   return {
      user,
      loading,
      setLoad,
      setUser,
      isAdmin: user?.role === "admin" || user?.role === "webmaster",
   };
};

export const useAuth = () => {
   return useContext(authContext);
};

export const ProvideAuth: FC = ({ children }) => {
   const auth = useProvideAuth();

   return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

import React, { useState } from "react";
import { IFirewallGroup, IRule } from "../../ts/interfaces/firewall-group";
import {
   Button,
   Checkbox,
   Divider,
   Flex,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Select,
   Stack,
   Tab,
   TabList,
   TabPanel,
   TabPanels,
   Tabs,
   Text,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { useAuth } from "../../context/auth";
import { INode } from "../../ts/interfaces/node_interface";
import { IUser } from "../../ts/interfaces/user_interface";
import axios from "axios";
import { AiFillFire } from "react-icons/ai";
import { RuleManagement } from "./RuleManagement";

const VerifyIP = (ip: string) => {
   const ipInvalid =
      /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)($|\/(0|16|24|32))?$/gi;
   return ipInvalid.test(ip);
};

export const FirewallGroupModalManagement: React.FC<{
   firewall_group?: IFirewallGroup;
   fetch: () => void;
}> = ({ firewall_group, fetch }) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const { isAdmin, user } = useAuth();
   const toast = useToast();

   const [nodeList, setNodeList] = useState<INode[]>([]);
   const [users, setUsers] = useState<IUser[]>([]);

   const [inBoundRules, setInBoundRules] = useState<IRule[]>([]);
   const [outBoundRules, setOutBoundRules] = useState<IRule[]>([]);
   const [owner, setOwner] = useState<IUser | null>(user);
   const [nodes_ids, setNodes] = useState<string[]>([]);
   const [ip, setIP] = useState("");

   const [load, setLoad] = useState(false);

   const fetchNode = () => {
      if (!isAdmin) return;
      setLoad(true);
      axios
         .get<{
            nodes: INode[];
            meta: {
               limit: number;
               total: number;
            };
         }>(`/admin/nodes`)
         .then(({ data }) => {
            setNodeList(data.nodes);
            setLoad(false);
         });
   };

   const moveItemUp = (rules: IRule[], index: number) => {
      let arr = [...rules];
      if (index > 0 && index < arr.length) {
         [arr[index], arr[index - 1]] = [arr[index - 1], arr[index]];
      }
      return arr;
   };

   const moveItemDown = (rules: IRule[], index: number) => {
      let arr = [...rules];
      if (index >= 0 && index < arr.length - 1) {
         [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
      }
      return arr;
   };

   return (
      <>
         <IconButton
            icon={<AiFillFire />}
            colorScheme="brand"
            onClick={() => {
               setLoad(true);
               fetchNode();
               Promise.all([
                  isAdmin
                     ? axios.get<{
                          users: IUser[];
                       }>("/admin/users?limit=-1")
                     : Promise.resolve(null),
               ])
                  .then((values) => {
                     values[0] && setUsers(values[0]?.data.users);
                  })
                  .finally(() => setLoad(false));
               setOwner(firewall_group?.user || user);
               setNodes(firewall_group?.nodes?.map((n) => n.id) || []);
               setInBoundRules([...(firewall_group?.in_bound.rules || [])]);
               setOutBoundRules([...(firewall_group?.out_bound.rules || [])]);
               setIP(firewall_group?.ip_address || "");
               onOpen();
            }}
            aria-label="add"
            disabled={firewall_group?.lock || firewall_group?.mark_delete}
         />
         <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Firewall Rule</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <Stack spacing="3">
                     <FormControl>
                        <FormLabel>IP Address</FormLabel>
                        <Input
                           mb={2}
                           placeholder="IP Address"
                           value={ip}
                           onChange={(e) => setIP(e.target.value)}
                           disabled={firewall_group !== undefined}
                        />
                     </FormControl>
                     {isAdmin && (
                        <>
                           <FormControl>
                              <FormLabel>Provided to user</FormLabel>
                              <Select
                                 placeholder="Select user to provided"
                                 value={owner?.id}
                                 isDisabled={load}
                                 onChange={(e) =>
                                    setOwner(
                                       users?.filter(
                                          (t) => t.id === e.target.value
                                       )[0]
                                    )
                                 }
                              >
                                 {users?.map((t) => (
                                    <option key={t.id} value={t.id}>
                                       {t.email}
                                    </option>
                                 ))}
                              </Select>
                           </FormControl>
                           <Flex align="center">
                              <Divider />
                              <Text padding="2">Nodes</Text>
                              <Divider />
                           </Flex>
                           {nodeList.map((node) => (
                              <FormControl key={node.id}>
                                 <Checkbox
                                    name="nodes"
                                    value={node.id}
                                    onChange={(e) => {
                                       const { value, checked } = e.target;

                                       if (checked) {
                                          setNodes([...nodes_ids, value]);
                                       } else {
                                          setNodes(
                                             nodes_ids.filter(
                                                (item) => item !== value
                                             )
                                          );
                                       }
                                    }}
                                    isChecked={nodes_ids.includes(node.id)}
                                 >
                                    {node.name}{" "}
                                    {(!node.active || !node.enable) && (
                                       <span style={{ color: "red" }}>
                                          (Deactive)
                                       </span>
                                    )}
                                 </Checkbox>
                              </FormControl>
                           ))}
                        </>
                     )}
                     <Flex align="center">
                        <Divider />
                        <Text padding="4">Rules</Text>
                        <Divider />
                     </Flex>
                     <Tabs isFitted variant="enclosed">
                        <TabList>
                           <Tab>In Bound</Tab>
                           <Tab>Out Bound</Tab>
                        </TabList>
                        <TabPanels>
                           <TabPanel>
                              <RuleManagement
                                 rules={inBoundRules}
                                 add={(rule) => {
                                    setInBoundRules([...inBoundRules, rule]);
                                 }}
                                 del={(index) => {
                                    let arr = inBoundRules;
                                    arr.splice(index, 1);
                                    setInBoundRules([...arr]);
                                 }}
                                 up={(index) => {
                                    console.log(
                                       "Got",
                                       moveItemUp(inBoundRules, index)
                                    );
                                    setInBoundRules(
                                       moveItemUp(inBoundRules, index)
                                    );
                                 }}
                                 down={(index) => {
                                    console.log(
                                       "Got",
                                       moveItemUp(inBoundRules, index)
                                    );
                                    setInBoundRules(
                                       moveItemDown(inBoundRules, index)
                                    );
                                 }}
                              />
                           </TabPanel>
                           <TabPanel>
                              <RuleManagement
                                 rules={outBoundRules}
                                 add={(rule) => {
                                    setOutBoundRules([...outBoundRules, rule]);
                                 }}
                                 del={(index) => {
                                    let arr = outBoundRules;
                                    arr.splice(index, 1);
                                    setOutBoundRules([...arr]);
                                 }}
                                 up={(index) => {
                                    console.log(
                                       "Got",
                                       moveItemUp(outBoundRules, index)
                                    );
                                    setOutBoundRules(
                                       moveItemUp(outBoundRules, index)
                                    );
                                 }}
                                 down={(index) => {
                                    console.log(
                                       "Got",
                                       moveItemUp(outBoundRules, index)
                                    );
                                    setOutBoundRules(
                                       moveItemDown(outBoundRules, index)
                                    );
                                 }}
                              />
                           </TabPanel>
                        </TabPanels>
                     </Tabs>
                  </Stack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={
                        load ||
                        !ip ||
                        inBoundRules.length + outBoundRules.length < 1 ||
                        (isAdmin &&
                           (nodes_ids.length < 1 || owner === undefined)) ||
                        !VerifyIP(ip)
                     }
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        let a = firewall_group
                           ? axios.patch(
                                "/firewall-group/" + firewall_group.id,
                                {
                                   user_id: owner?.id,
                                   node_ids: nodes_ids,
                                   in_bound: inBoundRules,
                                   out_bound: outBoundRules,
                                }
                             )
                           : axios.put("/firewall-group", {
                                ip_address: ip,
                                user_id: owner?.id,
                                node_ids: nodes_ids,
                                in_bound: inBoundRules,
                                out_bound: outBoundRules,
                             });
                        a.then(() => {
                           setLoad(false);
                           onClose();
                           fetch();
                           toast({
                              title: `Commit success`,
                              position: "top",
                              status: "success",
                              isClosable: true,
                           });
                        }).catch((error) => {
                           let res = error.response;
                           if (res) {
                              toast({
                                 title: res.data.message,
                                 status: "error",
                                 position: "top",
                                 isClosable: true,
                              });
                           }
                        });
                     }}
                  >
                     {firewall_group ? "Save" : "Create"}
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

import React, { useEffect, useState } from "react";
import { IUser } from "../../ts/interfaces/user_interface";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   StackDivider,
   VStack,
} from "@chakra-ui/react";
import { EditUser } from "./EditUser";
import { DeleteUser } from "./DeleteUser";
import { CreateUser } from "./CreateUser";
import { AiOutlineSearch } from "react-icons/ai";
import { ChangePassword } from "./ChangePassword";

export const UserTableList = () => {
   const [data, setData] = useState<IUser[]>([]);
   const [loading, setLoad] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);
   const [search, setSearch] = useState("");

   const fetch = (page: number = currentPage, limit: number = perPage) => {
      setLoad(true);
      setCurrentPage(page);
      axios
         .get<{
            users: IUser[];
            meta: {
               limit: number;
               total: number;
            };
         }>(
            `/admin/users?limit=${limit}&offset=${
               (page - 1) * perPage
            }&search=${search}`
         )
         .then(({ data }) => {
            setData(data.users);
            setLoad(false);
            setTotalRows(data.meta.total);
            setPerPage(data.meta.limit);
         });
   };

   useEffect(() => {
      fetch();
   }, []);

   const columns = [
      {
         name: "Email",
         selector: "email",
         sortable: true,
         width: "50%",
      },
      {
         name: "Role",
         selector: "role",
         sortable: true,
         width: "20%",
      },
      {
         name: "User Group",
         selector: "user_group.name",
         sortable: true,
         width: "20%",
      },
      {
         name: "",
         cell: (row: IUser) => (
            <HStack
               spacing={2}
               divider={<StackDivider borderColor="gray.200" />}
            >
               <EditUser user={row} onEdit={fetch} />
               <ChangePassword user={row} />
               <DeleteUser user={row} onDelete={fetch} />
            </HStack>
         ),
         right: true,
         sortable: false,
      },
   ];

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
      fetch(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      fetch(page, newPerPage);
   };

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <Flex w={{ base: "100%", lg: "auto" }}>
               <Flex mr={2} w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     onChange={(e) => setSearch(e.target.value)}
                     bg={"white"}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                     onClick={() => fetch()}
                  />
               </Flex>
               <CreateUser onCreate={fetch} />
            </Flex>
         </Flex>
         <Box>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data}
               progressPending={loading}
               pagination
               paginationServer
               paginationTotalRows={totalRows}
               onChangeRowsPerPage={handlePerRowsChange}
               onChangePage={handlePageChange}
               paginationPerPage={perPage}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
            />
         </Box>
      </VStack>
   );
};

import {
   FormControl,
   FormLabel,
   IconButton,
   Input,
   InputGroup,
   InputLeftElement,
   InputProps,
   InputRightElement,
   useDisclosure,
   useMergeRefs,
} from "@chakra-ui/react";
import * as React from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { ReactNode } from "react";

interface PasswordFieldProps extends InputProps {
   leftIcon?: ReactNode;
}

export const PasswordField = React.forwardRef<
   HTMLInputElement,
   PasswordFieldProps
>((props, ref) => {
   const { isOpen, onToggle } = useDisclosure();
   const inputRef = React.useRef<HTMLInputElement>(null);

   const mergeRef = useMergeRefs(inputRef, ref);

   const onClickReveal = () => {
      onToggle();
      const input = inputRef.current;
      if (input) {
         input.focus({ preventScroll: true });
         const length = input.value.length * 2;
         requestAnimationFrame(() => {
            input.setSelectionRange(length, length);
         });
      }
   };

   return (
      <InputGroup>
         {props.leftIcon && (
            <InputLeftElement pointerEvents="none" children={props.leftIcon} />
         )}
         <InputRightElement>
            <IconButton
               bg="transparent !important"
               variant="ghost"
               aria-label={isOpen ? "Mask password" : "Reveal password"}
               icon={isOpen ? <HiEye /> : <HiEyeOff />}
               onClick={onClickReveal}
            />
         </InputRightElement>
         <Input
            ref={mergeRef}
            name="password"
            type={isOpen ? "text" : "password"}
            autoComplete="current-password"
            required
            {...props}
         />
      </InputGroup>
   );
});

PasswordField.displayName = "PasswordField";

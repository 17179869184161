import React, { FC, useState } from "react";
import {
   Button,
   Checkbox,
   Divider,
   Flex,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Stack,
   Text,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { AiFillEdit } from "react-icons/ai";
import axios from "axios";
import { INode } from "../../ts/interfaces/node_interface";
import { IUserGroup } from "../../ts/interfaces/user_group";
import { useQuery } from "react-query";
import { IType } from "../../ts/interfaces/announces_interface";

interface Props {
   user_group: IUserGroup;
   onEdit: () => void;
}

export const EditUserGroup: FC<Props> = ({ onEdit, user_group }) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const toast = useToast();
   const [name, setName] = useState("");
   const [apply, setApply] = useState(true);
   const [nodeList, setNodeList] = useState<INode[]>([]);
   const [announce_node_ids, setAnnounceNodeIds] = useState<string[]>([]);
   const [app_type_ids, setAppTypeIds] = useState<string[]>([]);

   const {
      isLoading,
      data: typeData,
      refetch,
   } = useQuery(["admin", "firewall-types"], () => {
      return axios
         .get<{
            types: IType[];
         }>(`/admin/announces/types`)
         .then(({ data }) => {
            return data;
         });
   });

   const fetchNode = () => {
      setLoad(true);
      axios
         .get<{
            nodes: INode[];
            meta: {
               limit: number;
               total: number;
            };
         }>(`/admin/nodes`)
         .then(({ data }) => {
            setNodeList(data.nodes);
            setLoad(false);
         });
   };

   return (
      <>
         <IconButton
            aria-label={"icon"}
            icon={<AiFillEdit />}
            colorScheme="warning"
            onClick={() => {
               setName(user_group.name);
               setAnnounceNodeIds(
                  user_group?.announce_nodes?.map((n) => n.id) || []
               );
               setAppTypeIds(user_group?.apps?.map((n) => n.id) || []);
               setApply(false);
               setLoad(false);
               fetchNode();
               onOpen();
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Create user group</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <Stack spacing="3">
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           value={name}
                           placeholder="Name"
                           isDisabled={load}
                           onChange={(e) => setName(e.target.value)}
                        />
                     </FormControl>
                     <Flex align="center">
                        <Divider />
                        <Text padding="2">Nodes</Text>
                        <Divider />
                     </Flex>
                     {nodeList?.map((node) => (
                        <FormControl key={node.id}>
                           <Checkbox
                              name="nodes"
                              value={node.id}
                              onChange={(e) => {
                                 const { value, checked } = e.target;

                                 if (checked) {
                                    setAnnounceNodeIds([
                                       ...announce_node_ids,
                                       value,
                                    ]);
                                 } else {
                                    setAnnounceNodeIds(
                                       announce_node_ids.filter(
                                          (item) => item !== value
                                       )
                                    );
                                 }
                              }}
                              isChecked={announce_node_ids?.includes(node.id)}
                           >
                              {node.name}
                           </Checkbox>
                        </FormControl>
                     ))}
                     <Flex align="center">
                        <Divider />
                        <Text padding="2">Firewall Types</Text>
                        <Divider />
                     </Flex>
                     {typeData?.types?.map((app) => (
                        <FormControl key={app.id}>
                           <Checkbox
                              name="app-types"
                              value={app.id}
                              onChange={(e) => {
                                 const { value, checked } = e.target;

                                 if (checked) {
                                    setAppTypeIds([...app_type_ids, value]);
                                 } else {
                                    setAppTypeIds(
                                       app_type_ids.filter(
                                          (item) => item !== value
                                       )
                                    );
                                 }
                              }}
                              isChecked={app_type_ids?.includes(app.id)}
                           >
                              {app.name}
                           </Checkbox>
                        </FormControl>
                     ))}
                     <Flex align="center">
                        <Divider />
                        <Text padding="2">Apply</Text>
                        <Divider />
                     </Flex>
                     <FormControl>
                        <Checkbox
                           onChange={(e) => {
                              setApply(e.target.checked);
                           }}
                           isChecked={apply}
                        >
                           Apply and Reload
                        </Checkbox>
                     </FormControl>
                  </Stack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load || !name}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        axios
                           .patch("/admin/user-groups/" + user_group.id, {
                              name: name,
                              announce_node_ids: announce_node_ids,
                              apply: apply,
                              app_type_ids: app_type_ids,
                           })
                           .then(() => {
                              setLoad(false);
                              toast({
                                 title: `Commit success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                              onEdit();
                           })
                           .catch((error) => {
                              let res = error.response;
                              if (res) {
                                 toast({
                                    title: res.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              }
                           })
                           .finally(() => onClose());
                     }}
                  >
                     Save
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

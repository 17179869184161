import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import omitBy from "lodash.omitby";
import axios from "axios";
import { ISwitchModel } from "../../ts/interfaces/switch_device";
import {
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   StackDivider,
   VStack,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { CreateSwitchModelModal } from "./CreateSwitchModel";
import { EditSwitchModelModal } from "./EditSwitchModel";
import { DeleteSwitchModel } from "./DeleteSwitchModel";
import qs from "qs";

const Index = () => {
   const [search, setSearch] = useState("");
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);

   const { isLoading, data, refetch } = useQuery(
      ["admin", "switch-management", perPage, currentPage, search],
      () => {
         return axios
            .get<{
               switch_models: ISwitchModel[];
               meta: {
                  limit: number;
                  total: number;
               };
            }>(
               `/admin/switch-model?${qs.stringify({
                  limit: `${perPage}`,
                  offset: `${(currentPage - 1) * perPage}`,
                  search: search,
               })}`
            )
            .then(({ data }) => {
               return data;
            });
      }
   );

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      setPerPage(newPerPage);
   };

   const columns = useMemo(
      () => [
         {
            name: "Name",
            cell: (row: ISwitchModel) => `${row.name}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "",
            cell: (row: ISwitchModel) => (
               <HStack
                  spacing={2}
                  divider={<StackDivider borderColor="gray.200" />}
               >
                  <EditSwitchModelModal onSave={refetch} switchModel={row} />
                  <DeleteSwitchModel onDelete={refetch} switchModel={row} />
               </HStack>
            ),
            right: true,
         },
      ],
      []
   );

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <Flex w={{ base: "100%", lg: "auto" }}>
               <Flex mr={2} w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     bg={"white"}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                  />
               </Flex>
               <CreateSwitchModelModal onCreate={refetch} />
            </Flex>
         </Flex>
         <Box>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data?.switch_models || []}
               progressPending={isLoading}
               pagination
               paginationServer
               paginationTotalRows={0}
               onChangeRowsPerPage={handlePerRowsChange}
               onChangePage={handlePageChange}
               paginationPerPage={perPage}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
            />
         </Box>
      </VStack>
   );
};

export default Index;

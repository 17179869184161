import React, { useState } from "react";
import { ITypeField } from "../../ts/interfaces/announces_interface";
import {
   Box,
   Button,
   Checkbox,
   Collapse,
   Flex,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Spacer,
   Stack,
   Text,
   useDisclosure,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";

export const FieldEdit: React.FC<{
   field: ITypeField;
   onDelete: () => void;
   onUpdate: (field: ITypeField) => void;
}> = ({ field, onDelete, onUpdate }) => {
   const {
      isOpen: isOpenField,
      onOpen: onOpenField,
      onClose: onCloseField,
   } = useDisclosure();

   const [form, setForm] = useState(field);

   const onOpen = () => {
      setForm(field);
      onOpenField();
   };

   return (
      <>
         {!isOpenField && (
            <Box p={2}>
               <Flex>
                  <Text>{field.label}</Text>
                  <Spacer />
                  <IconButton
                     aria-label="Edit firewall type field"
                     onClick={onOpen}
                     size="xs"
                     variant="outline"
                     icon={<FaEdit />}
                  />
               </Flex>
            </Box>
         )}
         <Collapse
            in={isOpenField}
            unmountOnExit
            style={{ overflow: "visible" }}
         >
            <Stack spacing="3">
               <FormControl>
                  <FormLabel>Key</FormLabel>
                  <Input
                     placeholder="Key"
                     value={form.key}
                     onChange={(e) => setForm({ ...form, key: e.target.value })}
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>Label</FormLabel>
                  <Input
                     placeholder="Label"
                     value={form.label}
                     onChange={(e) =>
                        setForm({ ...form, label: e.target.value })
                     }
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>Placeholder</FormLabel>
                  <Input
                     placeholder="Placeholder"
                     value={form.placeholder}
                     onChange={(e) =>
                        setForm({ ...form, placeholder: e.target.value })
                     }
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>Pattern</FormLabel>
                  <Input
                     placeholder="Pattern"
                     value={form.pattern}
                     onChange={(e) =>
                        setForm({ ...form, pattern: e.target.value })
                     }
                  />
               </FormControl>
               <FormControl>
                  <Checkbox
                     isChecked={form.optional}
                     onChange={(e) => {
                        setForm({ ...form, optional: e.target.checked });
                     }}
                  >
                     Optional
                  </Checkbox>
               </FormControl>
               <FormControl>
                  <Checkbox
                     isChecked={form.multiple}
                     onChange={(e) => {
                        setForm({ ...form, multiple: e.target.checked });
                     }}
                  >
                     Multiple
                  </Checkbox>
               </FormControl>
               <FormControl>
                  <Button
                     onClick={() => {
                        onUpdate(form);
                        onCloseField();
                     }}
                     colorScheme="brand"
                  >
                     Done
                  </Button>
                  <Button
                     onClick={() => {
                        onCloseField();
                        onDelete();
                     }}
                     colorScheme="danger"
                     ml={2}
                  >
                     Delete
                  </Button>
               </FormControl>
            </Stack>
         </Collapse>
      </>
   );
};

export const FieldForm: React.FC<{ onAdd: (field: ITypeField) => void }> = ({
   onAdd,
}) => {
   const {
      isOpen: isOpenField,
      onOpen: onOpenField,
      onClose: onCloseField,
   } = useDisclosure();

   const initForm = {
      label: "",
      placeholder: "",
      pattern: "",
      key: "",
      optional: false,
      multiple: false,
   } as ITypeField;

   const [form, setForm] = useState(initForm);

   const onOpen = () => {
      setForm(initForm);
      onOpenField();
   };

   return (
      <>
         <FormControl>
            <Button onClick={isOpenField ? onCloseField : onOpen} w="100%">
               Add Field
            </Button>
         </FormControl>
         <Collapse
            in={isOpenField}
            unmountOnExit
            style={{ overflow: "visible" }}
         >
            <Stack spacing="3" mt={3}>
               <FormControl>
                  <FormLabel>Key</FormLabel>
                  <Input
                     placeholder="Key"
                     value={form.key}
                     onChange={(e) => setForm({ ...form, key: e.target.value })}
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>Label</FormLabel>
                  <Input
                     placeholder="Label"
                     value={form.label}
                     onChange={(e) =>
                        setForm({ ...form, label: e.target.value })
                     }
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>Placeholder</FormLabel>
                  <Input
                     placeholder="Placeholder"
                     value={form.placeholder}
                     onChange={(e) =>
                        setForm({ ...form, placeholder: e.target.value })
                     }
                  />
               </FormControl>
               <FormControl>
                  <FormLabel>Pattern</FormLabel>
                  <Input
                     placeholder="Pattern"
                     value={form.pattern}
                     onChange={(e) =>
                        setForm({ ...form, pattern: e.target.value })
                     }
                  />
               </FormControl>
               <FormControl>
                  <Checkbox
                     isChecked={form.optional}
                     onChange={(e) => {
                        setForm({ ...form, optional: e.target.checked });
                     }}
                  >
                     Optional
                  </Checkbox>
               </FormControl>
               <FormControl>
                  <Checkbox
                     isChecked={form.multiple}
                     onChange={(e) => {
                        setForm({ ...form, multiple: e.target.checked });
                     }}
                  >
                     Multiple
                  </Checkbox>
               </FormControl>
               <FormControl>
                  <Button
                     onClick={() => {
                        onAdd(form);
                        onCloseField();
                     }}
                     colorScheme="brand"
                  >
                     Done
                  </Button>
               </FormControl>
            </Stack>
         </Collapse>
      </>
   );
};

import {
   Box,
   IconButton,
   Stack,
   FormControl,
   FormLabel,
   VStack,
   HStack,
   Input,
   Button,
   Select,
   NumberInput,
   NumberInputField,
} from "@chakra-ui/react";
import axios from "axios";
import { useState, useMemo } from "react";
import { IoMdArrowRoundBack, IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useQuery } from "react-query";
import {
   IApp,
   IType,
   ITypeField,
} from "../../ts/interfaces/announces_interface";

const ApplicationForm: React.FC<{
   onDone: (fields: IApp) => void;
   goBack: () => void;
   apps: IApp[];
}> = ({ onDone, goBack, apps }) => {
   const {
      isLoading,
      data: typeData,
      refetch,
   } = useQuery(["firewall-types"], () => {
      return axios
         .get<{
            types: IType[];
         }>(`/announces/apps/types`)
         .then(({ data }) => {
            return data;
         });
   });

   const [select, setSelect] = useState<IType>();
   const [value, setValue] = useState<Record<string, string>>({});
   const [port, setPort] = useState<number>(20);

   const validate = useMemo(
      () =>
         (
            select?.fields?.map((field) => {
               if (field.multiple) {
                  return (
                     value[field.key]
                        .split("|")
                        .map((value, index) => {
                           let optional = field.optional
                              ? field.optional && index === 0
                              : field.optional;
                           if (optional && !value) {
                              return true;
                           }
                           if (!optional && !value) {
                              return false;
                           }
                           return new RegExp(field.pattern).test(value || "");
                        })
                        .every(Boolean) || false
                  );
               }
               if (field.optional && !value[field.key]) {
                  return true;
               }
               if (!field.optional && !value[field.key]) {
                  return false;
               }
               return new RegExp(field.pattern).test(value[field.key]);
            }) || []
         ).every(Boolean),
      [value]
   );

   return (
      <Box>
         <IconButton
            onClick={goBack}
            icon={<IoMdArrowRoundBack />}
            aria-label="back"
         />
         <Stack spacing="3" mt={3}>
            <Select
               placeholder="Select application"
               value={select?.id || ""}
               isDisabled={isLoading}
               onChange={(e) => {
                  const sel = typeData?.types?.filter(
                     (t) => t.id === e.target.value
                  )[0];
                  if (!sel) {
                     return;
                  }
                  setSelect(sel);
                  const fieldsObj = Object.assign(
                     {} as ITypeField,
                     ...(sel.fields || [])?.map((state) => {
                        return { [state.key]: "" };
                     })
                  );
                  setValue(fieldsObj);
               }}
            >
               {typeData?.types
                  .filter(
                     (type) => !apps.map((app) => app.type.id).includes(type.id)
                  )
                  ?.map((t) => (
                     <option key={t.id} value={t.id}>
                        {t.name}
                     </option>
                  ))}
            </Select>
            {select?.need_port && (
               <FormControl isRequired>
                  <FormLabel>Port</FormLabel>
                  <NumberInput
                     precision={0}
                     step={1}
                     onChange={(e) => e && setPort(parseInt(e))}
                  >
                     <NumberInputField placeholder="Port" />
                  </NumberInput>
               </FormControl>
            )}
            {select?.fields?.map((field, idx) => (
               <FormControl
                  isRequired={
                     field.optional
                        ? field.optional && idx === 0
                        : field.optional
                  }
               >
                  <FormLabel>{field.label}</FormLabel>

                  {field.multiple ? (
                     <VStack spacing={2}>
                        {value[field.key]?.split("|").map((v, idy) => (
                           <HStack w="100%">
                              <Input
                                 placeholder={field.placeholder}
                                 value={v}
                                 onChange={(e) => {
                                    let newValue = structuredClone(value);
                                    let values =
                                       value[field.key].split("|") || [];

                                    values[idy] = e.target.value;
                                    newValue[field.key] = values.join("|");
                                    setValue(newValue);
                                 }}
                              />
                              {idy === 0 ? (
                                 <IconButton
                                    icon={<IoMdAdd />}
                                    aria-label="add"
                                    onClick={() => {
                                       let newValue = structuredClone(value);
                                       let values =
                                          value[field.key].split("|") || [];
                                       values.push("");
                                       newValue[field.key] = values.join("|");
                                       setValue(newValue);
                                    }}
                                    disabled={!value || !validate}
                                 />
                              ) : (
                                 <IconButton
                                    icon={<MdDelete />}
                                    aria-label="delete"
                                    colorScheme="danger"
                                    onClick={() => {
                                       let sel = { ...select };
                                       if (sel.fields) {
                                          let newValue = structuredClone(value);
                                          let values =
                                             value[field.key].split("|") || [];
                                          values.splice(idy, 1);
                                          newValue[field.key] =
                                             values.join("|");
                                          setValue(newValue);
                                       }
                                       setSelect(sel);
                                    }}
                                 />
                              )}
                           </HStack>
                        ))}
                     </VStack>
                  ) : (
                     <Input
                        mb={2}
                        placeholder={field.placeholder}
                        value={value[field.key]}
                        onChange={(e) => {
                           let newValue = structuredClone(value);
                           newValue[field.key] = e.target.value;
                           setValue(newValue);
                        }}
                     />
                  )}
               </FormControl>
            ))}
            <Button
               onClick={() => {
                  if (select) {
                     onDone({
                        type: select,
                        fields: value,
                        port: port,
                     } as IApp);
                     goBack();
                  }
               }}
               disabled={!select || !validate}
               colorScheme="brand"
            >
               Done
            </Button>
         </Stack>
      </Box>
   );
};

export default ApplicationForm;

import { ISwitchDevice, ISwitchModel } from "../../ts/interfaces/switch_device";
import React, { FC, useState } from "react";
import {
   Button,
   Checkbox,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   NumberDecrementStepper,
   NumberIncrementStepper,
   NumberInput,
   NumberInputField,
   NumberInputStepper,
   Select,
   useDisclosure,
   useToast,
   VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { APIError } from "../../ts/interfaces/error";
import { AiFillSetting } from "react-icons/ai";

interface EditSwitchDeviceModalProps {
   onSave: () => void;
   swDevice: ISwitchDevice;
}

export const EditSwitchDeviceModal: FC<EditSwitchDeviceModalProps> = ({
   onSave,
   swDevice,
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const toast = useToast();

   const [ipConnect, setIpConnect] = useState("");
   const [name, setName] = useState("");
   const [switchModelId, setSwitchModelId] = useState("");
   const [maxPortChannel, setMaxPortChannel] = useState(0);
   const [haveSecret, setHaveSecret] = useState(false);

   const { mutateAsync } = useMutation<any, AxiosError<APIError>, any>(
      ["admin", "save-switch-device"],
      (data) => {
         return axios
            .patch("/admin/switch-device/" + swDevice.id, data)
            .then((res) => res.data);
      }
   );

   const { isLoading, data, refetch } = useQuery(
      ["admin", "switch-management"],
      () => {
         return axios
            .get<{
               switch_models: ISwitchModel[];
            }>(`/admin/switch-model?limit=-1`)
            .then(({ data }) => {
               return data;
            });
      },
      {
         enabled: isOpen,
      }
   );

   return (
      <>
         <IconButton
            colorScheme="warning"
            icon={<AiFillSetting />}
            aria-label={"Add"}
            onClick={() => {
               onOpen();
               setIpConnect(swDevice?.ip_connect || "");
               setName(swDevice.name);
               setMaxPortChannel(swDevice.max_port_channel);
               setHaveSecret(swDevice.have_secret);
               setSwitchModelId(swDevice.switch_model_id);
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Edit Switch Device</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <VStack spacing={4}>
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           placeholder="Name"
                           onChange={(e) => setName(e.target.value)}
                           value={name}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Switch Model</FormLabel>
                        <Select
                           placeholder="Select switch model"
                           backgroundColor="white"
                           value={switchModelId}
                           onChange={(e) => setSwitchModelId(e.target.value)}
                        >
                           {data?.switch_models?.map((port, idx) => (
                              <option value={port.id} key={idx}>
                                 {port.name}
                              </option>
                           ))}
                        </Select>
                     </FormControl>
                     <FormControl>
                        <FormLabel>IP Connect</FormLabel>
                        <Input
                           placeholder="IP Connect"
                           onChange={(e) => setIpConnect(e.target.value)}
                           value={ipConnect}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Have secret</FormLabel>
                        <Checkbox
                           isChecked={haveSecret}
                           onChange={(e) => setHaveSecret(e.target.checked)}
                        >
                           Have Secret
                        </Checkbox>
                     </FormControl>
                     <FormControl>
                        <FormLabel>Max PortChannel</FormLabel>
                        <NumberInput
                           mb={2}
                           placeholder="Max PortChannel"
                           isDisabled={load}
                           value={maxPortChannel}
                           onChange={(_, value) => setMaxPortChannel(value)}
                        >
                           <NumberInputField />
                           <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                           </NumberInputStepper>
                        </NumberInput>
                     </FormControl>
                  </VStack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        mutateAsync(
                           {
                              name: name,
                              ip_connect: ipConnect,
                              max_port_channel: maxPortChannel,
                              have_secret: haveSecret,
                              switch_model_id: switchModelId,
                           },
                           {
                              onSuccess: (data) => {
                                 toast({
                                    title: `Commit success`,
                                    position: "top",
                                    status: "success",
                                    isClosable: true,
                                 });
                                 onClose();
                                 onSave();
                              },
                              onError: (res) => {
                                 toast({
                                    title: res.response?.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              },
                              onSettled: () => {
                                 setLoad(false);
                              },
                           }
                        );
                     }}
                  >
                     Save
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
   Box,
   Flex,
   IconButton,
   Input,
   Spinner,
   Table,
   TableContainer,
   Tag,
   Tbody,
   Td,
   Text,
   Tr,
   VStack,
} from "@chakra-ui/react";
import {
   AiFillCheckCircle,
   AiFillCloseCircle,
   AiOutlineSearch,
} from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { BsFillSkipEndFill } from "react-icons/bs";
import { GiSandsOfTime } from "react-icons/gi";
import { VscTasklist } from "react-icons/vsc";
import DataTable from "react-data-table-component";
import moment from "dayjs";
import { ILog, IStatus, ITask } from "../ts/interfaces/task_interface";
import { useInterval } from "react-use";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import omitBy from "lodash.omitby";
import { IoIosRefresh } from "react-icons/io";
import qs from "qs";
import { useQueryState } from "../hooks/useQueryState";

export const Task = () => {
   return (
      <VStack align="stretch" spacing={5}>
         <TableList />
      </VStack>
   );
};

const TableList = () => {
   const [search, setSearch] = useQueryState("search");
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);

   const { isLoading, data, refetch } = useQuery(
      ["firewall-task", perPage, currentPage, search],
      () => {
         return axios
            .get<{
               tasks: ITask[];
               meta: {
                  limit: number;
                  total: number;
               };
            }>(
               `/admin/task?${qs.stringify({
                  limit: `${perPage}`,
                  offset: `${(currentPage - 1) * perPage}`,
                  search: search,
               })}`
            )
            .then(({ data }) => {
               return data;
            });
      }
   );

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      setPerPage(newPerPage);
   };

   const columns = [
      {
         name: "",
         selector: (row: ITask) => (
            <>
               {row.status === 0 && <BiTimeFive size={16} color="orange" />}
               {row.status === 1 && (
                  <AiFillCheckCircle size={16} color="green" />
               )}
               {row.status === 2 && <AiFillCloseCircle size={16} color="red" />}
               {row.status === 3 && <GiSandsOfTime size={16} color="blue" />}
               {row.status === 4 && <BsFillSkipEndFill size={16} />}
            </>
         ),
         width: "50px",
      },
      {
         name: "ID",
         selector: (row: ITask) => row.id,
         sortable: true,
         width: "300px",
      },
      {
         name: "Action",
         selector: (row: ITask) => row.action,
         sortable: true,
      },
      {
         name: "Status",
         selector: (row: ITask) => IStatus[row.status],
         sortable: true,
         width: "100px",
      },
      {
         name: "Time",
         selector: "bandwidth",
         cell: (row: ITask) => (
            <Text fontSize="xs" whiteSpace={"nowrap"}>
               {moment(row.created_at).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
         ),
         width: "200px",
         sortable: true,
      },
   ];

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <Flex w={{ base: "100%", lg: "auto" }}>
               <Flex w={"100%"}>
                  {/* <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     onChange={(e) => setSearch(e.target.value)}
                     bg={"white"}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                     onClick={() => refetch()}
                  /> */}
                  <IconButton
                     aria-label={"search"}
                     icon={<IoIosRefresh />}
                     onClick={() => refetch()}
                     colorScheme="orange"
                  />
               </Flex>
            </Flex>
         </Flex>
         <Box boxShadow={"md"} rounded={"lg"}>
            <Box overflowX={"auto"}>
               <DataTable
                  columns={columns}
                  noHeader={true}
                  data={data?.tasks || []}
                  progressPending={isLoading}
                  pagination
                  paginationServer
                  paginationTotalRows={data?.meta?.total || 0}
                  paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationPerPage={perPage}
                  highlightOnHover
                  expandableRows
                  expandOnRowClicked
                  //expandableRowsComponent={LogTable}
                  expandableRowsComponent={<LogTable loaded={refetch} />}
               />
            </Box>
         </Box>
      </VStack>
   );
};

const LogTable: React.FC<{ data?: ITask; loaded: () => void }> = ({
   data,
   loaded,
}) => {
   const [task, setTask] = useState<ITask | undefined>();
   const [loading, setLoad] = useState(false);
   const fetch = () => {
      if (!task) {
         setLoad(true);
      }
      axios
         .get<ITask>(`/admin/task/${data?.id}`)
         .then(({ data }) => {
            setTask(data);
         })
         .finally(() => {
            setLoad(false);
            loaded();
         });
   };

   useEffect(() => {
      fetch();
   }, []);

   useInterval(fetch, 2000);

   const columns = useMemo(
      () => [
         {
            name: "",
            selector: (row: ILog) => (
               <>
                  {row.status === 0 && <BiTimeFive size={16} color="orange" />}
                  {row.status === 1 && (
                     <AiFillCheckCircle size={16} color="green" />
                  )}
                  {row.status === 2 && (
                     <AiFillCloseCircle size={16} color="red" />
                  )}
                  {row.status === 3 && <GiSandsOfTime size={16} color="blue" />}
                  {row.status === 4 && <BsFillSkipEndFill size={16} />}
               </>
            ),
            width: "50px",
         },
         {
            name: "Action",
            selector: (row: ILog) => (
               <Box style={{ textWrap: "wrap" }}>{row.action}</Box>
            ),
            sortable: true,
            width: "400px",
         },
         {
            name: "Detail",
            selector: (row: ILog) => (
               <Box py={4}>
                  {row.detail.split("\n").map((line) => (
                     <Box>{line}</Box>
                  ))}
               </Box>
            ),
            sortable: true,
         },
         {
            name: "Status",
            selector: (row: ILog) => IStatus[row.status],
            sortable: true,
            width: "100px",
         },
         {
            name: "Time",
            selector: "bandwidth",
            cell: (row: ILog) => (
               <Text fontSize="xs" whiteSpace={"nowrap"}>
                  {moment(row.created_at).format("DD-MM-YYYY HH:mm:ss")}
               </Text>
            ),
            width: "150px",
            sortable: true,
         },
      ],
      []
   );

   return (
      <Box
         p={6}
         border="1px"
         borderColor="gray.100"
         borderLeftColor="red.500"
         borderLeftWidth={5}
      >
         {loading ? (
            <div>
               <Spinner />
            </div>
         ) : (
            <div>
               <TableContainer>
                  <Table variant="simple" size="sm">
                     <Tbody>
                        <Tr>
                           <Td>Task By</Td>
                           <Td isNumeric>{task?.user?.email}</Td>
                        </Tr>
                        <Tr>
                           <Td>Created At</Td>
                           <Td isNumeric>
                              {moment(data?.created_at).format(
                                 "DD-MM-YYYY HH:mm:ss"
                              )}
                           </Td>
                        </Tr>
                     </Tbody>
                  </Table>
               </TableContainer>
               <Flex mt={8}>
                  <VscTasklist size={24} />
                  <Text ml={2}>Action Log ::</Text>
               </Flex>
               <Box p={4} mt={4} border="1px" borderColor="gray.200">
                  <DataTable
                     columns={columns}
                     noHeader={true}
                     data={task?.logs || []}
                     progressPending={loading}
                     pagination-={false}
                     highlightOnHover
                     striped
                  />
               </Box>
            </div>
         )}
      </Box>
   );
};

import * as React from "react";
import { FC, useEffect, useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import { Home } from "./views/Home";
import { Login } from "./views/Login";
import { useAuth } from "./context/auth";
import { DefaultLayout } from "./layouts/DefaultLayout";
import "./index.css";
import axios from "axios";
import { IUser } from "./ts/interfaces/user_interface";
import { Setting } from "./views/Setting";
import theme from "./theme";
import { Admin } from "./views/Admin";
import { Logout } from "./views/Logout";
import { DDOS } from "./views/DDos";
import { FirewallGroup } from "./views/FirewallGroup";
import { Task } from "./views/Task";
import SwitchManagement from "./views/SwitchManagement";
import { QueryClient, QueryClientProvider } from "react-query";

export const App = () => {
  const [load, setLoad] = useState(localStorage.getItem("jwt") != null);
  const { setUser } = useAuth();
  useEffect(() => {
    if (load) {
      axios.get<IUser | null>("/user/profile").then(({ data }) => {
        setUser(data);
        setLoad(false);
      });
    }
  });

  const queryClient = new QueryClient({
     defaultOptions: {
        queries: {
           keepPreviousData: true,
           cacheTime: 0, // 24 hours,
           refetchInterval: 3000,
        },
     },
  });

  useEffect(() => {
     if (
        process.env.NODE_ENV === "production" &&
        window.location.hostname !== "edc.forest-idc.com"
     ) {
        window.location.replace("https://edc.forest-idc.com/login");
     }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider resetCSS theme={theme}>
        {load
          ? (
            "Loading"
          )
          : (
            <Router>
              <Switch>
                <PublicRoute strictly={true} path="/login">
                  <Login />
                </PublicRoute>
                <PrivateRoute path="/setting">
                  <Setting />
                </PrivateRoute>
                <PrivateRoute path="/admin">
                  <Admin />
                </PrivateRoute>
                <PrivateRoute path="/home">
                  <Home />
                </PrivateRoute>
                <PrivateRoute path="/firewall-rule">
                  <FirewallGroup />
                </PrivateRoute>
                <PrivateRoute path="/switch-management">
                  <SwitchManagement />
                </PrivateRoute>
                <PrivateRoute path="/task">
                  <Task />
                </PrivateRoute>
                <PrivateRoute path="/anomaly">
                  <DDOS />
                </PrivateRoute>
                <PrivateRoute path="/logout">
                  <Logout />
                </PrivateRoute>
                <PrivateRoute path="">
                  <Redirect to={{ pathname: "/home" }} />
                </PrivateRoute>
              </Switch>
            </Router>
          )}
      </ChakraProvider>
    </QueryClientProvider>
  );
};

const PrivateRoute: FC<RouteProps> = ({ ...rest }) => {
  let { user } = useAuth();
  return (
    <DefaultLayout>
      {user ? <Route {...rest} /> : <Redirect to={{ pathname: "/login" }} />}
    </DefaultLayout>
  );
};

type ProtectedRouteProps = {
  strictly?: boolean;
} & RouteProps;

const PublicRoute: FC<ProtectedRouteProps> = ({ strictly, ...rest }) => {
  let { user } = useAuth();
  return !user || !strictly
    ? <Route {...rest} />
    : <Redirect to={{ pathname: "/home" }} />;
};

import React, { useState } from "react";
import {
   Button,
   FormControl,
   FormLabel,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Stack,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { RiLockPasswordFill } from "react-icons/ri";
import axios from "axios";
import { PasswordField } from "../Login/PasswordField";

export const ChangePassword = () => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [newPwd, setNewPwd] = useState("");
   const [confirmPwd, setConfirmPwd] = useState("");
   const [currentPwd, setPwd] = useState("");

   const [load, setLoad] = useState(false);
   const toast = useToast();

   return (
      <>
         <Button
            leftIcon={<RiLockPasswordFill />}
            onClick={() => {
               setNewPwd("");
               setConfirmPwd("");
               setPwd("");
               onOpen();
            }}
            colorScheme="brand"
         >
            Change password
         </Button>
         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Change password</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <Stack spacing="3">
                     <FormControl>
                        <FormLabel>Current password</FormLabel>
                        <PasswordField
                           placeholder={"Current password"}
                           value={currentPwd}
                           onChange={(e) => setPwd(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>New password</FormLabel>
                        <PasswordField
                           placeholder={"New password"}
                           value={newPwd}
                           onChange={(e) => setNewPwd(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Current password</FormLabel>
                        <PasswordField
                           placeholder={"Confirm password"}
                           value={confirmPwd}
                           onChange={(e) => setConfirmPwd(e.target.value)}
                        />
                     </FormControl>
                  </Stack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        axios
                           .post(`/user/change-password`, {
                              current_password: currentPwd,
                              new_password: newPwd,
                           })
                           .then(() => {
                              setLoad(false);
                              toast({
                                 title: `Change password success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                           })
                           .catch((error) => {
                              let res = error.response;
                              if (res) {
                                 toast({
                                    title: res.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              }
                           })
                           .finally(() => onClose());
                     }}
                  >
                     Change password
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

import {
   Box,
   Button,
   Checkbox,
   Collapse,
   Divider,
   Drawer,
   DrawerBody,
   DrawerCloseButton,
   DrawerContent,
   DrawerFooter,
   DrawerHeader,
   DrawerOverlay,
   Flex,
   FormControl,
   FormLabel,
   HStack,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   NumberDecrementStepper,
   NumberIncrementStepper,
   NumberInput,
   NumberInputField,
   NumberInputStepper,
   Select,
   Stack,
   Text,
   useDisclosure,
   useToast,
   VStack,
   Spacer,
   Tabs,
   TabList,
   Tab,
   TabPanels,
   TabPanel,
   Accordion,
   AccordionItem,
   AccordionButton,
   AccordionIcon,
   AccordionPanel,
} from "@chakra-ui/react";
import React, { FC, useState, useMemo } from "react";
import { AiFillFire } from "react-icons/ai";
import { IoIosAddCircle, IoMdAdd, IoMdArrowRoundBack } from "react-icons/io";
import {
   IApp,
   IType,
   ITypeField,
} from "../../ts/interfaces/announces_interface";
import axios from "axios";
import { AppTableList } from "./AppTableList";
import { useAuth } from "../../context/auth";
import { IUser } from "../../ts/interfaces/user_interface";
import { INode } from "../../ts/interfaces/node_interface";
import { useWindowSize } from "react-use";
import { useQuery } from "react-query";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { mapKeys } from "lodash";
import ApplicationEdit from "./ApplicationEdit";
import ApplicationCard from "./ApplicationCard";
import ApplicationForm from "./ApplicationForm";

interface AddInstanceProps {
   onAdd: () => void;
}

export const AddInstance: FC<AddInstanceProps> = ({ onAdd }) => {
   const { width } = useWindowSize();
   return width > 420 ? <Desktop onAdd={onAdd} /> : <Mobile onAdd={onAdd} />;
};

const Desktop: FC<AddInstanceProps> = ({ onAdd }) => {
   const { user, isAdmin } = useAuth();
   const { isOpen, onOpen, onClose } = useDisclosure();

   const initForm = {
      ip_addr: "",
      apps: [] as IApp[],
      port: "",
      user_id: "",
      announce_node_ids: [] as string[],
   };

   const [formData, setFormData] = useState(initForm);

   const [ip, setIP] = useState("");
   const [ingress, setIngress] = useState<number>(1024);
   const [users, setUsers] = useState<IUser[]>([]);
   const [apps, setApps] = useState<IApp[]>([]);
   const [port, setPort] = useState<number>(20);
   const [owner, setOwner] = useState<IUser | null>(user);
   const [load, setLoad] = useState(false);
   const toast = useToast();

   const [nodeList, setNodeList] = useState<INode[]>([]);
   const [announce_node_ids, setAnnounceNodeIds] = useState<string[]>([]);

   const [tabIndex, setTabIndex] = useState(0);
   const [edit, setEdit] = useState<IApp>();
   const [editIndex, setEditIndex] = useState<number>();

   const fetchNode = () => {
      if (!isAdmin) return;
      setLoad(true);
      axios
         .get<{
            nodes: INode[];
            meta: {
               limit: number;
               total: number;
            };
         }>(`/admin/nodes`)
         .then(({ data }) => {
            setNodeList(data.nodes);
            setLoad(false);
         });
   };

   return (
      <>
         <IconButton
            colorScheme="brand"
            icon={<AiFillFire />}
            aria-label={"Add"}
            onClick={() => {
               setLoad(true);
               fetchNode();
               Promise.all([
                  isAdmin
                     ? axios.get<{
                          users: IUser[];
                       }>("/admin/users?limit=-1")
                     : Promise.resolve(null),
               ])
                  .then((values) => {
                     values[0] && setUsers(values[0]?.data.users);
                  })
                  .finally(() => setLoad(false));
               setApps([]);
               setOwner(user);
               setIngress(1024);
               setAnnounceNodeIds([]);
               setIP("");
               onOpen();
            }}
         />

         <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Add Filter</ModalHeader>
               <ModalBody pb={6}>
                  <Tabs index={tabIndex} isLazy>
                     <TabPanels>
                        <TabPanel p={0}>
                           <Stack spacing="3">
                              <FormControl>
                                 <FormLabel>IP Address</FormLabel>
                                 <Input
                                    mb={2}
                                    placeholder="IP Address"
                                    value={ip}
                                    isDisabled={load}
                                    onChange={(e) => setIP(e.target.value)}
                                 />
                              </FormControl>
                              {isAdmin && (
                                 <>
                                    <FormControl>
                                       <FormLabel>Provided to user</FormLabel>
                                       <Select
                                          placeholder="Select user to provided"
                                          value={owner?.id}
                                          isDisabled={load}
                                          onChange={(e) =>
                                             setOwner(
                                                users?.filter(
                                                   (t) =>
                                                      t.id === e.target.value
                                                )[0]
                                             )
                                          }
                                       >
                                          {users?.map((t) => (
                                             <option key={t.id} value={t.id}>
                                                {t.email}
                                             </option>
                                          ))}
                                       </Select>
                                    </FormControl>
                                    <Flex align="center">
                                       <Divider />
                                       <Text padding="2">Nodes</Text>
                                       <Divider />
                                    </Flex>
                                    {nodeList.map((node) => (
                                       <FormControl key={node.id}>
                                          <Checkbox
                                             name="nodes"
                                             value={node.id}
                                             onChange={(e) => {
                                                const { value, checked } =
                                                   e.target;

                                                if (checked) {
                                                   setAnnounceNodeIds([
                                                      ...announce_node_ids,
                                                      value,
                                                   ]);
                                                } else {
                                                   setAnnounceNodeIds(
                                                      announce_node_ids.filter(
                                                         (item) =>
                                                            item !== value
                                                      )
                                                   );
                                                }
                                             }}
                                             isChecked={announce_node_ids.includes(
                                                node.id
                                             )}
                                          >
                                             {node.name}{" "}
                                             {(!node.active ||
                                                !node.enable) && (
                                                <span style={{ color: "red" }}>
                                                   (Deactive)
                                                </span>
                                             )}
                                          </Checkbox>
                                       </FormControl>
                                    ))}
                                 </>
                              )}
                              <Divider />

                              <FormControl>
                                 <FormLabel>Application</FormLabel>
                                 <>
                                    <FormControl>
                                       <Button
                                          onClick={() => {
                                             setTabIndex(1);
                                          }}
                                          w="100%"
                                       >
                                          Add Application
                                       </Button>
                                    </FormControl>
                                 </>
                              </FormControl>
                              {apps.map((app, idx) => (
                                 <ApplicationCard
                                    setEdit={setEdit}
                                    setEditIndex={setEditIndex}
                                    setTabIndex={setTabIndex}
                                    apps={apps}
                                    app={app}
                                    idx={idx}
                                    setApps={setApps}
                                 />
                              ))}
                              <ModalFooter>
                                 <Button
                                    colorScheme="brand"
                                    mr={3}
                                    isDisabled={
                                       load ||
                                       !ip ||
                                       (isAdmin && announce_node_ids.length < 1)
                                    }
                                    isLoading={load}
                                    onClick={() => {
                                       setLoad(true);
                                       axios
                                          .put("/announces/instances/v2", {
                                             ip_addr: ip,
                                             apps: apps,
                                             port: port,
                                             user_id: owner?.id,
                                             announce_node_ids:
                                                announce_node_ids,
                                          })
                                          .then(() => {
                                             setLoad(false);
                                             onAdd();
                                             toast({
                                                title: `Commit success`,
                                                position: "top",
                                                status: "success",
                                                isClosable: true,
                                             });
                                          })
                                          .catch((error) => {
                                             let res = error.response;
                                             if (res) {
                                                toast({
                                                   title: res.data.message,
                                                   status: "error",
                                                   position: "top",
                                                   isClosable: true,
                                                });
                                             }
                                          })
                                          .finally(() => onClose());
                                    }}
                                 >
                                    Create
                                 </Button>
                                 <Button onClick={onClose} isDisabled={load}>
                                    Cancel
                                 </Button>
                              </ModalFooter>
                           </Stack>
                        </TabPanel>
                        <TabPanel p={0}>
                           <ApplicationForm
                              onDone={(app) => {
                                 setApps([...apps, app]);
                              }}
                              goBack={() => setTabIndex(0)}
                              apps={apps}
                           />
                        </TabPanel>
                        <TabPanel p={0}>
                           {edit && editIndex !== undefined && (
                              <ApplicationEdit
                                 app={edit}
                                 onDone={(app) => {
                                    let a = [...apps];
                                    a[editIndex] = app;
                                    setApps(a);
                                 }}
                                 goBack={() => setTabIndex(0)}
                              />
                           )}
                        </TabPanel>
                     </TabPanels>
                  </Tabs>
               </ModalBody>
            </ModalContent>
         </Modal>
      </>
   );
};

const Mobile: FC<AddInstanceProps> = ({ onAdd }) => {
   const { user, isAdmin } = useAuth();
   const { isOpen, onOpen, onClose } = useDisclosure();

   const initForm = {
      ip_addr: "",
      apps: [] as IApp[],
      port: "",
      user_id: "",
      announce_node_ids: [] as string[],
   };

   const [formData, setFormData] = useState(initForm);

   const [ip, setIP] = useState("");
   const [ingress, setIngress] = useState<number>(1024);
   const [users, setUsers] = useState<IUser[]>([]);
   const [apps, setApps] = useState<IApp[]>([]);
   const [port, setPort] = useState<number>(20);
   const [owner, setOwner] = useState<IUser | null>(user);
   const [load, setLoad] = useState(false);
   const toast = useToast();

   const [nodeList, setNodeList] = useState<INode[]>([]);
   const [announce_node_ids, setAnnounceNodeIds] = useState<string[]>([]);

   const [tabIndex, setTabIndex] = useState(0);
   const [edit, setEdit] = useState<IApp>();
   const [editIndex, setEditIndex] = useState<number>();

   const fetchNode = () => {
      if (!isAdmin) return;
      setLoad(true);
      axios
         .get<{
            nodes: INode[];
            meta: {
               limit: number;
               total: number;
            };
         }>(`/admin/nodes`)
         .then(({ data }) => {
            setNodeList(data.nodes);
            setLoad(false);
         });
   };

   return (
      <>
         <IconButton
            colorScheme="brand"
            icon={<AiFillFire />}
            aria-label={"Add"}
            onClick={() => {
               setLoad(true);
               fetchNode();
               Promise.all([
                  isAdmin
                     ? axios.get<{
                          users: IUser[];
                       }>("/admin/users?limit=-1")
                     : Promise.resolve(null),
               ])
                  .then((values) => {
                     values[0] && setUsers(values[0]?.data.users);
                  })
                  .finally(() => setLoad(false));
               setApps([]);
               setOwner(user);
               setIngress(1024);
               setAnnounceNodeIds([]);
               setIP("");
               onOpen();
            }}
         />
         <Drawer isOpen={isOpen} onClose={onClose} placement={"bottom"}>
            <DrawerOverlay />
            <DrawerContent maxHeight="80vh">
               <DrawerHeader>Announce</DrawerHeader>
               <DrawerCloseButton />
               <DrawerBody pb={6}>
                  <Tabs index={tabIndex} isLazy>
                     <TabPanels>
                        <TabPanel p={0}>
                           <Stack spacing="3">
                              <FormControl>
                                 <FormLabel>IP Address</FormLabel>
                                 <Input
                                    mb={2}
                                    placeholder="IP Address"
                                    value={ip}
                                    isDisabled={load}
                                    onChange={(e) => setIP(e.target.value)}
                                 />
                              </FormControl>
                              {isAdmin && (
                                 <>
                                    <FormControl>
                                       <FormLabel>Provided to user</FormLabel>
                                       <Select
                                          placeholder="Select user to provided"
                                          value={owner?.id}
                                          isDisabled={load}
                                          onChange={(e) =>
                                             setOwner(
                                                users?.filter(
                                                   (t) =>
                                                      t.id === e.target.value
                                                )[0]
                                             )
                                          }
                                       >
                                          {users?.map((t) => (
                                             <option key={t.id} value={t.id}>
                                                {t.email}
                                             </option>
                                          ))}
                                       </Select>
                                    </FormControl>
                                    <Flex align="center">
                                       <Divider />
                                       <Text padding="2">Nodes</Text>
                                       <Divider />
                                    </Flex>
                                    {nodeList.map((node) => (
                                       <FormControl key={node.id}>
                                          <Checkbox
                                             name="nodes"
                                             value={node.id}
                                             onChange={(e) => {
                                                const { value, checked } =
                                                   e.target;

                                                if (checked) {
                                                   setAnnounceNodeIds([
                                                      ...announce_node_ids,
                                                      value,
                                                   ]);
                                                } else {
                                                   setAnnounceNodeIds(
                                                      announce_node_ids.filter(
                                                         (item) =>
                                                            item !== value
                                                      )
                                                   );
                                                }
                                             }}
                                             isChecked={announce_node_ids.includes(
                                                node.id
                                             )}
                                          >
                                             {node.name}{" "}
                                             {(!node.active ||
                                                !node.enable) && (
                                                <span style={{ color: "red" }}>
                                                   (Deactive)
                                                </span>
                                             )}
                                          </Checkbox>
                                       </FormControl>
                                    ))}
                                 </>
                              )}
                              <Divider />

                              <FormControl>
                                 <FormLabel>Application</FormLabel>
                                 <>
                                    <FormControl>
                                       <Button
                                          onClick={() => {
                                             setTabIndex(1);
                                          }}
                                          w="100%"
                                       >
                                          Add Application
                                       </Button>
                                    </FormControl>
                                 </>
                              </FormControl>
                              {apps.map((app, idx) => (
                                 <ApplicationCard
                                    setEdit={setEdit}
                                    setEditIndex={setEditIndex}
                                    setTabIndex={setTabIndex}
                                    apps={apps}
                                    app={app}
                                    idx={idx}
                                    setApps={setApps}
                                 />
                              ))}
                              <ModalFooter>
                                 <Button
                                    colorScheme="brand"
                                    mr={3}
                                    isDisabled={
                                       load ||
                                       !ip ||
                                       (isAdmin && announce_node_ids.length < 1)
                                    }
                                    isLoading={load}
                                    onClick={() => {
                                       setLoad(true);
                                       axios
                                          .put("/announces/instances/v2", {
                                             ip_addr: ip,
                                             apps: apps,
                                             port: port,
                                             user_id: owner?.id,
                                             announce_node_ids:
                                                announce_node_ids,
                                          })
                                          .then(() => {
                                             setLoad(false);
                                             onAdd();
                                             toast({
                                                title: `Commit success`,
                                                position: "top",
                                                status: "success",
                                                isClosable: true,
                                             });
                                          })
                                          .catch((error) => {
                                             let res = error.response;
                                             if (res) {
                                                toast({
                                                   title: res.data.message,
                                                   status: "error",
                                                   position: "top",
                                                   isClosable: true,
                                                });
                                             }
                                          })
                                          .finally(() => onClose());
                                    }}
                                 >
                                    Create
                                 </Button>
                                 <Button onClick={onClose} isDisabled={load}>
                                    Cancel
                                 </Button>
                              </ModalFooter>
                           </Stack>
                        </TabPanel>
                        <TabPanel p={0}>
                           <ApplicationForm
                              onDone={(app) => {
                                 setApps([...apps, app]);
                              }}
                              goBack={() => setTabIndex(0)}
                              apps={apps}
                           />
                        </TabPanel>
                        <TabPanel p={0}>
                           {edit && editIndex !== undefined && (
                              <ApplicationEdit
                                 app={edit}
                                 onDone={(app) => {
                                    let a = [...apps];
                                    a[editIndex] = app;
                                    setApps(a);
                                 }}
                                 goBack={() => setTabIndex(0)}
                              />
                           )}
                        </TabPanel>
                     </TabPanels>
                  </Tabs>
               </DrawerBody>
            </DrawerContent>
         </Drawer>
      </>
   );
};

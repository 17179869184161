import React, { FC, useState } from "react";
import {
   Button,
   Checkbox,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   NumberDecrementStepper,
   NumberIncrementStepper,
   NumberInput,
   NumberInputField,
   NumberInputStepper,
   Stack,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { AiFillEdit } from "react-icons/ai";
import axios from "axios";
import { INode } from "../../ts/interfaces/node_interface";

interface Props {
   node: INode;
   onEdit: () => void;
}

export const EditNode: FC<Props> = ({ onEdit, node }) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const toast = useToast();
   const [name, setName] = useState("");
   const [enable, setEnable] = useState(true);
   const [priority, setPriority] = useState(0);

   return (
      <>
         <IconButton
            aria-label={"icon"}
            icon={<AiFillEdit />}
            colorScheme="warning"
            onClick={() => {
               setName(node.name);
               setEnable(node.enable);
               setPriority(node.priority);
               setLoad(false);
               onOpen();
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Create node</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <Stack spacing="3">
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           value={name}
                           placeholder="Name"
                           isDisabled={load}
                           onChange={(e) => setName(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Priority</FormLabel>
                        <NumberInput
                           mb={2}
                           placeholder="Priority"
                           isDisabled={load}
                           value={priority}
                           onChange={(_, value) => setPriority(value)}
                        >
                           <NumberInputField />
                           <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                           </NumberInputStepper>
                        </NumberInput>
                     </FormControl>
                     <FormControl>
                        <Checkbox
                           onChange={(e) => {
                              setEnable(e.target.checked);
                           }}
                           isChecked={enable}
                        >
                           Enable
                        </Checkbox>
                     </FormControl>
                  </Stack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load || !name}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        axios
                           .patch("/admin/nodes/" + node.id, {
                              name: name,
                              enable: enable,
                              priority: priority,
                           })
                           .then(() => {
                              setLoad(false);
                              toast({
                                 title: `Commit success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                              onEdit();
                           })
                           .catch((error) => {
                              let res = error.response;
                              if (res) {
                                 toast({
                                    title: res.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              }
                           })
                           .finally(() => onClose());
                     }}
                  >
                     Save
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { ProvideAuth } from "./context/auth";

axios.defaults.baseURL =
   process.env.NODE_ENV !== "production"
      ? //"http://localhost:8080/v1"
        "https://firewall-console-api.forest-idc.com/v1"
      : "https://firewall-console-api.forest-idc.com/v1";

axios.interceptors.request.use(
   function (config) {
      // Do something before request is sent
      let token = localStorage.getItem("jwt");
      if (token) {
         config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
   },
   function (error) {
      // Do something with request error
      return Promise.reject(error);
   }
);

axios.interceptors.response.use(
   function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
   },
   function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      let res = error.response;
      if (res) {
         if (401 === res.status) {
            localStorage.removeItem("jwt");
            window.location.reload();
         }
      }
      return Promise.reject(error);
   }
);

ReactDOM.render(
   <React.StrictMode>
      <ColorModeScript />
      <ProvideAuth>
         <App />
      </ProvideAuth>
   </React.StrictMode>,
   document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Link } from "../component/Navbar/Link";
import {
   AiFillInfoCircle,
   AiOutlineFileProtect,
   AiOutlineHome,
   AiOutlineLogout,
   AiOutlineSetting,
} from "react-icons/ai";
import { BsListTask } from "react-icons/bs";
import { RiAdminFill } from "react-icons/ri";
import { Box, Divider, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../context/auth";
import brand from "../assets/brand.png";
import { FaFilter, FaNetworkWired } from "react-icons/fa";
import { useQuery } from "react-query";
import { ISwitchDevice } from "../ts/interfaces/switch_device";
import axios from "axios";
import { match, __ } from "ts-pattern";

export const NavbarMenu = () => {
   const { isAdmin, user } = useAuth();

   const { isLoading, data, refetch } = useQuery(
      ["switch-management-device"],
      () => {
         return axios
            .get<ISwitchDevice[]>(`/switch-management`)
            .then(({ data }) => {
               return data;
            });
      }
   );

   return (
      <Box p={4}>
         <Box maxW={"300px"} mb={8}>
            <Image src={brand} alt="brand" objectFit={"cover"} />
         </Box>

         <VStack align="stretch">
            <Link to={"/home"} icon={<FaFilter />}>
               <Text px={1}>Filter</Text>
            </Link>
            {data && data.length > 0 && (
               <Link to={"/switch-management"} icon={<FaNetworkWired />}>
                  <Text px={1}>Switch Management</Text>
               </Link>
            )}
            <Link to={"/anomaly"} icon={<AiFillInfoCircle />}>
               <Text px={1}>Anomaly</Text>
            </Link>
            <Link to={"/setting"} icon={<AiOutlineSetting />}>
               <Text px={1}>Setting</Text>
            </Link>

            {isAdmin && (
               <>
                  <Link to={"/firewall-rule"} icon={<AiOutlineFileProtect />}>
                     <Text px={1}>Firewall Rule</Text>
                  </Link>
                  <Link to={"/task"} icon={<BsListTask />}>
                     <Text px={1}>Task</Text>
                  </Link>
                  <Link to={"/admin"} icon={<RiAdminFill />}>
                     <Text px={1}>Admin</Text>
                  </Link>
               </>
            )}
            <Divider borderColor="gray.200" orientation="horizontal" />
            <Link to={"/logout"} icon={<AiOutlineLogout />}>
               <Text px={1}>Logout</Text>
            </Link>
         </VStack>
      </Box>
   );
};

import React, { FC } from "react";
import {
   Box,
   Container,
   Drawer,
   DrawerContent,
   DrawerOverlay,
   Flex,
   IconButton,
   useDisclosure,
} from "@chakra-ui/react";
import { Navbar } from "./Navbar";
import { BsList } from "react-icons/bs";
import { NavbarMenu } from "./NavbarMenu";
import { useWindowSize } from "react-use";

export const DefaultLayout: FC = ({ children }) => {
   const { width } = useWindowSize();
   const { isOpen, onOpen, onClose } = useDisclosure();

   return (
      <>
         <Flex h={"100vh"}>
            {width > 768 && <Navbar />}
            <Box max-h="full" w={"full"} overflowY={"auto"} bg={"#f5f6f9"}>
               <Box bg={"white"} p={4} boxShadow={"sm"}>
                  {width <= 768 && (
                     <IconButton
                        aria-label={"icon"}
                        icon={<BsList />}
                        onClick={onOpen}
                     />
                  )}
               </Box>
               <Container maxW={2200} overflowY={"auto"}>
                  <Box overflowY={"auto"}>
                     <Box p={4}>{children}</Box>
                  </Box>
               </Container>
            </Box>
         </Flex>
         <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
               <NavbarMenu />
            </DrawerContent>
         </Drawer>
      </>
   );
};

import {
   IPort,
   IPortGroup,
   ISwitchDevice,
} from "../../ts/interfaces/switch_device";
import React, { FC, useEffect, useState } from "react";
import {
   Box,
   Button,
   Checkbox,
   Collapse,
   Flex,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   SimpleGrid,
   Stack,
   useDisclosure,
   useToast,
   VStack,
} from "@chakra-ui/react";
import { MultiValue, Select as MSelect } from "chakra-react-select";
import { useMutation, useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { IUser } from "../../ts/interfaces/user_interface";
import { APIError } from "../../ts/interfaces/error";
import { AiFillSetting } from "react-icons/ai";
// @ts-ignore
import { memoPlus } from "@karuga/react-memo-plus";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

interface EditPortGroupModalProps {
   onSave: () => void;
   portGroup: IPortGroup;
}

export const EditPortGroupModal: FC<EditPortGroupModalProps> = ({
   onSave,
   portGroup,
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const [name, setName] = useState("");
   const [portIds, setPortIds] = useState<string[][]>([]);
   const [userIds, setUserIds] =
      useState<
         MultiValue<{
            label: string;
            value: string;
         }>
      >();
   const toast = useToast();

   const {
      isLoading,
      data: userData,
      refetch,
   } = useQuery(["admin", "port-group", "users"], () => {
      return axios
         .get<{
            users: IUser[];
         }>(`/admin/users?limit=-1`)
         .then(({ data }) => {
            return data;
         });
   });

   const {
      isLoading: swIsLoading,
      data: swData,
      refetch: swRefetch,
   } = useQuery(["admin", "port-group", "switch"], () => {
      return axios
         .get<{
            switch_devices: ISwitchDevice[];
         }>(`/admin/port-group/switch`)
         .then(({ data }) => {
            return data;
         });
   });

   const { data: portGroupData, refetch: portGroupRefetch } = useQuery(
      ["admin", "port-group", portGroup.id],
      () => {
         return axios
            .get<IPortGroup>(`/admin/port-group/` + portGroup.id)
            .then(({ data }) => {
               return data;
            });
      },
      {
         enabled: isOpen,
      }
   );

   const [done, setDone] = useState(false);

   useEffect(() => {
      if (portGroupData && swData) {
         let newIds = [...portIds];
         swData.switch_devices.forEach((sw, index) => {
            newIds[index] = [];
            sw.ports?.forEach((port) => {
               if (portGroupData?.ports?.map((p) => p.id).includes(port.id)) {
                  newIds[index].push(port.id);
               }
            });
         });
         setPortIds(newIds);
         // setPortIds(
         //    portGroupData?.ports?.map((p) => {
         //       return {
         //          value: p.id,
         //          label: p.name,
         //       };
         //    })
         // );
         setUserIds(
            portGroupData?.users?.map((u) => {
               return {
                  value: u.id,
                  label: u.email,
               };
            })
         );

         setDone(true);
      }
   }, [portGroupData?.users, portGroupData?.ports, swData]);

   const { mutateAsync } = useMutation<any, AxiosError<APIError>, any>(
      ["admin", "save-port-group"],
      (data) => {
         return axios
            .patch("/admin/port-group/" + portGroup.id, data)
            .then((res) => res.data);
      }
   );

   return (
      <>
         <IconButton
            colorScheme="warning"
            icon={<AiFillSetting />}
            aria-label={"Add"}
            onClick={() => {
               onOpen();
               refetch();
               swRefetch();
               portGroupRefetch();
               setName(portGroup.name);
            }}
         />
         <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            size="3xl"
         >
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Edit Port Group</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <VStack spacing={4}>
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           placeholder="Name"
                           value={name}
                           onChange={(e) => {
                              setName(e.target.value);
                           }}
                        />
                     </FormControl>

                     <FormControl>
                        <FormLabel>User</FormLabel>
                        <MSelect
                           isMulti
                           hasStickyGroupHeaders
                           chakraStyles={{
                              dropdownIndicator: (provided) => ({
                                 ...provided,
                                 bg: "transparent",
                                 px: 2,
                                 cursor: "inherit",
                              }),
                              indicatorSeparator: (provided) => ({
                                 ...provided,
                                 display: "none",
                              }),
                           }}
                           closeMenuOnSelect={false}
                           placeholder="Select user, who can access"
                           options={userData?.users.map((user) => {
                              return {
                                 label: user.email,
                                 value: user.id,
                              };
                           })}
                           value={userIds}
                           onChange={(sel) => {
                              setUserIds(sel);
                           }}
                        />
                     </FormControl>

                     <FormControl>
                        <FormLabel>Port List</FormLabel>
                        <Box maxH="40vh" overflow="auto">
                           {done &&
                              swData?.switch_devices?.map((sw, idx) => {
                                 return (
                                    <MemoDeviceCheckbox
                                       key={sw.id}
                                       switchDevice={sw}
                                       setSelectIds={(ids: string[]) => {
                                          let newIds = [...portIds];
                                          newIds[idx] = ids;
                                          setPortIds(newIds);
                                       }}
                                       initIds={portIds[idx]}
                                    />
                                 );
                              })}
                        </Box>
                     </FormControl>
                  </VStack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={load}
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        let newPortIds: string[] = [];
                        portIds.forEach((ids) => {
                           if (ids) {
                              newPortIds.push(...ids);
                           }
                        });
                        mutateAsync(
                           {
                              name: name,
                              port_ids: newPortIds,
                              user_ids: userIds?.map((user) => user.value),
                           },
                           {
                              onSuccess: (data) => {
                                 toast({
                                    title: `Commit success`,
                                    position: "top",
                                    status: "success",
                                    isClosable: true,
                                 });
                                 onClose();
                                 onSave();
                              },
                              onError: (res) => {
                                 toast({
                                    title: res.response?.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              },
                              onSettled: () => {
                                 setLoad(false);
                              },
                           }
                        );
                     }}
                  >
                     Save
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

const PortCheckBox: React.FC<{
   port: IPort;
   checkedItems: string[];
   setCheckedItems: (data: string[]) => void;
}> = ({ port, checkedItems, setCheckedItems }) => {
   return (
      <Box key={port.id}>
         <Checkbox
            isChecked={checkedItems.includes(port.id)}
            onChange={() => {
               const index = checkedItems.indexOf(port.id);
               if (index === -1) {
                  setCheckedItems([...checkedItems, port.id]);
               } else {
                  const newSelectId = [...checkedItems];
                  newSelectId.splice(index, 1);
                  setCheckedItems(newSelectId);
               }
            }}
         >
            {port.name}
         </Checkbox>
      </Box>
   );
};

const MemoPortCheckBox = memoPlus(PortCheckBox);

const DeviceCheckbox: React.FC<{
   initIds: string[];
   switchDevice: ISwitchDevice;
   setSelectIds: (ids: string[]) => void;
}> = ({ switchDevice, setSelectIds, initIds }) => {
   const { isOpen, onToggle } = useDisclosure();
   const [checkedItems, setCheckedItems] = React.useState<string[]>(
      initIds || []
   );

   const isIndeterminate =
      checkedItems.length > 0 &&
      checkedItems.length !== switchDevice.ports.length;
   const allChecked =
      checkedItems.length > 0 &&
      checkedItems.length === switchDevice.ports.length;

   useEffect(() => {
      setSelectIds([...checkedItems]);
   }, [checkedItems, setSelectIds]);

   return (
      <Box p={1}>
         <Box onClick={onToggle} cursor="pointer">
            <Flex>
               <Box p={1} pl={0}>
                  {isOpen ? <FaAngleDown /> : <FaAngleRight />}
               </Box>
               <Checkbox
                  p={1}
                  mr={1}
                  onChange={(e) => {
                     if (e.target.checked) {
                        setCheckedItems(
                           switchDevice.ports.map((port) => port.id)
                        );
                     } else {
                        setCheckedItems([]);
                     }
                  }}
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
               ></Checkbox>
               {switchDevice.name}
            </Flex>
         </Box>

         <Collapse in={isOpen} unmountOnExit style={{ overflow: "visible" }}>
            <Stack pl={6} mt={1} spacing={1}>
               <SimpleGrid columns={2} spacing={3}>
                  {switchDevice?.ports?.map((port) => (
                     <MemoPortCheckBox
                        port={port}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                     />
                  ))}
               </SimpleGrid>
            </Stack>
         </Collapse>
      </Box>
   );
};

const MemoDeviceCheckbox = memoPlus(DeviceCheckbox);

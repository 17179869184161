import { Button } from "@chakra-ui/react";
import React, { FC, ReactElement } from "react";
import { NavLink } from "react-router-dom";

interface ILink {
   to: string;
   icon: ReactElement;
}

export const Link: FC<ILink> = ({ to, icon, children }) => {
   return (
      <Button
         as={NavLink}
         to={to}
         leftIcon={icon}
         w={"full"}
         activeClassName={"active"}
         justifyContent={"left"}
         bg="white"
      >
         {children}
      </Button>
   );
};

import { VStack } from "@chakra-ui/react";
import React from "react";
import { TableList } from "../component/FirewallGroup/OverView";

export const FirewallGroup = () => {
   return (
      <VStack align="stretch" spacing={5}>
         <TableList />
      </VStack>
   );
};

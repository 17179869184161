import { IUser } from "./user_interface";

export enum IStatus {
   InProgress,
   Success,
   Failure,
   Pending,
   Skip,
}

export interface ITask {
   created_at: null | string;
   updated_at: null | string;
   deleted_at: null | string;
   id: string;
   action: string;
   status: IStatus;
   logs: ILog[];
   user?: IUser;
}

export interface ILog {
   created_at: null | string;
   updated_at: null | string;
   deleted_at: null | string;
   id: string;
   action: string;
   detail: string;
   status: IStatus;
}

import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  Select,
  StackDivider,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import { IPort, ISwitchDevice } from "../ts/interfaces/switch_device";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import axios from "axios/index";
import { SettingPortModal } from "../component/SwitchManagement/SettingPort";
import { PortExpander } from "../component/SwitchManagement/PortExpander";
import { ViewPortModal } from "../component/SwitchManagement/ViewPort";
import { CreatePortChannelModal } from "../component/SwitchManagement/CreatePortChannel";
import { DeletePort } from "../component/SwitchManagement/DeletePort";
import { useQueryState } from "../hooks/useQueryState";
import { IoIosRefresh } from "react-icons/io";
import qs from "qs";

const Index = () => {
   const [search, setSearch] = useQueryState("search");
   const [selectSw, setSelectSw] = useState<ISwitchDevice>();
   const [switchId, setSwitchId] = useQueryState("sw");

   const {
      isLoading,
      data: swList,
      refetch,
   } = useQuery(["switch-management-device"], () => {
      return axios
         .get<ISwitchDevice[]>(`/switch-management`)
         .then(({ data }) => {
            return data;
         });
   });

   useEffect(() => {
      if (swList && swList.length > 0 && !selectSw) {
         if (switchId) {
            swList.forEach((element) => {
               if (element.id === switchId) {
                  setSelectSw(element);
               }
            });
         } else {
            setSelectSw(swList[0]);
         }
      }
   }, [swList]);

   return (
      <VStack align="stretch" spacing={5}>
         <HStack maxWidth={500}>
            <Select
               placeholder="Select option"
               backgroundColor="white"
               value={selectSw?.id}
               onChange={(e) => {
                  swList?.forEach((sw) => {
                     if (sw.id === e.target.value) {
                        setSelectSw(sw);
                        setSwitchId(sw.id);
                     }
                  });
               }}
            >
               {swList?.map((sw, idx) => (
                  <option value={sw.id} key={sw.id}>
                     {sw.name}
                  </option>
               ))}
            </Select>
            <HStack width="600px">
               <Flex w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     bg={"white"}
                     onChange={(e) => setSearch(e.target.value)}
                     value={search}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                  />
               </Flex>
               <IconButton
                  aria-label={"search"}
                  icon={<IoIosRefresh />}
                  onClick={() => refetch()}
                  colorScheme="orange"
               />
            </HStack>
            <CreatePortChannelModal onCreate={refetch} swDevice={selectSw} />
         </HStack>
         <PortTable switchDevice={selectSw} search={search} />
      </VStack>
   );
};

const PortTable: React.FC<{
   switchDevice?: ISwitchDevice;
   search?: string;
}> = ({ switchDevice, search }) => {
   const {
      isLoading,
      data: portList,
      refetch,
   } = useQuery(
      ["switch-port-list", switchDevice?.id, search],
      () => {
         return axios
            .get<IPort[]>(
               `/switch-management/${switchDevice?.id}/ports?${qs.stringify({
                  search: search,
               })}`
            )
            .then(({ data }) => {
               return data;
            });
      },
      {
         enabled: !!switchDevice,
      }
   );

   const columns = useMemo(
      () => [
         {
            name: "Name",
            cell: (row: IPort) => `${row.name}`,
            width: "180px",
            sortable: false,
         },
         {
            name: "Description",
            cell: (row: IPort) => `${row.description}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "Note",
            cell: (row: IPort) => `${row.note}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "Status",
            cell: (row: IPort) => (
               <Tooltip
                  label={
                     <div>
                        <div>Protocol Status: {row.port_status}</div>
                        <div>Link Status: {row.link_state}</div>
                     </div>
                  }
               >
                  <Badge
                     colorScheme={
                        row.port_status.includes("up") ? "green" : "red"
                     }
                     cursor="pointer"
                  >
                     {row.port_status.includes("up") ? "UP" : "DOWN"}
                  </Badge>
               </Tooltip>
            ),
            width: "100px",
            sortable: false,
         },
         {
            name: "Mode",
            cell: (row: IPort) => <Badge cursor="pointer">{row.mode}</Badge>,
            width: "150px",
            sortable: false,
         },
         {
            name: "Vlan",
            cell: (row: IPort) => {
               if (row.mode === "access") {
                  return (
                     <Tooltip label="Access Vlan">
                        <Badge colorScheme="green" m={1} cursor="pointer">
                           {row.access_vlan}
                        </Badge>
                     </Tooltip>
                  );
               }
               return (
                  <Box>
                     {row.trunk_need_native_vlan && (
                        <Tooltip label="Native Vlan">
                           <Badge colorScheme="blue" m={1} cursor="pointer">
                              {row.trunk_native_vlan}
                           </Badge>
                        </Tooltip>
                     )}
                     {row.trunk_allow_vlan
                        ?.split(",")
                        .filter((vlan) => vlan !== "")
                        .map((vlan) => (
                           <Tooltip label="Allow Vlan" key={vlan}>
                              <Badge colorScheme="green" m={1} cursor="pointer">
                                 {vlan}
                              </Badge>
                           </Tooltip>
                        ))}
                  </Box>
               );
            },
            width: "200px",
            sortable: false,
         },
         {
            name: "",
            cell: (row: IPort) => (
               <HStack
                  spacing={2}
                  divider={<StackDivider borderColor="gray.200" />}
               >
                  <ViewPortModal data={row} />
                  <SettingPortModal onSave={refetch} port={row} />
                  <DeletePort port={row} onDelete={refetch} />
               </HStack>
            ),
            right: true,
         },
      ],
      []
   );

   return (
      <>
         <Box boxShadow={"md"} rounded={"lg"}>
            <DataTable
               columns={columns}
               noHeader={true}
               data={portList || []}
               progressPending={isLoading}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
               expandableRows
               expandOnRowClicked
               expandableRowDisabled={(row) =>
                  row.interface_type !== "port-channel"
               }
               expandableRowsComponent={<PortExpander />}
            />
         </Box>
      </>
   );
};

export default Index;

import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/auth";

export const Logout = () => {
   const history = useHistory();
   const { setUser } = useAuth();
   useEffect(() => {
      axios.post("/auth/logout").then(async () => {
         await localStorage.removeItem("jwt");
         await setUser(null);
         history.push("/login");
      });
   }, []);
   return <>Logging out</>;
};

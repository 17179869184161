import React, { useEffect, useMemo, useState } from "react";
import { IFirewallGroup } from "../../ts/interfaces/firewall-group";
import { useAuth } from "../../context/auth";
import axios from "axios";
import {
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   Spacer,
   Spinner,
   StackDivider,
   Tag,
   Text,
} from "@chakra-ui/react";
import {
   AiFillCheckCircle,
   AiFillCloseCircle,
   AiOutlineSearch,
} from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { BsFillSkipEndFill } from "react-icons/bs";
import { GiSandsOfTime } from "react-icons/gi";
import DataTable from "react-data-table-component";
import { FirewallGroupModalManagement } from "./ModalManagement";
import { Delete } from "./Delete";
import { useInterval } from "react-use";
import { useQuery } from "react-query";
import qs from "qs";
import { useQueryState } from "../../hooks/useQueryState";
import { IoIosRefresh } from "react-icons/io";

export const TableList = () => {
   const [search, setSearch] = useQueryState("search");
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);
   const { isAdmin, user } = useAuth();

   const { isLoading, data, refetch } = useQuery(
      ["firewall-task", perPage, currentPage, search],
      () => {
         return axios
            .get<{
               firewall_groups: IFirewallGroup[];
               meta: {
                  limit: number;
                  total: number;
               };
            }>(
               `/admin/task?${qs.stringify({
                  limit: `${perPage}`,
                  offset: `${(currentPage - 1) * perPage}`,
                  search: search,
               })}`
            )
            .then(({ data }) => {
               return data;
            });
      }
   );

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      setPerPage(newPerPage);
   };

   const columns = useMemo(
      () => [
         {
            name: "IP address",
            cell: (row: IFirewallGroup) => (
               <Flex>
                  {row.lock || row.mark_delete ? (
                     <Spinner width={3} height={3} mr={2} />
                  ) : (
                     <div style={{ marginRight: 3 }}>
                        {row.status === 0 && (
                           <BiTimeFive size={16} color="orange" />
                        )}
                        {row.status === 1 && (
                           <AiFillCheckCircle size={16} color="green" />
                        )}
                        {row.status === 2 && (
                           <AiFillCloseCircle size={16} color="red" />
                        )}
                        {row.status === 3 && (
                           <GiSandsOfTime size={16} color="blue" />
                        )}
                        {row.status === 4 && <BsFillSkipEndFill size={16} />}
                     </div>
                  )}
                  {row.ip_address}
               </Flex>
            ),
            sortable: true,
         },
         {
            name: isAdmin ? "Nodes" : "",
            cell: (row: IFirewallGroup) => (
               <HStack>
                  {row.nodes?.map((node) => (
                     <Tag key={node.id}>
                        <Text fontSize="xs" whiteSpace={"nowrap"}>
                           {node.name}
                        </Text>
                     </Tag>
                  ))}
               </HStack>
            ),
            width: "200px",
            sortable: false,
         },
         {
            name: isAdmin ? "Owner" : "",
            cell: (row: IFirewallGroup) =>
               isAdmin && (
                  <Text whiteSpace={"nowrap"}>
                     {row.user?.email || "Unknown"}
                  </Text>
               ),
            width: "200px",
            sortable: false,
         },
         {
            name: "",
            cell: (row: IFirewallGroup) => (
               <HStack
                  spacing={2}
                  divider={<StackDivider borderColor="gray.200" />}
               >
                  <FirewallGroupModalManagement
                     fetch={refetch}
                     firewall_group={row}
                  />
                  <Delete firewall_group={row} onDelete={refetch} />
               </HStack>
            ),
            width: "200px",
            right: true,
            sortable: false,
         },
      ],
      []
   );

   return (
      <>
         <Box>
            <Flex flexWrap={"wrap"}>
               <HStack w={{ base: "100%", lg: "auto" }}>
                  <Flex w={"100%"}>
                     <Input
                        placeholder={"Search"}
                        roundedRight={"none"}
                        onChange={(e) => setSearch(e.target.value)}
                        bg={"white"}
                     />
                     <IconButton
                        aria-label={"search"}
                        roundedLeft={"none"}
                        icon={<AiOutlineSearch />}
                        onClick={() => refetch()}
                     />
                  </Flex>
                  <FirewallGroupModalManagement fetch={refetch} />
                  <IconButton
                     aria-label={"search"}
                     icon={<IoIosRefresh />}
                     onClick={() => refetch()}
                     colorScheme="orange"
                  />
               </HStack>
               <Spacer />
            </Flex>
         </Box>
         <Box boxShadow={"md"} rounded={"lg"}>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data?.firewall_groups || []}
               progressPending={isLoading}
               pagination
               paginationServer
               paginationTotalRows={data?.meta?.total || 0}
               onChangeRowsPerPage={handlePerRowsChange}
               onChangePage={handlePageChange}
               paginationPerPage={perPage}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               highlightOnHover
            />
         </Box>
      </>
   );
};

import React, { FC, useState } from "react";
import {
   Box,
   Button,
   Checkbox,
   Divider,
   Flex,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Stack,
   useDisclosure,
   useToast,
   Text,
} from "@chakra-ui/react";
import { AiFillFileAdd } from "react-icons/ai";
import axios from "axios";
import { HuePicker, TwitterPicker } from "react-color";
import { IType, ITypeField } from "../../ts/interfaces/announces_interface";
import { FieldEdit, FieldForm } from "./FirewallTypeField";

interface CreateFirewallTypeProps {
   onCreate: () => void;
}

export const CreateFirewallType: FC<CreateFirewallTypeProps> = ({
   onCreate,
}) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const initForm = {
      name: "",
      cmd: "",
      remove_cmd: "",
      color: "",
      need_port: false,
      fields: [] as ITypeField[],
   };
   const [form, setForm] = useState(initForm);

   const [load, setLoad] = useState(false);
   const toast = useToast();

   return (
      <>
         <IconButton
            aria-label={"icon"}
            icon={<AiFillFileAdd />}
            onClick={() => {
               setForm(initForm);
               setLoad(false);
               onOpen();
            }}
         />
         <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="2xl"
            scrollBehavior="inside"
         >
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Firewall Type</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <Stack spacing="3">
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           placeholder="Name"
                           isDisabled={load}
                           value={form.name}
                           onChange={(e) =>
                              setForm({ ...form, name: e.target.value })
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Cmd</FormLabel>
                        <Input
                           placeholder="Cmd"
                           isDisabled={load}
                           value={form.cmd}
                           onChange={(e) =>
                              setForm({ ...form, cmd: e.target.value })
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Remove cmd</FormLabel>
                        <Input
                           placeholder="Remove cmd"
                           isDisabled={load}
                           value={form.remove_cmd}
                           onChange={(e) =>
                              setForm({ ...form, remove_cmd: e.target.value })
                           }
                        />
                     </FormControl>
                     <HuePicker
                        width={"100%"}
                        color={form.color}
                        onChange={(color) =>
                           setForm({ ...form, color: color.hex })
                        }
                     />
                     <TwitterPicker
                        width={"100%"}
                        color={form.color}
                        onChange={(color) =>
                           setForm({ ...form, color: color.hex })
                        }
                     />
                     <Checkbox
                        isChecked={form.need_port}
                        onChange={(e) =>
                           setForm({ ...form, need_port: e.target.checked })
                        }
                     >
                        Need port
                     </Checkbox>
                     <Flex align="center">
                        <Divider />
                        <Text padding="2">Fields</Text>
                        <Divider />
                     </Flex>
                     <Box>
                        <Stack spacing={3} divider={<Divider />}>
                           {form.fields?.map((field, idx) => (
                              <FieldEdit
                                 field={field}
                                 onDelete={() => {
                                    let newFields = [...form.fields];
                                    newFields.splice(idx, 1);
                                    console.log(newFields);
                                    setForm({ ...form, fields: newFields });
                                 }}
                                 onUpdate={(field) => {
                                    let newFields = [...form.fields];
                                    newFields[idx] = field;
                                    setForm({ ...form, fields: newFields });
                                 }}
                              />
                           ))}
                           <FieldForm
                              onAdd={(field) => {
                                 setForm({
                                    ...form,
                                    fields: [...form.fields, field],
                                 });
                              }}
                           />
                        </Stack>
                     </Box>
                  </Stack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={
                        load || !form.name || !form.remove_cmd || !form.cmd
                     }
                     isLoading={load}
                     onClick={() => {
                        setLoad(true);
                        axios
                           .put("/admin/announces/types", form)
                           .then(() => {
                              setLoad(false);
                              toast({
                                 title: `Commit success`,
                                 position: "top",
                                 status: "success",
                                 isClosable: true,
                              });
                              onCreate();
                           })
                           .catch((error) => {
                              let res = error.response;
                              if (res) {
                                 toast({
                                    title: res.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              }
                           })
                           .finally(() => onClose());
                     }}
                  >
                     Create
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

import React, { FC, useState } from "react";
import {
   Button,
   Checkbox,
   Divider,
   Flex,
   FormControl,
   FormLabel,
   IconButton,
   Input,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   NumberDecrementStepper,
   NumberIncrementStepper,
   NumberInput,
   NumberInputField,
   NumberInputStepper,
   Stack,
   Text,
   useDisclosure,
   useToast,
} from "@chakra-ui/react";
import { AiFillFileAdd } from "react-icons/ai";
import axios from "axios";

interface Props {
   onCreate: () => void;
}

export const CreateNode: FC<Props> = ({ onCreate }) => {
   const { isOpen, onOpen, onClose } = useDisclosure();
   const [load, setLoad] = useState(false);
   const toast = useToast();
   const [name, setName] = useState("");
   const [endpoint, setEndpoint] = useState("");
   const [apiKey, setApiKey] = useState("");
   const [announcerEndPoint, setAnnouncerEndPoint] = useState("");
   const [announcerApiKey, setAnnouncerApiKey] = useState("");
   const [enable, setEnable] = useState(true);
   const [priority, setPriority] = useState(0);

   return (
      <>
         <IconButton
            aria-label={"icon"}
            icon={<AiFillFileAdd />}
            onClick={() => {
               setName("");
               setEndpoint("");
               setApiKey("");
               setAnnouncerEndPoint("");
               setAnnouncerApiKey("");
               setEnable(true);
               setLoad(false);
               setPriority(0);
               onOpen();
            }}
         />
         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>Create node</ModalHeader>
               <ModalCloseButton />
               <ModalBody>
                  <Stack spacing="3">
                     <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                           placeholder="Name"
                           isDisabled={load}
                           onChange={(e) => setName(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>Priority</FormLabel>
                        <NumberInput
                           mb={2}
                           placeholder="Priority"
                           isDisabled={load}
                           value={priority}
                           onChange={(_, value) => setPriority(value)}
                        >
                           <NumberInputField />
                           <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                           </NumberInputStepper>
                        </NumberInput>
                     </FormControl>
                     <FormControl>
                        <Checkbox
                           onChange={(e) => {
                              setEnable(e.target.checked);
                           }}
                           isChecked={enable}
                        >
                           Enable
                        </Checkbox>
                     </FormControl>
                     <FormControl>
                        <FormLabel>EndPoint</FormLabel>
                        <Input
                           placeholder="http://example.com"
                           isDisabled={load}
                           onChange={(e) => setEndpoint(e.target.value)}
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>ApiKey</FormLabel>
                        <Input
                           placeholder="xxxxxxxxxxxxxxxxxxxxxxxxx"
                           isDisabled={load}
                           type="password"
                           onChange={(e) => setApiKey(e.target.value)}
                        />
                     </FormControl>
                     <Flex align="center">
                        <Divider />
                        <Text padding="2">Announcer</Text>
                        <Divider />
                     </Flex>
                     <FormControl>
                        <FormLabel>EndPoint</FormLabel>
                        <Input
                           placeholder="http://example.com"
                           isDisabled={load}
                           onChange={(e) =>
                              setAnnouncerEndPoint(e.target.value)
                           }
                        />
                     </FormControl>
                     <FormControl>
                        <FormLabel>ApiKey</FormLabel>
                        <Input
                           placeholder="xxxxxxxxxxxxxxxxxxxxxxxxx"
                           isDisabled={load}
                           type="password"
                           onChange={(e) => setAnnouncerApiKey(e.target.value)}
                        />
                     </FormControl>
                  </Stack>
               </ModalBody>

               <ModalFooter>
                  <Button
                     colorScheme="brand"
                     mr={3}
                     isDisabled={
                        load ||
                        !name ||
                        !endpoint ||
                        !apiKey ||
                        !announcerEndPoint ||
                        !announcerApiKey
                     }
                     isLoading={load}
                     onClick={async () => {
                        axios
                           .post("/admin/nodes/test", {
                              end_point: endpoint,
                              api_key: apiKey,
                           })
                           .then(() => {
                              axios
                                 .post("/admin/nodes/test", {
                                    end_point: announcerEndPoint,
                                    api_key: announcerApiKey,
                                 })
                                 .then(() => {
                                    setLoad(true);
                                    axios
                                       .put("/admin/nodes", {
                                          name: name,
                                          end_point: endpoint,
                                          api_key: apiKey,
                                          announcer_end_point:
                                             announcerEndPoint,
                                          announcer_api_key: announcerApiKey,
                                          enable: enable,
                                          priority: priority,
                                       })
                                       .then(() => {
                                          setLoad(false);
                                          toast({
                                             title: `Commit success`,
                                             position: "top",
                                             status: "success",
                                             isClosable: true,
                                          });
                                          onCreate();
                                       })
                                       .catch((error) => {
                                          let res = error.response;
                                          if (res) {
                                             toast({
                                                title: res.data.message,
                                                status: "error",
                                                position: "top",
                                                isClosable: true,
                                             });
                                          }
                                       })
                                       .finally(() => onClose());
                                 })
                                 .catch((error) => {
                                    let res = error.response;
                                    if (res) {
                                       toast({
                                          title:
                                             "announcer " + res.data.message,
                                          status: "error",
                                          position: "top",
                                          isClosable: true,
                                       });
                                    }
                                 });
                           })
                           .catch((error) => {
                              let res = error.response;
                              if (res) {
                                 toast({
                                    title: "node " + res.data.message,
                                    status: "error",
                                    position: "top",
                                    isClosable: true,
                                 });
                              }
                           });
                     }}
                  >
                     Create
                  </Button>
                  <Button onClick={onClose} isDisabled={load}>
                     Cancel
                  </Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>
   );
};

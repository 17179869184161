import React, { useMemo, useState } from "react";
import {
   Box,
   Flex,
   HStack,
   IconButton,
   Input,
   StackDivider,
   Tab,
   TabList,
   TabPanel,
   TabPanels,
   Tabs,
   Text,
   Tooltip,
   VStack,
   Wrap,
   WrapItem,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import {
   IPort,
   IPortGroup,
   ISwitchDevice,
} from "../../ts/interfaces/switch_device";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import omitBy from "lodash.omitby";
import axios from "axios";
import { CreatePortGroupModal } from "./CreatePortGroup";
import { DeleteDeletePortGroup } from "./DeletePortGroup";
import { EditPortGroupModal } from "./EditPortGroup";
import { MdCable } from "react-icons/md";
import qs from "qs";

const Index = () => {
   const [search, setSearch] = useState("");
   const [perPage, setPerPage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);

   const { isLoading, data, refetch } = useQuery(
      ["admin", "port-group", perPage, currentPage, search],
      () => {
         return axios
            .get<{
               port_groups: IPortGroup[];
               meta: {
                  limit: number;
                  total: number;
               };
            }>(
               `/admin/port-group?${qs.stringify({
                  limit: `${perPage}`,
                  offset: `${(currentPage - 1) * perPage}`,
                  search: search,
               })}`
            )
            .then(({ data }) => {
               return data;
            });
      }
   );

   const handlePageChange = (page: number) => {
      setCurrentPage(page);
   };

   const handlePerRowsChange = (newPerPage: number, page: number) => {
      setPerPage(newPerPage);
   };

   const {
      isLoading: swIsLoading,
      data: swData,
      refetch: swRefetch,
   } = useQuery(["admin", "port-group", "switch"], () => {
      return axios
         .get<{
            switch_devices: ISwitchDevice[];
         }>(`/admin/port-group/switch`)
         .then(({ data }) => {
            return data;
         });
   });

   const columns = useMemo(
      () => [
         {
            name: "Name",
            cell: (row: IPortGroup) => `${row.name}`,
            width: "200px",
            sortable: false,
         },
         {
            name: "Ports",
            cell: (row: IPortGroup) => {
               const portIds = row?.ports?.map((port) => port.id) || [];

               const deviceList = swData?.switch_devices?.filter((sw) => {
                  for (const idx in sw.ports) {
                     if (portIds.includes(sw.ports[idx].id)) {
                        return true;
                     }
                  }
                  return false;
               });
               return (
                  <Box py={2}>
                     {deviceList?.map((sw) => (
                        <Box>
                           {sw.name} (
                           {
                              sw.ports.filter((port) =>
                                 portIds.includes(port.id)
                              ).length
                           }
                           )
                        </Box>
                     ))}
                  </Box>
               );
            },
            width: "auto",
            sortable: false,
         },
         {
            name: "Users",
            cell: (row: IPortGroup) => (
               <Box py={4}>
                  {row?.users?.map((user) => (
                     <div key={user.id}>{user.email}</div>
                  ))}
               </Box>
            ),
            width: "250px",
            sortable: false,
         },
         {
            name: "",
            cell: (row: IPortGroup) => (
               <HStack
                  spacing={2}
                  divider={<StackDivider borderColor="gray.200" />}
               >
                  <EditPortGroupModal onSave={refetch} portGroup={row} />
                  <DeleteDeletePortGroup onDelete={refetch} portGroup={row} />
               </HStack>
            ),
            width: "100px",
            right: true,
         },
      ],
      [swData]
   );

   return (
      <VStack align="stretch" spacing={5}>
         <Flex>
            <Flex w={{ base: "100%", lg: "auto" }}>
               <Flex mr={2} w={"100%"}>
                  <Input
                     placeholder={"Search"}
                     roundedRight={"none"}
                     bg={"white"}
                  />
                  <IconButton
                     aria-label={"search"}
                     roundedLeft={"none"}
                     icon={<AiOutlineSearch />}
                  />
               </Flex>
               <CreatePortGroupModal onCreate={refetch} />
            </Flex>
         </Flex>
         <Box>
            <DataTable
               columns={columns}
               noHeader={true}
               data={data?.port_groups || []}
               progressPending={false}
               pagination
               paginationServer
               paginationTotalRows={0}
               onChangeRowsPerPage={handlePerRowsChange}
               onChangePage={handlePageChange}
               paginationPerPage={perPage}
               paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
               expandableRows
               expandOnRowClicked
               expandableRowsComponent={<Expander />}
            />
         </Box>
      </VStack>
   );
};

const Port: React.FC<{ port: IPort }> = ({ port }) => {
   // 👇️ paste SVG into a component
   // take fill and stroke colors as props
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         shape-rendering="geometricPrecision"
         text-rendering="geometricPrecision"
         image-rendering="optimizeQuality"
         fill-rule="evenodd"
         clip-rule="evenodd"
         viewBox="0 0 512 490.43"
         width={25}
         height={25}
      >
         <path
            fill-rule="nonzero"
            d="M29.08 0h453.85c8.04 0 15.28 3.33 20.5 8.55 5.26 5.27 8.57 12.64 8.57 20.53v432.27c0 7.94-3.33 15.23-8.59 20.49-5.25 5.25-12.54 8.59-20.48 8.59H29.08c-7.89 0-15.26-3.31-20.53-8.58C3.33 476.63 0 469.4 0 461.35V29.08c0-8.05 3.3-15.3 8.54-20.54l2.18-1.91A28.741 28.741 0 0 1 29.08 0z"
         />
         <path
            fill="#CCC"
            d="M29.08 21.98h453.85c3.9 0 7.09 3.35 7.09 7.1v432.27c0 3.75-3.34 7.1-7.09 7.1H29.08c-3.75 0-7.1-3.19-7.1-7.1V29.08c0-3.91 3.19-7.1 7.1-7.1z"
         />
         <path
            fill-rule="nonzero"
            d="M82.08 65.09h348.08c6.53 0 12.4 2.71 16.62 6.93 4.27 4.27 6.95 10.25 6.95 16.65v239.14h-70.87v48.58h-40.95v46.95H171.27v-46.95h-40.94v-48.58H58.5V88.67c0-6.54 2.67-12.42 6.92-16.66 4.3-4.28 10.23-6.92 16.66-6.92z"
         />
         <path
            fill="#fff"
            d="M82.08 81.57h348.08c3.9 0 7.1 3.35 7.1 7.1v222.66h-70.88v48.59h-40.95v46.94H187.75v-46.94H146.8v-48.59H74.98V88.67c0-3.91 3.19-7.1 7.1-7.1z"
         />
         <path
            fill-rule="nonzero"
            d="M365.43 297.06h71.83c9.09 0 16.47 7.38 16.47 16.48v46.38c0 9.09-7.38 16.47-16.47 16.47h-71.83c-9.09 0-16.48-7.38-16.48-16.47v-46.38c0-9.1 7.39-16.48 16.48-16.48z"
         />
         <path
            fill={port.link_state.includes("up") ? "yellow" : "white"}
            className="right"
            d="M437.26 313.54h-71.83v46.38h71.83z"
         />
         <path
            fill-rule="nonzero"
            d="M74.98 297.06h71.82c9.1 0 16.48 7.38 16.48 16.48v46.38c0 9.09-7.38 16.47-16.48 16.47H74.98c-9.1 0-16.48-7.38-16.48-16.47v-46.38c0-9.1 7.38-16.48 16.48-16.48z"
         />
         <path
            fill={port.port_status.includes("up") ? "green" : "red"}
            className="left"
            d="M74.98 313.54h71.82v46.38H74.98z"
         />
         <rect
            x="109.2"
            y="110.21"
            width="25.59"
            height="46.75"
            rx="3.44"
            ry="3.44"
         />
         <rect
            x="153.91"
            y="110.21"
            width="25.59"
            height="46.75"
            rx="3.44"
            ry="3.44"
         />
         <rect
            x="198.62"
            y="110.21"
            width="25.59"
            height="46.75"
            rx="3.44"
            ry="3.44"
         />
         <rect
            x="243.33"
            y="110.21"
            width="25.59"
            height="46.75"
            rx="3.44"
            ry="3.44"
         />
         <rect
            x="288.03"
            y="110.21"
            width="25.59"
            height="46.75"
            rx="3.44"
            ry="3.44"
         />
         <rect
            x="332.74"
            y="110.21"
            width="25.59"
            height="46.75"
            rx="3.44"
            ry="3.44"
         />
         <rect
            x="377.45"
            y="110.21"
            width="25.59"
            height="46.75"
            rx="3.44"
            ry="3.44"
         />
      </svg>
   );
};

const Expander: React.FC<{ data?: IPortGroup }> = ({ data }) => {
   const portIds = data?.ports?.map((port) => port.id) || [];

   const {
      isLoading: swIsLoading,
      data: swData,
      refetch: swRefetch,
   } = useQuery(["admin", "port-group", "switch"], () => {
      return axios
         .get<{
            switch_devices: ISwitchDevice[];
         }>(`/admin/port-group/switch`)
         .then(({ data }) => {
            return data;
         });
   });

   const deviceList = useMemo(
      () =>
         swData?.switch_devices?.filter((sw) => {
            for (const idx in sw.ports) {
               if (portIds.includes(sw.ports[idx].id)) {
                  return true;
               }
            }
            return false;
         }),
      []
   );

   return (
      <>
         <Box
            p={6}
            border="1px"
            borderColor="gray.100"
            borderLeftColor="red.500"
            borderLeftWidth={5}
         >
            <Flex>
               <MdCable size={24} />
               <Text ml={2}>Interfaces ::</Text>
            </Flex>
            <Tabs>
               <TabList>
                  {deviceList?.map((swDevice) => (
                     <Tab>
                        {swDevice.name} (
                        {
                           swDevice.ports.filter((port) =>
                              portIds.includes(port.id)
                           ).length
                        }
                        )
                     </Tab>
                  ))}
               </TabList>

               <TabPanels>
                  {deviceList?.map((sw) => {
                     return (
                        <TabPanel px={0}>
                           <Box border="1px" borderColor="gray.300">
                              <Wrap ml={4} py={4}>
                                 {sw.ports
                                    .filter((port) => portIds.includes(port.id))
                                    ?.map((port) => (
                                       <WrapItem>
                                          <Tooltip label={port.name}>
                                             <Box cursor="pointer">
                                                <Port port={port} />
                                             </Box>
                                          </Tooltip>
                                       </WrapItem>
                                    ))}
                              </Wrap>
                           </Box>
                        </TabPanel>
                     );
                  })}
               </TabPanels>
            </Tabs>
         </Box>
      </>
   );
};

export default Index;
